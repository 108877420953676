import React, { useState, useEffect, useRef } from 'react';
import { NFTTutor, TutorAttributes, TutorLabels } from '../../Models';
import { printInitials } from '../../Utils';
import { useLeadContracts } from '../Hooks/Lead/useLeadContracts';
import OrangeTriangle from '../../assets/orangetriangle.png';
import TriangleUp from '../../assets/triangleup.png';

type RenderTutorProps = {
  tutorOffered: NFTTutor;
  indexOfContract: number;
};

const RenderTutorInLead: React.FC<RenderTutorProps> = ({ tutorOffered, indexOfContract }) => {
  const [hasBackgroundCheck, setHasBackgroundCheck] = useState(false);
  const [wage, setWage] = useState<string | null>(tutorOffered.wage_for_job);
  const wageRef = useRef<HTMLInputElement>(null);
  const [isSelected, setIsSelected] = useState(false);
  const [showSMS, setShowSMS] = useState(false);
  const [hoverSubjects, setHoverSubjects] = useState(false);
  const [smsToTutor, setSMStoTutor] = useState(tutorOffered.defaultSMS);
  const { updateSelectedTutors } = useLeadContracts();

  //Sets Background check value after seatching tutor's labels
  useEffect(() => {
    if (!hasBackgroundCheck && tutorOffered.extra_attributes && tutorOffered.extra_attributes.length > 0) {
      tutorOffered.extra_attributes.forEach((attribute: TutorAttributes) => {
        if (attribute.machine_name === 'bgcheck' && attribute.value === 'True') {
          setHasBackgroundCheck(true);
        }
      });
    }
  });

  //Sets wage value after searching tutor for wage
  useEffect(() => {
    if (tutorOffered.wage_for_job) {
      setWage(tutorOffered.wage_for_job);
    } else {
      renderWage(tutorOffered.labels);
    }
  }, [wage, tutorOffered.labels, tutorOffered.wage_for_job]);

  //Function that takes the labels array from a tutor and renders the wage
  const renderWage = (labels: TutorLabels[]) => {
    labels.forEach((label) => {
      if (label.name.indexOf('$') === 0) {
        tutorOffered.wage_for_job = label.name;
        setWage(label.name);
      }
    });
  };

  //Renders URL to tutorcruncher profile. In our DB the link is to the API, so this function removes the api part of the endpoint
  const renderURL = (url: string) => {
    let parsed_url = url.split('/');
    parsed_url.splice(3, 1);
    return parsed_url.join('/');
  };

  //Sets SMStoTutor State
  const changeSMStoTutor = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSMStoTutor(e.target.value);
  };

  //Takes state value for SMS and sets it on the tutorOffered prop
  const saveSMStoTutor = () => {
    tutorOffered.defaultSMS = smsToTutor;
    setShowSMS(false);
  };

  return (
    <>
      <div className="single-tutor double-label-margin-bottom" data-cy="tutor">
        <div>
          {isSelected ? (
            <div className="tutor-heading-container">
              <div className="avatar find-tutor-avatar">{printInitials(tutorOffered.tutor_name)}</div>
              <div className="avatar-header">
                <h4>{tutorOffered.tutor_name}</h4>
                <label
                  className="orange-text normal-weight sms-label pointer"
                  onClick={() => setShowSMS(!showSMS)}
                  data-cy="tutor_sms--show"
                >
                  Edit SMS <img src={showSMS ? OrangeTriangle : TriangleUp} alt="" />
                </label>
              </div>
            </div>
          ) : (
            <div className="tutor-heading-container">
              <div className="avatar find-tutor-avatar">{printInitials(tutorOffered.tutor_name)}</div>
              <div>
                <h4>{tutorOffered.tutor_name}</h4>
              </div>
            </div>
          )}
        </div>
        <div className="tutor-info-container">
          <div className="inner-tutor-container">
            <span className="bg-check tutor-info">
              {hasBackgroundCheck ? (
                <i className="far fa-check-circle green-font"></i>
              ) : (
                <i className="far fa-times-circle red-font"></i>
              )}
            </span>
            {wage !== null ? (
              <input
                className={isSelected ? 'wage-input' : 'tutor-info span-clone'}
                ref={wageRef}
                type="text"
                defaultValue={wage!}
                disabled={!isSelected}
                onBlur={() => {
                  tutorOffered.wage_for_job = wageRef.current!.value;
                  setWage(wageRef.current!.value);
                }}
                data-cy="tutor_wage"
              />
            ) : (
              <input
                className={isSelected ? 'wage-input' : 'tutor-info span-clone'}
                ref={wageRef}
                type="text"
                placeholder={'No Wage'}
                disabled={!isSelected}
                onBlur={() => {
                  tutorOffered.wage_for_job = wageRef.current!.value;
                  setWage(wageRef.current!.value);
                }}
              />
            )}

            {hoverSubjects ? (
              <p onClick={() => setHoverSubjects(false)} className="subject-button">
                Subjects
              </p>
            ) : (
              <p onClick={() => setHoverSubjects(true)} className="subject-button">
                Subjects
              </p>
            )}

            <a
              className="tutor-link"
              href={renderURL(tutorOffered.tutorcruncher_url)}
              target="blank"
              rel="noopener noreferrer"
            >
              TC Profile
            </a>
            <div>
              <input
                id={`${tutorOffered.tutor_id}`}
                className="select-tutor-checkbox"
                type="checkbox"
                onClick={() => {
                  updateSelectedTutors(tutorOffered, indexOfContract);
                  // render edit SMS button (add className to show button)
                  setIsSelected(!isSelected);
                  setShowSMS(false);
                }}
                data-cy="tutor_select"
              />
            </div>
          </div>
        </div>
      </div>
      {showSMS ? (
        <div className="sms-input-container">
          <textarea
            className="sms"
            defaultValue={tutorOffered.defaultSMS}
            onChange={changeSMStoTutor}
            data-cy="tutor_sms--edit"
          ></textarea>

          <button className="reverse-blue default-margin-top-bottom" onClick={saveSMStoTutor} data-cy="tutor_sms--save">
            Save SMS
          </button>
        </div>
      ) : null}
      {hoverSubjects ? (
        <div
          className="subjects-offered"
          onClick={() => {
            setHoverSubjects(false);
          }}
        >
          {Array.from(new Set(tutorOffered.subjects)).map((subject, i) => {
            return (
              <p key={i} className="subject-hover">
                {subject}
              </p>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default RenderTutorInLead;
