import { StudentGrades, StudentType } from './index';

export type Subject = {
  student: string;
  // student_name?: string,
  subject_name: string;
  subject_grade: string;
  subject_price: number;
  appointment_times: string;
  skills_and_goals: string;
  resources_required: boolean;
  online_sessions: boolean;
};
// export class Subject {
//   constructor(
//     private student: string,
//     public subject_name: string,
//     public subject_grade: string,
//     public subject_price: number,
//     public appointment_times: string,
//     public skills_and_goals: string,
//   ) {
//     this.resources_required = false;
//     this.online_sessions = false;
//   }
//   resources_required: boolean;
//   online_sessions: boolean;

// renderLabel() {
//   let renderGrade;
//   if (this.subject_grade === StudentGrades.Kindergarten) {
//     renderGrade = 'KG';
//   } else if (this.subject_grade === StudentGrades.JuniorKindergarten) {
//     renderGrade = 'JK';
//   } else {
//     renderGrade = `Gr. ${this.subject_grade.split(' ')[1]}`;
//   }

//   return `${this.student.split(' ')[0]}/${this.subject_name}/${renderGrade}`;
// }

// returnSubjectGradeAndPrice() {
//   return `${this.subject_name} -- ${this.subject_grade} -- $${this.subject_price}`;
// }

//! is this being used?
// get Student(): string {
//   return this.student;
// }
// }

export enum SubjectList {
  Economics = 'Economics',
  ComputerScience = 'Computer Science',
  EnglishBeginner = 'English Grade 1-8',
  EnglishHighSchool = 'English Grade 9-12',
  EnglishL2R = 'Learn to Read',
  EnglishL2RD = 'Learn to Read with Dyslexia',
  Geography = 'Geography',
  History = 'History',
  Sociology = 'Sociology',
  Philosophy = 'Philosophy',
  Psychology = 'Psychology',
  Anthropology = 'Anthropology',
  FrenchCore = 'French (Core) - Not Immersion',
  FrenchImmersion = 'French Immersion (Fluent)',
  Italian = 'Italian',
  French = 'French',
  Spanish = 'Spanish',
  Accounting = 'Accounting',
  MathGrade1through4 = 'Math Grade 1-4',
  MathGrade5through8 = 'Math Grade 5-8',
  MathGrade9 = 'Math Grade 9',
  MathGrade10 = 'Math Grade 10',
  MathGrade11 = 'Math Grade 11',
  MathAdvancedFunctions = 'Math Grade 12 - Advanced Functions',
  MathCalculus = 'Math Grade 12 - Calculus',
  MathDataManagement = 'Math Grade 12 - Data Management',
  Biology = 'Biology',
  Physics = 'Physics',
  Chemistry = 'Chemistry',
  PhysicsGrade11 = 'Physics Grade 11',
  PhysicsGrade12 = 'Physics Grade 12',
  ChemistryGrade11 = 'Chemistry Grade 11',
  ChemistryGrade12 = 'Chemistry Grade 12',
  GeneralScience = 'General Science',
  ADHDExperience = 'Experience with ADHD',
  SpecialNeeds = 'Working with Special Needs',
  IELTS = 'IELTS',
  SAT = 'SAT (College Admission Exam)',
  AdmissionsTest = 'Admissions Test',
  SpecielNeedsHelp = 'Special Needs Help',
  TEFL = 'TEFL',
}

export enum NFTSubjectList {
  English = 'English',
  Math = 'Math',
  GeneralScience = 'General Science',
  History = 'History',
  AdvancedFunctions = 'Advanced Functions',
  Calculus = 'Calculus',
  DataManagement = 'Data Management',
  Biology = 'Biology',
  Physics = 'Physics',
  Chemistry = 'Chemistry',
  SAT = 'SAT (College Admission Exam)',
  AdmissionsTest = 'Admissions Test',
  FrenchCore = 'French (Core) - Not Immersion',
  FrenchImmersion = 'French Immersion (Fluent)',
  Italian = 'Italian',
  French = 'French',
  Spanish = 'Spanish',
  Economics = 'Economics',
  Accounting = 'Accounting',
  ComputerScience = 'Computer Science',
  Geography = 'Geography',
  Sociology = 'Sociology',
  Philosophy = 'Philosophy',
  Psychology = 'Psychology',
  Anthropology = 'Anthropology',
}
