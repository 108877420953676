import React, { useState, useEffect } from 'react';
import Dropdown from '../Partials/Dropdown';
import { countries, states } from '../../Models/SelectData';
import { NormalizePhone, RemoveSpecialChars } from '../../Utils';
import { useLeadData } from '../Hooks/Lead/useLeadData';
import { useClientData } from '../Hooks/Lead/useClientData';
import { useStudentData } from '../Hooks/Lead/useStudentData';

interface ClientProps {
  setClientIsStudent: (value: boolean) => void;
}

//Feeling like I'll want to pass down a function to do the isStudent/setIsStudent logic from the leadform. It must be notified of these changes.
export const RenderClient: React.FC<ClientProps> = ({ setClientIsStudent }) => {
  const { lead } = useLeadData();
  const client = lead!.client;
  const { handleClientInputChange, handleClientSelect, toggleClientStudent, resetClientIsStudent } = useClientData();
  const { removeStudent } = useStudentData();

  const [isStudent, setIsStudent] = useState<boolean>(lead!.client.isStudent);

  useEffect(() => {
    if (isStudent === null) {
      setIsStudent(client.isStudent);
    }
  }, [isStudent, client.isStudent]);

  return (
    <section id="CLIENT_DETAILS">
      <div className="heading">
        <div className="left-heading">
          <h3>Client Information</h3>
        </div>
      </div>
      <section className="row">
        <div>
          <label>Full Name (Required)</label>
          <input
            name="full_name"
            type="text"
            defaultValue={client.full_name}
            onBlur={(e) => {
              handleClientInputChange(e);
            }}
            data-cy="client_full_name"
          />
        </div>

        <div>
          <label>Phone Number </label>
          <input
            name="phone_number"
            type="text"
            defaultValue={client.phone_number}
            onBlur={(e) => {
              RemoveSpecialChars(client.phone_number);
              NormalizePhone(e);
              handleClientInputChange(e);
            }}
            data-cy="client_phone_number"
          />
        </div>
      </section>
      <section>
        <div>
          <label>Email (Required)</label>
        </div>
        <div className="row checkbox-container">
          <input
            name="email"
            onBlur={(e) => {
              handleClientInputChange(e);
            }}
            type="email"
            defaultValue={client.email}
            data-cy="client_email"
          />

          <div className="checkbox default-input-width">
            <input
              id="clientIsStudent"
              name="isStudent"
              type="checkbox"
              checked={lead!.client.isStudent}
              onChange={(e) => {
                if (lead!.client.isStudent) {
                  lead!.students.forEach((student) => removeStudent(student));
                }

                const CHECKBOX = document.getElementById('clientIsStudent')! as HTMLInputElement;
                setIsStudent(CHECKBOX.checked);
                setClientIsStudent(CHECKBOX.checked);
                toggleClientStudent(e);
              }}
              data-cy="client_isStudent"
            />
            <span>Client is a Student?</span>
          </div>
        </div>
      </section>

      <section className="row">
        <div>
          <label>City</label>
          <input
            name="city"
            type="text"
            defaultValue={lead!.client.city}
            onBlur={(e) => {
              handleClientInputChange(e);
            }}
            data-cy="city"
          />
        </div>

        <div>
          <label>Country (Required)</label>
          <Dropdown
            name="country"
            items={countries}
            value={client.country ? client.country : ''}
            onChange={(e) => {
              handleClientSelect(e);
            }}
          />
        </div>
      </section>
      <section className="row">
        <div>
          <label>State/Province</label>
          <Dropdown
            name="state_province"
            items={states}
            value={lead!.client.state_province}
            onChange={(e) => {
              handleClientSelect(e);
            }}
          />
        </div>

        <div>
          <label>Zip/Postal Code</label>
          <input
            name="postcode"
            type="text"
            defaultValue={lead!.client.postcode}
            onBlur={(e) => {
              handleClientInputChange(e);
            }}
            data-cy="client_postcode"
          />
        </div>
      </section>
    </section>
  );
};
