import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../Partials/TextInput';
import { StudentType, Subject } from '../../Models';
import { printInitials, renderClassname } from '../../Utils';

type StudentInMatchProps = {
  student: StudentType;
};

export const StudentInMatch: React.FC<StudentInMatchProps> = ({ student }) => {
  const [studentInState, setStudentInState] = useState<StudentType>(student);

  const appointmentDateRef = useRef<HTMLTextAreaElement>(null);
  const skillsAndGoalsRef = useRef<HTMLTextAreaElement>(null);

  const updateStudent = () => {
    setStudentInState(student);
  };

  const handleTextInput = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    value: 'skills_and_goals' | 'appointment_times',
    subject: Subject,
  ) => {
    subject[value] = e.target.value;
  };

  return (
    <>
      {student.subjects.map((subject, index) => {
        let gr;
        if (subject.subject_grade === 'Junior Kindergarten' || subject.subject_grade === 'Kindergarten') {
          gr = 'KG';
        } else {
          gr = `Gr.${subject.subject_grade.split(' ')[1]}`;
        }
        const labelName = `${student.student_name}/${subject.subject_name}/${gr}`;

        return (
          <div key={index} className="rounded-gray form-padding-sides default-margin-bottom">
            <div className="flex match-student-height">
              <div className="sms-selected">
                <div className="student-heading-container with-edit">
                  <div className="avatar student-avatar">{printInitials(student.student_name)}</div>
                  <div className="match-client-header">
                    <div className="left-heading">
                      <h4 className="nowrap m-header-font" data-cy="match_student--name">
                        {student.student_name}
                      </h4>
                    </div>
                    <a href={`tel: +1${student.student_phone}`} className="none phone-link normal-weight sms-label">
                      {student.student_phone}
                    </a>
                  </div>
                </div>
              </div>
              <div className="tutor-info">
                <div className="align-right">
                  <div className={renderClassname(labelName)}>
                    <h4>
                      {subject.subject_name}/{subject.subject_grade}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <section className="row"></section>
            <section className="match-textarea row default-margin-bottom appointments">
              <TextInput
                title="Appointment Dates & Times"
                name="appointment_times"
                refer={appointmentDateRef}
                value={subject.appointment_times}
                handler={handleTextInput}
                update={updateStudent}
                isLocked={false}
                subject={subject}
              />
              <TextInput
                title="Skill Level & Goals"
                name="skills_and_goals"
                refer={skillsAndGoalsRef}
                value={subject.skills_and_goals}
                handler={handleTextInput}
                update={updateStudent}
                isLocked={false}
                subject={subject}
              />
            </section>
          </div>
        );
      })}
    </>
  );
};

export default StudentInMatch;
