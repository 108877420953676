import React, { useState, useRef } from 'react';
import { WorksheetsTextSegment } from '../../Models';
import ErrorHandler from '../Partials/ErrorHandler';

type UploadHEadingSegmentProps = {
  UpdateTextBlock: (segment: WorksheetsTextSegment) => void;
};

const UploadHeadingSegment: React.FC<UploadHEadingSegmentProps> = ({ UpdateTextBlock }) => {
  const [hasError, setHasError] = useState(false);
  const HeadingInputRef = useRef<HTMLInputElement>(null);

  const AppendHeading = () => {
    if (HeadingInputRef.current && HeadingInputRef.current.value !== '') {
      UpdateTextBlock({
        type: 'header',
        content: HeadingInputRef.current.value,
      });
      HeadingInputRef.current.value = '';
    } else {
      setHasError(true);
    }
  };

  return (
    <section>
      {hasError && (
        <ErrorHandler
          type="error"
          message="Invalid Title entered, please try again or contact the tech team."
          handler={() => {
            setHasError(false);
          }}
        />
      )}
      <div>
        <label>New Heading: </label>
        <input ref={HeadingInputRef} type="text" placeholder="Heading..." />
      </div>
      <button onClick={AppendHeading}>Add Heading</button>
    </section>
  );
};

export default UploadHeadingSegment;
