import { useContext } from 'react';
import { LeadContext } from '../../Context/LeadContext';
import { useSubjectData } from './useSubjectData';
//Models
import {
  // Leads,
  LeadType,
  StudentType,
  Subject,
  Contract,
  MatchType,
  AdminLeadsFilterColumn,
  AdminLeadsFilterStatus,
} from '../../../Models';
import { GetBaseURL, isLeadType, isMatchType } from '../../../Utils';

export const useLeadData = () => {
  const { lead, setLead } = useContext(LeadContext);
  const { renderLabel } = useSubjectData();

  // create a lead
  const createNewLead = () => {
    // setLead(new Leads());
    setLead({
      status: AdminLeadsFilterStatus.Lead,
      column: AdminLeadsFilterColumn.NewLead,
      client: {
        full_name: '',
        phone_number: '',
        email: '',
        street_address: '',
        city: '',
        state_province: '',
        country: 'USA',
        postcode: '',
        major_intersection: '',
        isStudent: false,
      },
      students: [],
      notes: '',
      labels: [],
      contracts: [],
      timestamp: new Date(),
      lead_form_type: '',
      id: +(Math.random() * 1000000).toFixed(0),
      title: '',
    });
  };

  // create lead from data
  const createLeadFromData = (info: LeadType) => {
    setLead(info);
  };

  const updateContracts = (newContracts: Contract[]) => {
    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      updatedLead.contracts = newContracts;

      return updatedLead;
    });
  };

  // <-------------------- lead form functions -------------------->
  //! this is the same function 4 times for each react change event - can we simplify this in a way that is accepted by Typescript?
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      let targetInput: keyof LeadType = e.target.name! as keyof LeadType;

      // adding switch cases so methods in leadBase persist on state update
      switch (targetInput) {
        case 'lead_source':
          updatedLead.lead_source = e.target.value;
          break;
        case 'title':
          updatedLead.title = e.target.value;
          break;
      }
      return updatedLead;
    });
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      let targetInput: keyof LeadType = e.target.name! as keyof LeadType;

      switch (targetInput) {
        case 'column':
          updatedLead.column = e.target.value;
          break;
        case 'lead_source':
          updatedLead.lead_source = e.target.value;
          break;
        case 'lead_form_type':
          updatedLead.lead_form_type = e.target.value;
          break;
      }
      return updatedLead;
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.persist();
    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      let targetInput: keyof LeadType = e.target.name! as keyof LeadType;

      switch (targetInput) {
        case 'notes':
          updatedLead.notes = e.target.value;
          break;
      }
      return updatedLead;
    });
  };

  // <-------------------- student functions -------------------->
  const deleteStudent = (student: StudentType) => {
    const filteredStudents = lead!.students.filter((s, index) => {
      return s.student_name !== student.student_name && index !== lead!.students.indexOf(student);
    });

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      updatedLead.students = filteredStudents;
      return updatedLead;
    });
  };

  // <-------------------- Update Contract functions -------------------->
  const updateContract = (contract: Contract, indexOfContract: number) => {
    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      updatedLead.contracts[indexOfContract] = contract;
      return updatedLead;
    });
  };

  // <-------------------- Update ID -------------------->
  //todo: Create and export hook that will receive and ID as a paramter and add it to the lead
  const updateLeadID = (id: string) => {
    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      updatedLead._id = id;
      return updatedLead;
    });
  };

  const save = async (lead: LeadType | MatchType) => {
    let postObject;
    let url;
    if (isLeadType(lead)) {
      // returns true if lead is of type LeadType or MatchType
      postObject = {
        status: lead.status,
        column: lead.column,
        lead_source: lead.lead_source,
        client: lead.client,
        students: lead.students,
        notes: lead.notes,
        timestamp: lead.timestamp,
        labels: lead.labels,
        contracts: lead.contracts,
        lead_form_type: lead.lead_form_type,
        title: lead.title,
      };
      url = `${GetBaseURL()}/api/leads/`;
    } else {
      postObject = {
        status: lead.status,
        column: lead.column,
        lead_source: lead.lead_source,
        client: lead.client,
        students: lead.students,
        notes: lead.notes,
        labels: lead.labels,
        timestamp: lead.timestamp,
        tutors_offered: lead.tutors_offered,
        lead_form_type: lead.lead_form_type,
        title: lead.title,
      };
      url = `${GetBaseURL()}/api/leads/saveMatch`;
    }

    const postRequest = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(postObject),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    let response = await postRequest.json();

    if (response.id !== '') {
      return response.id;
    } else {
      return 'Failed to save';
    }
  };

  const update = async (lead: LeadType | MatchType) => {
    let postObject;
    let url;

    if (isLeadType(lead)) {
      postObject = {
        status: lead.status,
        column: lead.column,
        lead_source: lead.lead_source,
        client: lead.client,
        students: lead.students,
        notes: lead.notes,
        timestamp: lead.timestamp,
        labels: lead.labels,
        contracts: lead.contracts,
        _id: lead._id,
        lead_form_type: lead.lead_form_type,
        title: lead.title,
      };
      url = `${GetBaseURL()}/api/leads/updateOne`;
    } else {
      postObject = {
        status: lead.status,
        column: lead.column,
        lead_source: lead.lead_source,
        client: lead.client,
        students: lead.students,
        notes: lead.notes,
        labels: lead.labels,
        timestamp: lead.timestamp,
        tutors_offered: lead.tutors_offered,
        _id: lead._id,
        lead_form_type: lead.lead_form_type,
        title: lead.title,
      };
      url = `${GetBaseURL()}/api/leads/updateMatch`;
    }

    //todo: Update this IIFE so it's just regular and we can return same way client info is returned from tutorcruncher posts.
    let request = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(postObject),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (request.status === 200) {
      let res = await request.json();
      return { id: res.id, successfulSave: true };
    } else {
      return { successfulSave: false };
    }
  };

  const deleteSubjects = (subjectList: Subject[]) => {
    //We want to remove all subjects in the list passed in from the lead.
    lead.students.forEach((singleStudent, indexOfStudent) => {
      let filteredSubjects = singleStudent.subjects.filter((singleSubject) => {
        let found = subjectList.some((valueToCheck) => {
          return (
            valueToCheck.subject_name === singleSubject.subject_name &&
            valueToCheck.subject_grade === singleSubject.subject_grade &&
            //! where is property 'Student' on subjectList?
            // valueToCheck.Student === singleSubject.Student
            valueToCheck.student === singleSubject.student
          );
        });

        return !found;
      });

      singleStudent.subjects = filteredSubjects;
    });
  };

  const addLabel = (subject: Subject) => {
    lead.labels.push(renderLabel(subject));
  };

  const removeContract = (indexToRemove: number) => {
    lead.contracts = lead.contracts.filter((contract: Contract, index: number) => {
      return index !== indexToRemove;
    });
  };

  const deleteFromMongo = async (lead: LeadType | MatchType) => {
    let request = await fetch(`${GetBaseURL()}/api/leads/${lead._id}`, {
      method: 'DELETE',
    });

    return request.status;
  };

  return {
    lead,
    createNewLead,
    createLeadFromData,
    handleInputChange,
    handleSelect,
    handleTextChange,
    deleteStudent,
    updateContract,
    updateLeadID,
    updateContracts,
    save,
    update,
    deleteSubjects,
    addLabel,
    // modifyContract,
    removeContract,
    deleteFromMongo,
  };
};
