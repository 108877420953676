import React from 'react';
import { Link } from 'react-router-dom';

const CreateLeadModal: React.FC = () => {
  return (
    <div className="createLead shadow">
      <Link className="none flex-heading" to={`/admin/leads/new`}>
        <h3 data-cy="create_lead">Create New Lead</h3>
        <i className="fas fa-plus"></i>
      </Link>
    </div>
  );
};

export default CreateLeadModal;
