import React, { useState, useEffect, useRef } from 'react';
import { NFTTutor, TutorAttributes, TutorLabels } from '../../Models';
import { printInitials } from '../../Utils';

type RenderTutorProps = {
  tutorOffered: NFTTutor;
  SelectTutor?: (tutor: NFTTutor) => void;
};

const RenderTutorInMatch: React.FC<RenderTutorProps> = ({ tutorOffered, SelectTutor }) => {
  const [hasBackgroundCheck, setHasBackgroundCheck] = useState(false);
  const [wage, setWage] = useState<string>(tutorOffered.wage_for_job);
  const wageRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!hasBackgroundCheck && tutorOffered.extra_attributes && tutorOffered.extra_attributes.length > 0) {
      tutorOffered.extra_attributes.forEach((attribute: TutorAttributes) => {
        if (attribute.machine_name === 'bgcheck' && attribute.value === 'True') {
          setHasBackgroundCheck(true);
        }
      });
    }
  });

  useEffect(() => {
    if (tutorOffered.wage_for_job) {
      setWage(tutorOffered.wage_for_job);
    } else if (wage === null) {
      renderWage(tutorOffered.labels);
    } else if (wage === undefined) {
      setWage('N/A');
    }
  }, [wage, tutorOffered.labels, tutorOffered.wage_for_job]);

  const renderWage = (labels: TutorLabels[]) => {
    labels.forEach((label) => {
      if (label.name.indexOf('$') === 0) {
        setWage(label.name);
      }
    });
  };

  const renderURL = (url: string) => {
    let parsed_url = url.split('/');
    parsed_url.splice(3, 1);
    return parsed_url.join('/');
  };

  return (
    <div className="single-tutor single-tutor-bottom-padding">
      <div className="sms-selected">
        <div className="match-tutor-heading-container">
          <div className="avatar find-tutor-avatar">{printInitials(tutorOffered.tutor_name)}</div>
          <div>
            <h4 data-cy="match_tutor--name">{tutorOffered.tutor_name}</h4>
          </div>
        </div>
      </div>
      <div className="flex tutor-info-container">
        <div className="flex match-tutor-container">
          <span className="bg-check tutor-info">
            {hasBackgroundCheck ? (
              <i className="far fa-check-circle green-font"></i>
            ) : (
              <i className="far fa-times-circle red-font"></i>
            )}
          </span>

          {wage !== null || wage !== undefined ? (
            <input className="tutor-info span-clone" ref={wageRef} type="text" value={wage!} disabled />
          ) : (
            <span className="tutor-info span clone">No Wage</span>
          )}

          <span className="transit-mode tutor-info">
            {tutorOffered.transitMode ? tutorOffered.transitMode.toUpperCase() : 'N/A'}
          </span>

          <span className="transit-time tutor-info">
            {tutorOffered.transit_time ? `${tutorOffered.transit_time} min` : 'N/A'}
          </span>

          <span className="distance-to-client tutor-info">
            {tutorOffered.distance_to_client ? `${tutorOffered.distance_to_client} km` : 'N/A'}
          </span>
          <a
            className="tutor-link"
            href={renderURL(tutorOffered.tutorcruncher_url)}
            target="blank"
            rel="noopener noreferrer"
          >
            TC Profile
          </a>
          {SelectTutor ? (
            <input
              className="tutor-info input-margin-left"
              id={`${tutorOffered.tutor_id}`}
              type="radio"
              name="selectTutor"
              onClick={() => {
                SelectTutor(tutorOffered);
              }}
              data-cy="match_tutor--select"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RenderTutorInMatch;
