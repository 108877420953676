export enum UserRole {
  Admin = 'Admin',
  Client = 'Client',
  Student = 'Student',
  Tutor = 'Tutor',
}

export type Admin = {
  email: string;
  full_name: string;
  home_region: string;
  role: string;
  security_groups?: string[];
};

export enum CookieTypes {
  Role = 'role',
  HomeRegion = 'home_region',
  Email = 'email',
  FullName = 'full_name',
}
