import React, { useState, useEffect } from 'react';
import { WorksheetsTextSegment, WorksheetTextBlockLinkInText } from '../../Models';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

type WorksheetTextLinkProps = {
  segment: WorksheetsTextSegment;
};

const WorksheetTextLink: React.FC<WorksheetTextLinkProps> = ({ segment }) => {
  const [isValidSegment, setIsValidSegment] = useState(false);
  const { text, route, replaceString } = segment.content! as WorksheetTextBlockLinkInText;
  const uniqueReplace = uuidv4();
  const uniqueSplit = uuidv4();

  useEffect(() => {
    if (
      typeof segment.content !== 'string' &&
      Object.keys(segment.content).length === 3 &&
      Object.keys(segment.content).includes('replaceString')
    ) {
      setIsValidSegment(true);
    }
  }, [segment]);

  const ReplacedString = text.replace(replaceString, `${uniqueSplit}${uniqueReplace}${uniqueSplit}`).split(uniqueSplit);

  const newText = ReplacedString.map((singleString) => {
    return singleString === uniqueReplace ? (
      <Link target="_blank" rel="noopener noreferrer" to={{ pathname: route }}>
        {replaceString}
      </Link>
    ) : (
      singleString
    );
  });

  return isValidSegment === false ? null : segment.type === 'linkInText' ? (
    <React.Fragment key={uuidv4()}>
      <p>
        {newText.map((singleSection) => {
          return singleSection;
        })}
      </p>
    </React.Fragment>
  ) : null;
};

export default WorksheetTextLink;
