import React, { useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { StudentType, Subject, MatchType, AdminLeadsFilterStatus, AdminLeadsFilterColumn } from '../../Models';
import FindTutors from './FindTutors';
import { renderClassname, SendSMS, SendEmail, FindContactByPhone } from '../../Utils/';
import ErrorHandler from '../Partials/ErrorHandler';
// assets
import Triangle from '../../assets/triangle.png';
//context
// import { useContractData } from '../Hooks/useContractData';
import { useLeadData } from '../Hooks/Lead/useLeadData';
import { useClientData } from '../Hooks/Lead/useClientData';
import { useSubjectData } from '../Hooks/Lead/useSubjectData';
import { useMatchData } from '../Hooks/Lead/useMatchData';
import { useLeadContracts } from '../Hooks/Lead/useLeadContracts';
import { socket } from '../Hooks/useUserData';

interface CreateContractProps {
  subjects: Subject[];
  index: number;
}

export const CreateContract: React.FC<CreateContractProps> = ({ subjects, index }) => {
  const [clientSMSError, setClientSMSError] = useState(false);
  const [clientSMSSuccess, setClientSMSSuccess] = useState(false);
  const [saveMatchError, setSaveMatchError] = useState(false);
  const [saveMatchSuccess, setSaveMatchSuccess] = useState(false);
  const [displayAddSubject, setDisplayAddSubject] = useState(false);
  const [smsToClient, setSMSToClient] = useState<string>(
    'It was really nice talking to you and thank you for your interest in No Fuss Tutors - we will get back to you within 48 hours with your tutor!',
  );
  const [willRedirect, setWillRedirect] = useState(false);
  const saveContractRef = useRef<HTMLButtonElement>(null);
  const sendClientSMSButton = useRef<HTMLButtonElement>(null);
  const SelectSubjectRef = useRef<HTMLSelectElement>(null);

  const { lead, updateLeadID, addLabel, save, update, deleteSubjects, removeContract, deleteFromMongo } = useLeadData();
  const { updateClientID } = useClientData();
  const { getNumberOfSubjects, returnSubjectGradeAndPrice, renderLabel } = useSubjectData();
  const { printSubjects } = useMatchData();
  const { contracts, setSubjects, removeSubjectFromContract } = useLeadContracts();

  const changeSMStoClient = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSMSToClient(e.target.value);
  };

  //!function to return all subjects not in contract -- allows us to render them as options when adding subject
  const renderExtraSubjects = () => {
    //Find all subjects
    let allSubjects: Subject[] = [];
    lead!.students.forEach((student) => {
      student.subjects.forEach((subject) => {
        allSubjects.push(subject);
      });
    });
    //Filtering out subjects that are already in this contract.
    let subjectsOutsideContract: Subject[] = [];
    allSubjects.forEach((subject) => {
      if (!subjects.includes(subject)) {
        subjectsOutsideContract.push(subject);
      }
    });

    return subjectsOutsideContract;
  };

  //Removes one subject from the subjectList bu changing the display property to "none"
  const removeSubject = (subject: Subject) => {
    // take subject and find the index of it in the subjectList
    let subjectInList = subjects.find((subject_in_list) => {
      return returnSubjectGradeAndPrice(subject_in_list) === returnSubjectGradeAndPrice(subject);
    });

    if (subjectInList) {
      removeSubjectFromContract(index, subject);
    }
  };

  //Create match data (separating from Save function)
  const createMatchData = () => {
    let subjectsForStudents: { studentName: string; subjects: Subject[] }[] = [];

    //for each student
    lead!.students.forEach((singleStudent) => {
      //we want to gather an array of their subjects that are also in contractData
      let allSubjects = contracts[index].subjects.filter((singleSubject) => {
        return singleSubject.student === singleStudent.student_name;
      });

      //with that array of subject (from contractData), we will create an object to add to subjectsForStudents.
      if (allSubjects.length > 0) {
        subjectsForStudents.push({
          studentName: singleStudent.student_name,
          subjects: allSubjects,
        });
      }
    });

    //Creating student array for Match based off data above
    const studentsForMatch: StudentType[] = [];
    subjectsForStudents.forEach((studentForJob) => {
      let student = lead!.students.find((singleStudent) => {
        return singleStudent.student_name === studentForJob.studentName;
      });
      if (student) {
        let copied: StudentType = Object.assign(Object.create(Object.getPrototypeOf(student)), student);
        copied.subjects = studentForJob.subjects;
        studentsForMatch.push(copied);
      }
    });

    //Instantiates Match
    // let match = new MatchType(
    //   AdminLeadsFilterStatus.Match,
    //   AdminLeadsFilterColumn.Free,
    //   lead!.lead_source,
    //   lead!.client,
    //   studentsForMatch,
    //   '',
    //   lead!.labels,
    //   contracts[0].selectedTutors ? contracts[0].selectedTutors : [],
    //   lead!.title,
    // );

    let match = {
      status: AdminLeadsFilterStatus.Match,
      column: AdminLeadsFilterColumn.Free,
      lead_source: lead!.lead_source,
      client: lead!.client,
      students: studentsForMatch,
      notes: '',
      labels: lead!.labels,
      tutors_offered: contracts[0].selectedTutors ? contracts[0].selectedTutors : [],
      title: lead!.title,
    };

    return match;
  };

  const buildSMS = (matchDetails: MatchType) => {
    const subjectString = printSubjects(subjects).join(', ');

    let sms = `You have a new tutoring offer! Someone needs help with: ${subjectString}
    
If you are interested, reply "YES" to move forward, or "NO" to decline this job.`;
    return sms;
  };

  //Creates a match by instantiating a new match, removing the extraneous subjects from the lead by passing in the active subjects, saving it, then deleting subject from the lead class
  const saveMatch = async () => {
    if (saveContractRef.current) {
      saveContractRef.current.setAttribute('disabled', 'true');
    }

    //First creating labels
    contracts[index].subjects.forEach((subject) => {
      // lead!.addLabel(subject);
      addLabel(subject);
    });

    // instantiate new match and save to mongo
    let match = createMatchData();
    // saveMatchToMongo(match); //!Add back
    save(match);

    // send emails and SMS to all selected tutors
    match.tutors_offered.forEach((tutor) => {
      const subject = 'New Tutoring Opportunity';
      const emailTemplate = tutor.defaultSMS!.split('If you are interested')[0];

      const emailMessage = `${emailTemplate}
If you are interested in this opportunity, please reach out to your No Fuss Tutors representative.
      
Have a great day!`;

      SendEmail(emailMessage, tutor.tutor_email, subject);
      SendSMS(tutor.tutor_phone, tutor.defaultSMS!, match.client.state_province);
      let [associated_phone, twilio_number, body, dateSent, direction] = [
        `+1${tutor.tutor_phone}`,
        '+16469069648',
        tutor.defaultSMS,
        new Date(Date.now()),
        'outbound-api',
      ];
      socket.emit('updateCache', {
        newMessage: {
          associated_phone,
          twilio_number,
          transport_data: {
            body,
            dateSent,
            direction,
            from: twilio_number,
            to: associated_phone,
          },
        },
        contInfo: null,
        presistActiveContacts: true,
      });
    });

    //delete subjects used for this job from the lead and save
    // lead!.deleteSubjects(contracts[index].subjects);
    // lead!.removeContract(index);
    deleteSubjects(contracts[index].subjects);
    removeContract(index);

    try {
      // if (lead?.getNumberOfSubjects() === 0) {
      //!todo: uncomment this fn when new context ready
      if (getNumberOfSubjects() === 0) {
        if (lead?._id) {
          // lead.deleteFromMongo();
          deleteFromMongo(lead);
        }
        let oldContInfo = await FindContactByPhone(lead.client.phone_number);
        socket.emit('updateCache', {
          newMessage: null,
          contInfo: {
            ...oldContInfo,
            associated_lead: undefined,
            contact_data: {
              name: lead.client.full_name,
              email: lead.client.email,
              phone: lead.client.phone_number,
              location: lead.client.city,
            },
          },
        });
        setSaveMatchSuccess(true);
      } else if (lead!._id !== undefined) {
        // let updateLeadInMongo = await lead!.update();
        let updateLeadInMongo = await update(lead);
        if (updateLeadInMongo.successfulSave === true) {
          updateClientID(updateLeadInMongo.id!);
          setSaveMatchSuccess(true);
        } else {
          setSaveMatchError(true);
          return null;
        }
      } else {
        // lead!.save().then((res) => {
        save(lead).then((res) => {
          if (res !== 'Failed to save') {
            updateLeadID(res);
            setSaveMatchSuccess(true);
          } else {
            saveContractRef.current!.removeAttribute('disabled');
            setSaveMatchError(true);
          }
          setSaveMatchSuccess(true);
        });
      }
    } catch (err) {
      if (err) setSaveMatchError(true);
    }
  };

  if (willRedirect) {
    return <Redirect to="/admin/leads" />;
  } else {
    return (
      <section className="contract">
        <div className="contract-subjects-container flex-wrap">
          {/* <label>Subjects</label> */}
          {subjects.map((subject, index) => {
            return (
              <div className="contract-label-container nowrap" key={index} id={returnSubjectGradeAndPrice(subject)}>
                <span className={renderClassname(renderLabel(subject))}>
                  {renderLabel(subject)}{' '}
                  <span
                    className="x"
                    onClick={() => {
                      removeSubject(subject);
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </span>
              </div>
            );
          })}
          {displayAddSubject ? (
            <div className="select-container label-margin-top">
              <select
                ref={SelectSubjectRef}
                onBlur={() => {
                  //Finding which subject to add based on the value of the select onBlur
                  let subjectLabel = SelectSubjectRef.current!.value;
                  const SUBJECT_TO_ADD = renderExtraSubjects().find((subject) => {
                    return renderLabel(subject) === subjectLabel;
                  });

                  //If it's not null (Almost guaranteed) we run function to add the Subject to the contract
                  if (SUBJECT_TO_ADD) {
                    setSubjects([...contracts[index].subjects, SUBJECT_TO_ADD], index);
                    setDisplayAddSubject(false);
                  }

                  setDisplayAddSubject(false);
                }}
              >
                <option value=""></option>
                {renderExtraSubjects().map((subject, index) => {
                  return (
                    <option key={index} value={renderLabel(subject)}>
                      {renderLabel(subject)}
                    </option>
                  );
                })}
              </select>
              <div className="select-icon">
                <img src={Triangle} alt="" />
              </div>
            </div>
          ) : (
            <div className="like-label flex label-padding-top" onClick={() => setDisplayAddSubject(true)}>
              <i className="fas fa-plus"></i>
            </div>
          )}
        </div>
        <div className="contract-tutors-container">
          <FindTutors
            defaultSMS={() => {
              return buildSMS(createMatchData());
            }}
            indexOfContract={index}
          />
        </div>

        <section className="clientSMS default-margin-bottom">
          {clientSMSError ? (
            <ErrorHandler
              handler={() => {
                sendClientSMSButton.current!.removeAttribute('disabled');
                setClientSMSError(false);
              }}
              type="error"
              error="There was an issue sending the SMS. Please try again."
            />
          ) : null}
          {clientSMSSuccess ? (
            <ErrorHandler
              handler={() => {
                setClientSMSSuccess(false);
              }}
              type="success"
              message="Successfully Sent SMS!"
            />
          ) : null}
          <div>
            <label>Client SMS:</label>
            <textarea
              className="sms"
              name="smsToClient"
              defaultValue={smsToClient}
              onChange={changeSMStoClient}
            ></textarea>
          </div>
          <div className="flex default-margin-top">
            <button
              className="align-right"
              onClick={async () => {
                if (sendClientSMSButton.current) {
                  sendClientSMSButton.current.setAttribute('disabled', 'true');
                }

                const returnValue = await SendSMS(lead!.client.phone_number, smsToClient, lead!.client.state_province);
                if (returnValue) {
                  setClientSMSSuccess(true);
                  let [associated_phone, twilio_number, body, dateSent, direction] = [
                    `+1${lead.client.phone_number}`,
                    '+16469069648',
                    smsToClient,
                    new Date(Date.now()),
                    'outbound-api',
                  ];
                  socket.emit('updateCache', {
                    newMessage: {
                      associated_phone,
                      twilio_number,
                      transport_data: {
                        body,
                        dateSent,
                        direction,
                        from: twilio_number,
                        to: associated_phone,
                      },
                    },
                    contInfo: null,
                    presistActiveContacts: true,
                  });
                } else {
                  setClientSMSError(true);
                }
              }}
            >
              Send Client SMS
            </button>
          </div>
        </section>
        {saveMatchError ? (
          <ErrorHandler
            handler={() => {
              saveContractRef.current!.removeAttribute('disabled');
              setSaveMatchError(false);
            }}
            type="error"
            error="There was an issue saving the Match. Please try again"
          />
        ) : null}
        {saveMatchSuccess ? (
          <ErrorHandler
            handler={() => {
              setSaveMatchSuccess(false);
              setWillRedirect(true);
            }}
            type="success"
            error="The match was successfully saved!"
          />
        ) : null}
        <div>
          <button
            ref={saveContractRef}
            className="contract-button reverse-save align-right"
            onClick={saveMatch}
            data-cy="contract_save--match"
          >
            Complete Match
          </button>
        </div>
      </section>
    );
  }
};
