export type ErrorProps = {
  isActive: boolean;
  // error?: string,
  message: string;
  type: 'error' | 'warning' | 'success' | 'info';
  handler?: () => void;
  redirect?: boolean;
  icon?: string;
};

export const DefaultErrorProps = {
  isActive: false,
  message: '',
  type: 'error',
};

// ! FontAwesome shorthand for corresponding icons
export enum ErrorIcons {
  error = 'fas fa-times-circle',
  warning = 'fas fa-exclamation-triangle',
  success = 'fas fa-check-circle',
  info = 'fas fa-info-circle',
}
