import React, { useRef, useState, RefObject } from 'react';
import { WorksheetsTextSegment, WorksheetTextBlockLinkInText } from '../../Models';
import ErrorHandler from '../Partials/ErrorHandler';

type UploadLinkSegmentProps = {
  UpdateTextBlock: (segment: WorksheetsTextSegment) => void;
};

type ErrorProps = {
  isActive: boolean;
  message: string;
};

const UploadLinkSegment: React.FC<UploadLinkSegmentProps> = ({ UpdateTextBlock }) => {
  const [hasError, setHasError] = useState<ErrorProps>({
    isActive: false,
    message: '',
  });
  const [linkUpload, setLinkUpload] = useState<WorksheetTextBlockLinkInText>({
    text: '',
    replaceString: '',
    route: '',
  });
  const ParagraphInputRef = useRef<HTMLTextAreaElement>(null);
  const LinkTextInputRef = useRef<HTMLInputElement>(null);
  const RouteInputRef = useRef<HTMLInputElement>(null);

  const SubmitSegment = () => {
    if (!ParagraphInputRef.current || !LinkTextInputRef.current || !RouteInputRef.current) {
      setHasError({
        isActive: true,
        message: 'Please enter all required fields before submitting section.',
      });
    } else if (Object.values(linkUpload).includes('')) {
      setHasError({
        isActive: true,
        message: 'Please enter all required fields before submitting section.',
      });
    } else if (linkUpload.text.includes(linkUpload.replaceString) === false) {
      setHasError({
        isActive: true,
        message:
          "The link text is not in the submitted paragraph, please ensure 'link text' is included in the 'paragraph'.",
      });
    } else if (linkUpload.route.charAt(0) !== '/' && linkUpload.route.indexOf('https://') !== 0) {
      setHasError({
        isActive: true,
        message: "Route is not valid! It must begin with '/' or 'https://'.",
      });
    } else {
      //Submit segment and Reset values
      UpdateTextBlock({
        type: 'linkInText',
        content: linkUpload,
      });
      setLinkUpload({
        text: '',
        replaceString: '',
        route: '',
      });
      ParagraphInputRef.current.value = '';
      LinkTextInputRef.current.value = '';
      RouteInputRef.current.value = '';
    }
  };

  const validateInput = ({
    refer,
    targetValue,
  }: {
    refer: RefObject<HTMLInputElement | HTMLTextAreaElement>;
    targetValue: keyof WorksheetTextBlockLinkInText;
  }) => {
    if (refer.current && refer.current.value !== '') {
      const refValue = refer.current.value;
      setLinkUpload((prev: WorksheetTextBlockLinkInText) => ({ ...prev, [targetValue]: refValue }));
    } else {
      setHasError({
        isActive: true,
        message: `Value for ${
          targetValue === 'text' ? 'Paragraph' : targetValue === 'route' ? 'Link Route' : 'Link Text'
        } input is invalid. Please enter a full text string.`,
      });
    }
  };

  return (
    <div>
      {hasError.isActive && hasError.message.includes('Please enter all required fields before submitting section.') && (
        <ErrorHandler
          type="error"
          handler={() => {
            setHasError({ message: '', isActive: false });
          }}
          message={hasError.message}
        />
      )}

      {hasError.isActive &&
        hasError.message.includes(
          "The link text is not in the submitted paragraph, please ensure 'link text' is included in the 'paragraph'.",
        ) && (
          <ErrorHandler
            type="error"
            handler={() => {
              setHasError({ message: '', isActive: false });
            }}
            message={hasError.message}
          />
        )}
      {hasError.isActive && hasError.message.includes("Route is not valid! It must begin with '/' or 'https://'.") && (
        <ErrorHandler
          type="error"
          handler={() => {
            setHasError({ message: '', isActive: false });
          }}
          message={hasError.message}
        />
      )}

      <div>
        <div>
          <label>Paragraph: </label>
          <textarea
            ref={ParagraphInputRef}
            placeholder="Paragraph text..."
            onBlur={() => {
              validateInput({ refer: ParagraphInputRef, targetValue: 'text' });
            }}
          />
        </div>

        {hasError.isActive &&
          hasError.message.includes('Value for Paragraph input is invalid. Please enter a full text string.') && (
            <ErrorHandler
              type="error"
              handler={() => {
                setHasError({ message: '', isActive: false });
              }}
              message={hasError.message}
            />
          )}

        <div>
          <label>Link Text: </label>
          <input
            ref={LinkTextInputRef}
            type="text"
            placeholder="Will Display as a Link"
            onBlur={() => {
              validateInput({ refer: LinkTextInputRef, targetValue: 'replaceString' });
            }}
          />
        </div>

        {hasError.isActive &&
          hasError.message.includes('Value for Link Text input is invalid. Please enter a full text string.') && (
            <ErrorHandler
              type="error"
              handler={() => {
                setHasError({ message: '', isActive: false });
              }}
              message={hasError.message}
            />
          )}

        <div>
          <label>Link Route: </label>
          <input
            ref={RouteInputRef}
            type="text"
            placeholder="Must begin with '/' or 'https://"
            onBlur={() => {
              validateInput({ refer: RouteInputRef, targetValue: 'route' });
            }}
          />
        </div>

        {hasError.isActive &&
          hasError.message.includes('Value for Link Route input is invalid. Please enter a full text string.') && (
            <ErrorHandler
              type="error"
              handler={() => {
                setHasError({ message: '', isActive: false });
              }}
              message={hasError.message}
            />
          )}

        {Object.values(linkUpload).includes('') === false && <button onClick={SubmitSegment}>Submit Segment</button>}
      </div>
    </div>
  );
};

export default UploadLinkSegment;
