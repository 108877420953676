// import { useContext } from 'react';
// import { LeadContext } from '../../Context/LeadContext';
// import { useSubjectData } from './useSubjectData';

import {
  LeadType,
  StudentType,
  Subject,
  Contract,
  MatchType,
  NFTTutor,
  AdminLeadsFilterColumn,
  AdminLeadsFilterStatus,
} from '../../../Models';
import { GetBaseURL } from '../../../Utils';

export const useMatchData = () => {
  // const { lead, setLead } = useContext(LeadContext);
  // const { renderLabel } = useSubjectData();

  const selectTutorForContract = (tutor: NFTTutor, match: MatchType) => {
    const SINGLE_TUTOR = match.tutors_offered.filter((value: NFTTutor) => {
      return value.tutor_id === tutor.tutor_id;
    });
    match.tutors_offered = SINGLE_TUTOR;
  };

  // const saveMatchToMongo = async (match: MatchType) => {
  //   let postObject = {
  //     status: match.status,
  //     column: match.column,
  //     lead_source: match.lead_source,
  //     client: match.client,
  //     students: match.students,
  //     notes: match.notes,
  //     labels: match.labels,
  //     timestamp: match.timestamp,
  //     tutors_offered: match.tutors_offered,
  //     lead_form_type: match.lead_form_type,
  //     title: match.title,
  //   };

  //   (async () => {
  //     fetch(`${GetBaseURL()}/api/leads/saveMatch`, {
  //       method: 'POST',
  //       body: JSON.stringify(postObject),
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //   })();
  // };

  // const updateMatch = async (match: MatchType) => {
  //   const postObject = {
  //     status: match.status,
  //     column: match.column,
  //     lead_source: match.lead_source,
  //     client: match.client,
  //     students: match.students,
  //     notes: match.notes,
  //     labels: match.labels,
  //     timestamp: match.timestamp,
  //     tutors_offered: match.tutors_offered,
  //     _id: match._id,
  //     lead_form_type: match.lead_form_type,
  //     title: match.title,
  //   };

  //   (async () => {
  //     fetch(`${GetBaseURL()}/api/leads/updateMatch`, {
  //       method: 'POST',
  //       body: JSON.stringify(postObject),
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //   })();
  // };

  // const updateStudentsBySubject = (subjectArray: Subject[], match: MatchType) => {
  //   let newStudents: StudentType[] = [];
  //   //Going through subjects and adding the students from each to match.students
  //   subjectArray.forEach((subject) => {
  //     //Checking subjects to see if parent student in currently in Match.
  //     let studentSearch = match.students.find((student) => {
  //       return student.student_name.includes(subject.student);
  //     });

  //     //If Student exists in match AND the array to update the match's students does not contain this student, push it to newStudents
  //     if (
  //       studentSearch !== undefined &&
  //       !newStudents.find((student) => {
  //         return student.student_name === studentSearch?.student_name;
  //       })
  //     ) {
  //       newStudents.push(studentSearch);
  //     }
  //   });
  //   match.students = newStudents;
  // }; //!Take subject[] from CreateContract and create a new Student[] based on the students for those subjects

  // const removeSubjects = (subjectArray: Subject[], match: MatchType) => {
  //   match.students.forEach((studentInMatch) => {
  //     let newSubjectArray: Subject[] = [];
  //     studentInMatch.subjects.forEach((subjectInStudent) => {
  //       if (subjectArray.includes(subjectInStudent)) {
  //         newSubjectArray.push(subjectInStudent);
  //       }
  //     });
  //     studentInMatch.subjects = newSubjectArray;
  //   });
  // };

  const printSubjects = (subjectArray: Subject[]) => {
    const result: string[] = subjectArray.map((subject, index) => {
      let appointments = `Date/time requests: ${subject.appointment_times}`;

      if (!subject.appointment_times) {
        return `

${subject.subject_grade} ${subject.subject_name}`;
      } else {
        return `

${subject.subject_grade} ${subject.subject_name}
${appointments}`;
      }
    });

    return result;
  };

  // const postStudentToTC = async (student: StudentType, clientID: number, country: string) => {
  //   let newStudent = {
  //     newStudent: {
  //       user: {
  //         first_name: student.student_name.split(' ')[0],
  //         last_name: student.student_name.split(' ')[1], //!THis needs to be a better function....
  //         email: student.student_email,
  //         phone: student.student_phone,
  //       },
  //       paying_client: clientID,
  //     },
  //     oldStudent: student,
  //     country: country,
  //   };

  //   const request = await fetch(`${GetBaseURL()}/api/students/postToTutorcruncher`, {
  //     method: 'POST',
  //     body: JSON.stringify(newStudent),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });

  //   let response = await request.json();
  // };

  // const renderJobName = (match: MatchType) => {
  //   let jobName: string[] = [];
  //   match.students.forEach((student) => {
  //     student.subjects.forEach((subject) => {
  //       jobName.push(renderLabel(subject));
  //     });
  //   });

  //   return jobName.join(' -- ');
  // };

  // const postContractToTC = async (tutor: NFTTutor, match: MatchType) => {
  //   //Returns format for the studetns on the job
  //   let studentsOnJob = match.students.map((student) => {
  //     if (student.subjects[0]) {
  //       let charge_rate = student.subjects[0].subject_price;
  //       return {
  //         recipient: student.tutorcruncher_id!,
  //         charge_rate: Number(`${charge_rate}.0`),
  //       };
  //     }
  //   });

  //   let filteredStudents: { recipient: number; charge_rate: number }[] = [];

  //   studentsOnJob.forEach((obj) => {
  //     if (obj?.recipient && obj.charge_rate) {
  //       filteredStudents.push(obj!);
  //     }
  //   });

  //   let newJob = {
  //     tutor: {
  //       id: tutor.tutor_id,
  //     },
  //     jobName: renderJobName(match),
  //     students: filteredStudents,
  //     description: match.notes,
  //     match: match,
  //   };

  //   const request = await fetch(`${GetBaseURL()}/api/contracts/`, {
  //     method: 'POST',
  //     body: JSON.stringify(newJob),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });

  //   let response = await request.json();
  // };

  const EmailResourcesToClient = async (match: MatchType) => {
    let subjectsToSendResources: Subject[] = [];
    match.students.forEach((student) => {
      student.subjects.forEach((subject) => {
        subject.resources_required &&
        !subjectsToSendResources.find((subjectInArray) => {
          return subject.student === subjectInArray.student && subject.subject_name === subjectInArray.subject_name;
        })
          ? subjectsToSendResources.push(subject)
          : (subjectsToSendResources = subjectsToSendResources);
      });
    });

    let subjectsForRequest: { student: string; subject: string; grade: string }[] = subjectsToSendResources.map(
      (subject) => {
        return { student: subject.student, subject: subject.subject_name, grade: subject.subject_grade };
      },
    );
    if (subjectsForRequest.length > 0) {
      const requestBody = {
        subjects: subjectsForRequest,
        clientInfo: { email: match.client.email, name: match.client.full_name },
      };

      let request = await fetch(`${GetBaseURL()}/api/curriculum/sendResources`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  };

  // const deleteMatchFromMongo = async (match: MatchType) => {
  //   let request = await fetch(`${GetBaseURL()}/api/leads/${match._id}`, {
  //     method: 'DELETE',
  //   });

  //   return request.status;
  // };

  const postEntireContract = async (
    match: MatchType,
    {
      charge_rate,
      tutor_wage,
      tutor_id,
    }: {
      charge_rate: number;
      tutor_wage: number;
      tutor_id: number;
    },
  ) => {
    let matchForPost = {
      status: match.status,
      column: match.column,
      lead_source: match.lead_source,
      client: match.client,
      students: match.students,
      notes: match.notes,
      labels: match.labels,
      timestamp: match.timestamp,
      tutors_offered: match.tutors_offered,
      lead_form_type: match.lead_form_type,
      title: match.title,
    };

    let country = match.client.country;

    let postObject = {
      match: matchForPost,
      country,
      charge_rate,
      tutor_wage,
      tutor_id,
    };

    let request = await fetch(`${GetBaseURL()}/api/leads/newContract?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`, {
      method: 'POST',
      body: JSON.stringify(postObject),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return request.status;
  };

  return {
    selectTutorForContract,
    // saveMatchToMongo,
    // updateMatch,
    // updateStudentsBySubject,
    // removeSubjects,
    printSubjects,
    // postStudentToTC,
    // renderJobName,
    // postContractToTC,
    EmailResourcesToClient,
    // deleteMatchFromMongo,
    postEntireContract,
  };
};
