import { CondensedDateOptions, OverYearDateOptions, TimeOptions } from '../Models';

export const RenderTimestamp = (date: Date) => {
  const MONTHS: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

const getDaysDiffBetweenDates = (dateInitial: Date, dateFinal: number) =>
  (dateFinal - dateInitial.getTime()) / (1000 * 3600 * 24);

export const RenderRelativeDate = (date: Date) => {
  let today = new Date(Date.now()).setHours(0, 0, 0, 0);
  const compareDate = new Date(date);

  if (getDaysDiffBetweenDates(compareDate, today) <= 1) {
    // return 'Today';
    return compareDate.toLocaleString('en-US', TimeOptions);
  } else if (getDaysDiffBetweenDates(compareDate, today) > 1 && getDaysDiffBetweenDates(compareDate, today) <= 2) {
    return 'Yesterday';
  } else if (getDaysDiffBetweenDates(compareDate, today) > 2 && getDaysDiffBetweenDates(compareDate, today) < 7) {
    return compareDate.toLocaleDateString('en-US', { weekday: 'long' });
  } else if (getDaysDiffBetweenDates(compareDate, today) >= 7 && getDaysDiffBetweenDates(compareDate, today) < 365) {
    return compareDate.toLocaleString('en-US', CondensedDateOptions);
  } else {
    return compareDate.toLocaleString('en-US', OverYearDateOptions);
  }
};
