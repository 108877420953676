import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { WorksheetTextBlockType } from '../../Models';
import ErrorHandler from '../Partials/ErrorHandler';

type SegmentUpdateTypeSelectProps = {
  SelectHandler: (segmentType?: WorksheetTextBlockType) => void;
};

const SegmentUpdateTypeSelect: React.FC<SegmentUpdateTypeSelectProps> = ({ SelectHandler }) => {
  const [hasError, setHasError] = useState(false);
  const SelectElement = useRef<HTMLSelectElement>(null);

  const TypeArray: { type: WorksheetTextBlockType; label: string }[] = [
    { type: 'header', label: 'Header' },
    { type: 'paragraph', label: 'Paragraph' },
    { type: 'linkInText', label: 'Paragraph with link inside' },
    { type: 'list', label: 'List' },
  ];

  const SelectSegmentType = () => {
    if (SelectElement.current && SelectElement.current.value !== '') {
      const selectedValue = SelectElement.current.value! as WorksheetTextBlockType;
      SelectHandler(selectedValue);
    } else {
      setHasError(true);
    }
  };

  return (
    <div>
      {hasError && (
        <ErrorHandler
          type="error"
          message="The selection made is not valid. Please try again or contact the tech team."
          handler={() => {
            setHasError(false);
          }}
        />
      )}
      <select ref={SelectElement}>
        {[{ type: '', label: 'Select One' }, ...TypeArray].map((SingleSelectItem) => {
          return (
            <option value={SingleSelectItem.type} key={uuidv4()}>
              {SingleSelectItem.label}
            </option>
          );
        })}
      </select>
      <button onClick={SelectSegmentType}>Select Segment Type</button>
    </div>
  );
};

export default SegmentUpdateTypeSelect;
