import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { WorksheetsText, WorksheetsTextSegment, WorksheetTextBlockType } from '../Models';
import { GetBaseURL } from '../Utils';
import WorksheetText from '../Components/WorksheetForms/WorksheetText';
import SelectResourceToUpdate from '../Components/WorksheetForms/SelectResourceToUpdate';
import SegmentUpdateTypeSelect from '../Components/WorksheetForms/SegmentUpdateTypeSelect';
import UploadHeadingSegment from '../Components/WorksheetForms/UploadHeadingSegment';
import UploadParagraphSegment from '../Components/WorksheetForms/UploadParagraphSegment';
import UploadListSegment from '../Components/WorksheetForms/UploadListSegment';
import UploadLinkSegment from '../Components/WorksheetForms/UploadLinkSegment';
import ErrorHandler from '../Components/Partials/ErrorHandler';

type UploadStatusModel = {
  isActive: boolean;
  message: string;
  type: 'error' | 'success';
};

const WorksheetTextBlockUpload: React.FC = () => {
  const [targetResourceTitle, setTargetResourceTitle] = useState<null | string>(null);
  const [currentSegmentType, setCurrentSegmentType] = useState<null | WorksheetTextBlockType>(null);
  const [currentTextBlock, setCurrentTextBlock] = useState<WorksheetsText>([]);
  const [willDisableSubmit, setWillDisableSubmit] = useState(false);
  const [uploadStatus, setUploadStatus] = useState<UploadStatusModel>({
    isActive: false,
    message: '',
    type: 'error',
  });

  const SubmitCompletedTextBlock = async () => {
    setWillDisableSubmit(true);
    if (currentTextBlock.length > 0 && targetResourceTitle !== null) {
      const updateRequest = await fetch(
        `${GetBaseURL()}/api/resources/singleResourceTextBlock?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify({ title: targetResourceTitle, uniqueTextBlock: currentTextBlock }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      const response = await updateRequest.json();
      if (updateRequest.status === 200) {
        setUploadStatus({
          isActive: true,
          message: response.message ? response.message : 'Successfully updated document!',
          type: 'success',
        });
      } else {
        setUploadStatus({
          isActive: true,
          message: response.errorMessage
            ? response.errorMessage
            : 'There was an internal server error. Please try again or notify the tech team.',
          type: 'error',
        });
      }
    } else {
      setUploadStatus({
        isActive: true,
        message: 'Error Submitting Text. Please ensure title is entered and TextBlock has value.',
        type: 'error',
      });
    }

    setWillDisableSubmit(false);
  };

  const AppendSegmentToTextBlock = (segment: WorksheetsTextSegment) => {
    setCurrentTextBlock((prev: WorksheetsText) => [...prev, segment]);
    setCurrentSegmentType(null);
  };

  const SelectResourceToTarget = (newTitle?: string) => {
    setTargetResourceTitle(newTitle ? newTitle : null);
  };

  const SelectSegmentType = (segmentType?: WorksheetTextBlockType) => {
    setCurrentSegmentType(segmentType ? segmentType : null);
  };

  const SegmentTypeConversion = {
    list: 'List',
    paragraph: 'Paragraph',
    header: 'Header',
    linkInText: 'Paragraph with link inside',
  };

  return (
    <div style={{ marginTop: '150px' }}>
      <h3>Upload Text to Single Worksheet</h3>
      <div>
        <p>
          Current Document to Update:
          <span>{targetResourceTitle === null ? 'None Selected Yet' : targetResourceTitle}</span>
          {targetResourceTitle && (
            <i
              className="fas fa-times"
              onClick={() => {
                SelectResourceToTarget();
              }}
            ></i>
          )}
        </p>
      </div>
      <SelectResourceToUpdate SelectTitle={SelectResourceToTarget} />
      <div>
        <p>
          Current Segment to Add:
          <span>
            {currentSegmentType !== null && currentSegmentType in SegmentTypeConversion
              ? SegmentTypeConversion[currentSegmentType]
              : 'None Selected'}
          </span>
          {currentSegmentType && (
            <i
              className="fas fa-times"
              onClick={() => {
                SelectSegmentType();
              }}
            ></i>
          )}
        </p>
      </div>
      <SegmentUpdateTypeSelect SelectHandler={SelectSegmentType} />

      {currentSegmentType === 'header' && <UploadHeadingSegment UpdateTextBlock={AppendSegmentToTextBlock} />}

      {currentSegmentType === 'paragraph' && <UploadParagraphSegment UpdateTextBlock={AppendSegmentToTextBlock} />}

      {currentSegmentType === 'list' && <UploadListSegment UpdateTextBlock={AppendSegmentToTextBlock} />}

      {currentSegmentType === 'linkInText' && <UploadLinkSegment UpdateTextBlock={AppendSegmentToTextBlock} />}

      <h3>Text Preview: </h3>
      {currentTextBlock.length > 0 ? (
        <React.Fragment key={uuidv4()}>
          <WorksheetText textData={currentTextBlock} />
        </React.Fragment>
      ) : (
        <p>No Inputs Given Yet.</p>
      )}

      {uploadStatus.isActive && (
        <ErrorHandler
          type={uploadStatus.type}
          message={uploadStatus.message}
          handler={() => {
            if (uploadStatus.type === 'success') {
              setCurrentTextBlock([]);
            }

            setUploadStatus({
              isActive: false,
              message: '',
              type: 'error',
            });
          }}
        />
      )}

      {targetResourceTitle && currentTextBlock.length > 0 && (
        <button disabled={willDisableSubmit} onClick={SubmitCompletedTextBlock}>
          Submit Text
        </button>
      )}
    </div>
  );
};

export default WorksheetTextBlockUpload;
