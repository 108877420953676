import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ErrorHandler from '../Partials/ErrorHandler';
import { GetBaseURL } from '../../Utils';

type FetchedResourceNames = {
  titles: string[];
  pdfFilenames: string[];
  imageFilenames: string[];
};

type SelectResourceToUpdateProps = {
  SelectTitle: (newTitle?: string) => void;
};

const SelectResourceToUpdate: React.FC<SelectResourceToUpdateProps> = ({ SelectTitle }) => {
  const [willFetchResourceNames, setWillFetchResourceNames] = useState(true);
  const [inputText, setInputText] = useState('');
  const [resourceNames, setResourceNames] = useState<FetchedResourceNames>({
    titles: [],
    pdfFilenames: [],
    imageFilenames: [],
  });
  const [hasErrorWithTitle, setHasErrorWithTitle] = useState(false);
  const TitleInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      willFetchResourceNames &&
      resourceNames.titles.length === 0 &&
      resourceNames.pdfFilenames.length === 0 &&
      resourceNames.imageFilenames.length === 0
    ) {
      (async () => {
        let requestForTitles = await fetch(
          `${GetBaseURL()}/api/resources/fileNames?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
        );

        let titleData = await requestForTitles.json();
        setResourceNames((prev: FetchedResourceNames) => ({
          ...titleData,
        }));
        setWillFetchResourceNames(false);
      })();
    }
  }, [willFetchResourceNames, resourceNames]);

  const UpdateTitleInput = () => {
    TitleInputRef.current && TitleInputRef.current.value !== ''
      ? setInputText(TitleInputRef.current.value)
      : setInputText('');
  };

  const FilteredOptions = resourceNames.titles.filter((singleTitle) => {
    return singleTitle.toLowerCase().includes(inputText.toLowerCase());
  });

  return (
    <section>
      <div>
        <label>Resource to Fetch: </label>
        <input onChange={UpdateTitleInput} ref={TitleInputRef} type="text" placeholder="Resource to Update..." />
      </div>
      {hasErrorWithTitle && (
        <ErrorHandler
          type="error"
          message="The title entered does not match one from our DB. Please check spelling and case."
          handler={() => {
            setHasErrorWithTitle(false);
          }}
        />
      )}
      <div>
        {inputText !== '' &&
          FilteredOptions.slice(0, 4).map((singleOption) => {
            return <p key={uuidv4()}>{singleOption}</p>;
          })}
      </div>
      <button
        onClick={() => {
          if (resourceNames.titles.includes(inputText)) {
            SelectTitle(inputText);
            if (TitleInputRef.current) {
              TitleInputRef.current.value = '';
            }
          } else {
            setHasErrorWithTitle(true);
          }
        }}
      >
        Select Title
      </button>
    </section>
  );
};

export default SelectResourceToUpdate;
