import React, { RefObject } from 'react';
import Triangle from '../../assets/triangle.png';
import { StudentType, Subject } from '../../Models';
interface DropdownProps {
  className?: string;
  refer?: RefObject<HTMLSelectElement>;
  name?: string;
  items: {
    label: string;
    value: string;
  }[];
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>, student?: StudentType, subject?: Subject) => void;
  onBlur?: () => void;
}
export default function DropDown(props: DropdownProps) {
  const [items] = React.useState(props.items);
  return (
    <div className="select-container">
      <select
        className={props.className}
        ref={props.refer}
        name={props.name}
        defaultValue={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        required={props.required}
        data-cy={props.name}
      >
        {items.map((item) => {
          return (
            <option key={item.label} value={item.value} data-cy={item.label}>
              {item.label}
            </option>
          );
        })}
      </select>
      <div className="select-icon">
        <img src={Triangle} alt="" />
      </div>
    </div>
  );
}
