import { NFTSubjectList } from './SubjectTypes';
import { Subject } from './index';
import { GetBaseURL } from '../Utils';

export enum StudentGrades {
  Preschool = 'Preschool',
  Kindergarten = 'Kindergarten',
  Grade1 = 'Grade 1',
  Grade2 = 'Grade 2',
  Grade3 = 'Grade 3',
  Grade4 = 'Grade 4',
  Grade5 = 'Grade 5',
  Grade6 = 'Grade 6',
  Grade7 = 'Grade 7',
  Grade8 = 'Grade 8',
  Grade9 = 'Grade 9',
  Grade10 = 'Grade 10',
  Grade11 = 'Grade 11',
  Grade12 = 'Grade 12',
  'College/University' = 'College/University',
}

// export class Student {
//   constructor(
//     public student_name: string = '',
//     public student_phone: string = '',
//     public student_email: string = '',
//     public student_grade: string | StudentGrades = StudentGrades.Kindergarten,
//     public subjects: Subject[] = [],
//     public tutorcruncher_id?: number,
//   ) {}

// updateStringValue(studentProperty: keyof Student, newValue: string) {
//   if (
//     studentProperty === 'student_name' ||
//     studentProperty === 'student_phone' ||
//     studentProperty === 'student_email'
//   ) {
//     this[studentProperty] = newValue;
//   } else {
//     alert('There was an error with the client property provided');
//   }
// }

// instantiateSubject() {
//   let newSubject = new Subject(this.student_name, NFTSubjectList.English, this.student_grade, 30, '', '');

//   newSubject.subject_grade !== StudentGrades.Kindergarten && Number(newSubject.subject_grade.split(' ')[1]) > 11
//     ? (newSubject.subject_price = 40)
//     : (newSubject.subject_price = 30);

//   this.subjects.push(newSubject);
// }

// async saveStudentToTC(clientID: number, country: string) {
//   let postObject = {
//     newStudent: {
//       user: {
//         first_name: this.student_name.split(' ')[0],
//         last_name: this.student_name.split(' ')[1], //!THis needs to be a better function....
//         email: this.student_email === '' ? undefined : this.student_email,
//         phone: this.student_phone,
//       },
//       paying_client: clientID,
//     },
//     oldStudent: this,
//     country: country,
//   };

//   const request = await fetch(`${GetBaseURL()}/api/students/postToTutorcruncher`, {
//     method: 'POST',
//     body: JSON.stringify(postObject),
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });

//   if (request.status === 200) {
//     let response = await request.json();
//     this.tutorcruncher_id = response.id;
//     return { id: response.id, successfulSave: true };
//   } else {
//     return {
//       successfulSave: false,
//     };
//   }
// }

// async updateStudentInTC(clientID: number, country: string) {
//   let newStudent = {
//     newStudent: {
//       user: {
//         first_name: this.student_name.split(' ')[0],
//         last_name: this.student_name.split(' ')[1], //!THis needs to be a better function....
//         email: this.student_email,
//         phone: this.student_phone,
//       },
//       paying_client: clientID,
//     },
//     oldStudent: this,
//     country: country,
//   };

//   const request = await fetch(`${GetBaseURL()}/api/students/updateInTC`, {
//     method: 'POST',
//     body: JSON.stringify(newStudent),
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });

//   if (request.status === 200) {
//     let response = await request.json();
//     this.tutorcruncher_id = response.id;
//     return { id: response.id, successfulSave: true };
//   } else {
//     return {
//       successfulSave: false,
//     };
//   }
// }
// }

export type StudentType = {
  student_name: string;
  student_phone: string;
  student_email: string;
  grade: string;
  subjects: Subject[];
  skill_level_and_goals: string;
  first_appointment: string;
  tutorcruncher_id?: number;
};

export enum AdminLeadsFilterStatus {
  Lead = 'Lead',
  Match = 'Match',
}

export enum AdminLeadsFilterColumn {
  NewLead = 'New Lead',
  FutureStart = 'Future Start',
  Contacted = 'Contacted',
  Blocked = 'Blocked',
  UnableToContact = 'Unable to Contact',
  Free = 'Free',
  Matt = 'Matt',
  Suhail = 'Suhail',
  Ruth = 'Ruth',
}
