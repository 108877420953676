import React, { useState, useEffect } from 'react';
//Components
import MatchModal from './MatchModal';
import ErrorHandler from '../Partials/ErrorHandler';
//Types/Classes
import { AdminLeadsFilterColumn, CardData } from '../../Models';
//Hooks/Utils
import { Drop, DragOver, GetNumberForTwilio } from '../../Utils';
import { useLeadCards } from '../Hooks/Lead/useLeadCards';
//Other Imports
import FaceI from '../../assets/face.png';

type RenderMatchProps = {
  componentTitle: string;
  column: AdminLeadsFilterColumn;
  hasData: Boolean;
  RefreshComponent: (value: boolean) => void;
  refresh: boolean;
};

const RenderMatch: React.FC<RenderMatchProps> = ({ componentTitle, column, hasData, RefreshComponent, refresh }) => {
  const [dropError, setDropError] = useState(false);
  const [dropErrorMessage, setDropErrorMessage] = useState('');
  const [cardInfo, setCardInfo] = useState<CardData[]>([]);
  const { fetchColumnCards, updateColumnOfCard, cardContext } = useLeadCards();

  useEffect(() => {
    if (cardContext.filter === '' && hasData) {
      setCardInfo(fetchColumnCards(column));
    } else if (hasData) {
      let allCards = fetchColumnCards(column);
      let query = cardContext.filter.toLowerCase();

      let filtered = allCards.filter((singleCard: CardData) => {
        let phone = GetNumberForTwilio(singleCard.client_phone);
        return (
          singleCard.client_city.toLowerCase().includes(query) ||
          singleCard.client_name.toLowerCase().includes(query) ||
          phone.includes(query) ||
          singleCard.lead_form_type?.toLowerCase().includes(query) ||
          singleCard.lead_source.toLowerCase().includes(query) ||
          singleCard.notes.toLowerCase().includes(query) ||
          singleCard.title?.toLowerCase().includes(query) ||
          singleCard.labels.join(' ').toLowerCase().includes(query)
        );
      });
      setCardInfo(filtered);
    }
  }, [cardContext.filter, hasData]);
  useEffect(() => {
    if (refresh === true) {
      setCardInfo(fetchColumnCards(column));
      RefreshComponent(false);
    }
  }, [refresh]);

  if (cardInfo.length > 0) {
    const RENDER_ALL_MATCHES = cardInfo.map((obj, index) => {
      return (
        <div key={obj._id}>
          <MatchModal cardInfo={obj} />
        </div>
      );
    });

    return (
      <div
        className="match-card-width"
        onDragOver={DragOver}
        onDrop={async (event: React.DragEvent<HTMLElement>) => {
          let resultOfDrop: { status: number; message: string; id: string } = await Drop(event, column);
          if (resultOfDrop.status === 200) {
            updateColumnOfCard(resultOfDrop.id, column);
            RefreshComponent(true);
          } else {
            setDropError(true);
            setDropErrorMessage(resultOfDrop.message);
          }
        }}
      >
        <div className="header-and-total match-card-width">
          <div className="icon-margin">
            <img src={FaceI} alt=""></img>
          </div>
          {dropError && dropErrorMessage !== '' ? (
            <ErrorHandler
              handler={() => {
                setDropError(false);
                setDropErrorMessage('');
              }}
              type="error"
              error={dropErrorMessage}
            />
          ) : null}
          <h3>{componentTitle}</h3>
          <span>{RENDER_ALL_MATCHES.length}</span>
        </div>
        {RENDER_ALL_MATCHES}
      </div>
    );
  } else {
    return (
      <div
        className="match-card-width"
        onDragOver={DragOver}
        onDrop={async (event: React.DragEvent<HTMLElement>) => {
          let resultOfDrop: { status: number; message: string; id: string } = await Drop(event, column);
          if (resultOfDrop.status === 200) {
            updateColumnOfCard(resultOfDrop.id, column);
            RefreshComponent(true);
          } else {
            setDropError(true);
            setDropErrorMessage(resultOfDrop.message);
          }
        }}
      >
        <div className="header-and-total match-card-width">
          <div className="icon-margin">
            <img src={FaceI} alt=""></img>
          </div>
          {dropError && dropErrorMessage !== '' ? (
            <ErrorHandler
              handler={() => {
                setDropError(false);
                setDropErrorMessage('');
              }}
              type="error"
              error={dropErrorMessage}
            />
          ) : null}
          <h3>{componentTitle}</h3>
        </div>
        <p className="card shadow default-margin-top default-padding-top match-card-width">No Active Matches</p>
      </div>
    );
  }
};

export default RenderMatch;
