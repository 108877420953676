import React from 'react';
import { WorksheetsTextSegment } from '../../Models';
import { v4 as uuidv4 } from 'uuid';

type WorksheetTextParagraphProps = {
  segment: WorksheetsTextSegment;
};

const WorksheetTextParagraph: React.FC<WorksheetTextParagraphProps> = ({ segment }) => {
  return (
    <React.Fragment key={uuidv4()}>
      <p>{segment.type === 'paragraph' && typeof segment.content === 'string' && segment.content}</p>
    </React.Fragment>
  );
};

export default WorksheetTextParagraph;
