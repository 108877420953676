import { useContext } from 'react';
import { useLeadData } from './useLeadData';
import { LeadContractContext } from '../../Context/LeadContractContext';
import { Contract, NFTTutor, StudentType, Subject } from '../../../Models';

export const useLeadContracts = () => {
  const { contracts, setContracts } = useContext(LeadContractContext);
  const { updateContracts } = useLeadData();

  const addContract = (singleContract: Contract) => {
    const updatedContracts = [...contracts, singleContract];
    setContracts(updatedContracts);
    updateContracts(updatedContracts);
  };

  const removeContract = (indexOfContract: number) => {
    // let updatedArray = contracts.slice(indexOfContract, indexOfContract + 1);
    let updatedArray = contracts.filter((contract, index) => {
      return indexOfContract !== index;
    });
    setContracts(updatedArray);
    updateContracts(updatedArray);
  };

  const removeSubjectFromContract = (indexOfContract: number, subject: Subject) => {
    let newSubjects = contracts[indexOfContract].subjects.filter((singleSubject) => {
      return (
        // singleSubject.Student !== subject.Student ||
        singleSubject.student !== subject.student ||
        singleSubject.subject_name !== subject.subject_name ||
        singleSubject.subject_grade !== subject.subject_grade
      );
    });

    let updatedContracts: Contract[] = contracts.map((singleContract, index) => {
      if (index === indexOfContract) {
        return {
          ...singleContract,
          subjects: newSubjects,
        };
      } else {
        return singleContract;
      }
    });

    setContracts(updatedContracts);
    updateContracts(updatedContracts);
  };

  const setSubjects = (subjects: Subject[], indexOfContract: number) => {
    if (indexOfContract >= contracts.length) {
      let newContracts: Contract[] = [
        ...contracts,
        { subjects: subjects, tutors_by_location: [], tutors_by_subject: [] },
      ];

      setContracts(newContracts);
    } else {
      let updatedContracts: Contract[] = contracts.map((singleContract, index) => {
        if (index === indexOfContract) {
          return {
            ...singleContract,
            subjects: subjects,
          };
        } else {
          return singleContract;
        }
      });

      setContracts(updatedContracts);
      updateContracts(updatedContracts);
    }
  };

  const setTutorsByLocation = (tutors: NFTTutor[], indexOfContract: number) => {
    let updatedContracts: Contract[] = contracts.map((singleContract, index) => {
      if (index === indexOfContract) {
        return {
          ...singleContract,
          tutors_by_location: tutors,
        };
      } else {
        return singleContract;
      }
    });

    setContracts(updatedContracts);
    updateContracts(updatedContracts);
  };

  const setTutorsBySubject = (tutors: NFTTutor[], indexOfContract: number) => {
    let updatedContracts: Contract[] = contracts.map((singleContract, index) => {
      if (index === indexOfContract) {
        return {
          ...singleContract,
          tutors_by_subject: tutors,
        };
      } else {
        return singleContract;
      }
    });

    setContracts(updatedContracts);
    updateContracts(updatedContracts);
  };

  const updateSelectedTutors = (tutor: NFTTutor, indexOfContract: number) => {
    let contractToUpdate = contracts[indexOfContract];

    const TUTOR_ALREADY_SELECTED = contractToUpdate.selectedTutors?.find((selectedTutor: NFTTutor) => {
      return selectedTutor.tutor_id === tutor.tutor_id;
    });

    if (TUTOR_ALREADY_SELECTED) {
      const UPDATED_TUTORS = contractToUpdate.selectedTutors?.filter((previousTutor: NFTTutor) => {
        return TUTOR_ALREADY_SELECTED.tutor_id !== previousTutor.tutor_id;
      });

      let updatedContracts = contracts.map((singleContract, index) => {
        if (index === indexOfContract) {
          return {
            ...singleContract,
            selectedTutors: UPDATED_TUTORS,
          };
        } else {
          return singleContract;
        }
      });
      setContracts(updatedContracts);
      updateContracts(updatedContracts);
    } else if (contractToUpdate.selectedTutors) {
      let updatedContracts = contracts.map((singleContract, index) => {
        if (index === indexOfContract) {
          return {
            ...singleContract,
            selectedTutors: [tutor, ...singleContract.selectedTutors!],
          };
        } else {
          return singleContract;
        }
      });
      setContracts(updatedContracts);
      updateContracts(updatedContracts);
    } else {
      let updatedContracts = contracts.map((singleContract, index) => {
        if (index === indexOfContract) {
          return {
            ...singleContract,
            selectedTutors: [tutor],
          };
        } else {
          return singleContract;
        }
      });
      setContracts(updatedContracts);
      updateContracts(updatedContracts);
    }
  };

  // const removeStudentFromContracts = (student: StudentType | Student) => {
  const removeStudentFromContracts = (student: StudentType) => {
    const updatedContracts = contracts.map((singleContract) => {
      let filteredSubjects = singleContract.subjects.filter((singleSubject: Subject) => {
        return singleSubject.student !== student.student_name;
      });

      return {
        ...singleContract,
        subjects: filteredSubjects,
      };
    });
    setContracts(updatedContracts);
    updateContracts(updatedContracts);
  };

  return {
    contracts,
    addContract,
    removeContract,
    removeSubjectFromContract,
    setSubjects,
    setTutorsByLocation,
    setTutorsBySubject,
    updateSelectedTutors,
    removeStudentFromContracts,
  };
};
