import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//Types
import { DragStart, renderClassname, GetBaseURL } from '../../Utils';
import { CardData } from '../../Models';
import { useLeadCards } from '../Hooks/Lead/useLeadCards';

type MatchModalProps = {
  cardInfo: CardData;
};

const MatchModal: React.FC<MatchModalProps> = ({ cardInfo }) => {
  const [title, setTitle] = useState<string>(cardInfo.title ? cardInfo.title : cardInfo.client_name);
  const [editTitle, setEditTitle] = useState(false);
  const { updateCardTitle } = useLeadCards();

  return (
    <div
      draggable
      onDragStart={(event: React.DragEvent<HTMLElement>) => {
        DragStart(event, cardInfo._id);
      }}
      key={cardInfo._id}
      className="card shadow grid-child"
    >
      <Link className="none" to={`/admin/matches/${cardInfo._id}`}>
        <div className="tag-and-icon-container" data-cy="match_modal--link">
          <div className="expand">
            <span>...</span>
          </div>
        </div>
      </Link>
      {editTitle ? (
        <input
          className="card-title"
          type="text"
          name="title"
          autoFocus
          onBlur={(e) => {
            fetch(`${GetBaseURL()}/api/leads/updateLead/title/${cardInfo._id}`, {
              method: 'POST',
              body: JSON.stringify({ newTitle: e.target.value }),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            setTitle(e.target.value);
            updateCardTitle(cardInfo._id, e.target.value);
            setEditTitle(false);
          }}
          defaultValue={title}
        ></input>
      ) : (
        <h4
          onClick={() => {
            setEditTitle(true);
          }}
          data-cy="match_modal--title"
        >
          {title}
        </h4>
      )}
      <a href={`tel: +1${cardInfo.client_phone}`} className="none phone-link">
        {cardInfo.client_phone !== '' ? cardInfo.client_phone : null}
      </a>
      <Link className="none" to={`/admin/matches/${cardInfo._id}`}>
        {cardInfo.client_city !== '' ? <p>{cardInfo.client_city}</p> : null}
        {cardInfo.notes !== '' ? <p className="scroll notes">{cardInfo.notes}</p> : null}
        <div className="labels-container">
          {cardInfo.labels.map((label, index) => {
            return (
              <div className={renderClassname(label)} key={index}>
                {label}
              </div>
            );
          })}
        </div>
      </Link>
    </div>
  );
};

export default MatchModal;
