import React, { useRef, FormEvent, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
//Components
import RenderStudentInLead from './RenderStudentInLead';
import Dropdown from '../Partials/Dropdown';
import ErrorHandler from '../Partials/ErrorHandler';
//Types
import {
  LeadType,
  AdminLeadsFilterColumn,
  columns,
  ClientType,
  Subject,
  StudentType,
  leadSources,
  leadFormType,
  ErrorProps,
} from '../../Models';
//Utils
import { RenderTimestamp, renderClassname, FindContactByPhone } from '../../Utils';
import { RenderClient } from './RenderClient';
import { CreateContract } from './CreateContract';
import DeleteLead from '../Partials/DeleteLead';
//Context
import { DisplayContextProvider } from '../Context/DisplayContext';
import { ContractContextProvider } from '../Context/ContractContext';
import { useLeadData } from '../Hooks/Lead/useLeadData';
import { useClientData } from '../Hooks/Lead/useClientData';
import { useStudentData } from '../Hooks/Lead/useStudentData';
import { useSubjectData } from '../Hooks/Lead/useSubjectData';
import { useLeadContracts } from '../Hooks/Lead/useLeadContracts';
import { socket } from '../Hooks/useUserData';

type FormProps = {
  lead: LeadType;
  newLead: boolean;
  title?: string;
};

const LeadsForm: React.FC<FormProps> = ({ newLead, title }) => {
  //Context/Hooks
  const { lead, handleInputChange, handleSelect, handleTextChange, updateLeadID, save, update } = useLeadData();
  const { updateClientID, initializeClientAsStudent } = useClientData();
  const { initializeEmptyStudent } = useStudentData();
  const { getNumberOfSubjects } = useSubjectData();
  const { contracts, removeContract, addContract } = useLeadContracts();
  //State
  const [numberOfStudents, setNumberOfStudents] = useState(lead!.students.length);
  const [numberOfSubjects, setNumberOfSubjects] = useState(lead!.number_of_subjects);
  const [clientIsStudent, setClientIsStudent] = useState(lead!.client.isStudent);
  const [client, setClient] = useState<ClientType>(lead!.client);
  const [students, setStudents] = useState<StudentType[]>(lead!.students);
  const [willRedirect, setWillRedirect] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  //Error State
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });
  //Refs
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (clientIsStudent && lead.students.length === 0) {
      let newStudents = initializeClientAsStudent();
      setStudents(newStudents);
      setNumberOfStudents(1);
    }
  }, [clientIsStudent]);

  useEffect(() => {
    if (lead?.contracts && lead.contracts.length > 0) {
      lead.contracts.forEach((singleContract) => {
        addContract(singleContract);
      });
    }
  }, []);

  //Setting students to use in order to map through them and reflect state updates
  useEffect(() => {
    setStudents(lead!.students);
  }, [lead!.students]);

  //Reset error function
  const resetError = () => {
    setError(() => ({
      isActive: false,
      error: '',
      message: '',
      type: 'error',
      redirect: false,
      icon: '',
    }));
  };

  const resetErrorWithRedirect = () => {
    resetError();
    setWillRedirect(true);
  };

  const buildSaveLeadSuccess = () => {
    setError((prev: any) => ({
      ...prev,
      isActive: true,
      message: 'Lead was successfully saved!',
      type: 'success',
      redirect: true,
      icon: 'fas fa-check-circle',
    }));
  };

  const buildSaveLeadError = () => {
    setError((prev: any) => ({
      ...prev,
      isActive: true,
      message: 'There was an error saving the Lead. Please Try again.',
      type: 'error',
      redirect: false,
    }));
  };

  //Functions passed into client component
  const SetClientIsStudent = (value: boolean) => {
    setClientIsStudent(value);
  };

  //Updating state on "Add" buttons
  const UpdateNumberOfSubjects = () => {
    setNumberOfSubjects(getNumberOfSubjects());
  };

  //Function that will update the students and reflect the removal of a student
  const UpdateStudents = () => {
    setStudents(lead!.students);
  };

  if (willRedirect) {
    return <Redirect to="/admin/leads" />;
  } else if (!lead) {
    return <h3>Loading...</h3>;
  } else {
    return (
      <DisplayContextProvider>
        <form
          onSubmit={(event: FormEvent) => {
            event.preventDefault();
          }}
          data-cy="lead_form"
        >
          {editTitle ? (
            <input
              className="title card-title"
              type="text"
              name="title"
              autoFocus
              onBlur={(e) => {
                handleInputChange(e);
                setEditTitle(false);
              }}
              defaultValue={
                lead.title ? lead.title : lead.client.full_name !== '' ? lead.client.full_name : 'Create New Lead'
              }
            ></input>
          ) : (
            <h2
              onClick={() => {
                setEditTitle(true);
              }}
              data-cy="lead_form_title--set_edit"
            >
              {lead.title ? lead.title : lead.client.full_name !== '' ? lead.client.full_name : 'Create New Lead'}
            </h2>
          )}
          <section id="LEAD_DATA" className="row">
            {
              //Currently only displaying LeadSource for leads that have this field. Will want to change that moving forward
              typeof lead!.lead_source === 'string' ? (
                <div>
                  <label>Lead Source</label>
                  <Dropdown
                    name="lead_source"
                    items={leadSources}
                    value={lead.lead_source}
                    onChange={(e) => {
                      handleSelect(e);
                    }}
                  />
                </div>
              ) : (
                <div>Lead Source</div>
              )
            }

            {lead!.timestamp ? (
              <div className="timestamp">
                <label>Date Created</label>
                <input name="timestamp" type="text" disabled value={RenderTimestamp(new Date(lead!.timestamp))} />
              </div>
            ) : null}
          </section>

          <section className="row">
            <div>
              <label>Lead Column</label>
              <Dropdown
                name="column"
                items={columns}
                value={lead!.column ? lead!.column : AdminLeadsFilterColumn.NewLead}
                onChange={(e) => {
                  handleSelect(e);
                }}
              />
            </div>
            <div>
              <label>Lead Form Type</label>
              <Dropdown
                name="lead_form_type"
                items={leadFormType}
                value={lead!.lead_form_type}
                onChange={(e) => {
                  handleSelect(e);
                }}
              />
            </div>
          </section>

          {lead!.labels.length > 0 ? (
            <section className="tags default-margin-top">
              <label>Tags</label>
              <ul className="label-container">
                {
                  //!Here we want to render labels
                  lead!.labels.map((label, index) => {
                    return (
                      <div key={index}>
                        <span className={renderClassname(label)} key={index}>
                          {label}
                        </span>
                      </div>
                    );
                  })
                }
              </ul>
            </section>
          ) : null}

          {client !== null ? <RenderClient setClientIsStudent={SetClientIsStudent} /> : null}

          <section id="EXTRA_FIELDS">
            <div>
              <label>Notes</label>
              <textarea
                name="notes"
                rows={5}
                defaultValue={lead!.notes}
                onBlur={(e) => {
                  handleTextChange(e);
                }}
              />
            </div>
          </section>
          {numberOfStudents === 0 ? null : (
            <section id="STUDENT_DETAILS">
              <div>
                {lead!.students.map((student, index) => {
                  return (
                    <React.Fragment key={index}>
                      <RenderStudentInLead
                        student={student}
                        UpdateNumberOfSubjects={UpdateNumberOfSubjects}
                        lead={lead!}
                        UpdateStudents={UpdateStudents}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </section>
          )}

          {clientIsStudent ? null : (
            <div
              className="add"
              onClick={() => {
                initializeEmptyStudent();
                setNumberOfStudents(numberOfStudents + 1);
              }}
              data-cy="student_add"
            >
              {lead!.students.length === 0 ? '+ Add Student' : '+ Add Another Student'}
            </div>
          )}

          {/* //!Create Contracts */}
          <section>
            {/* {contractArray.map((subjects, index) => { */}
            {contracts.map((subjects, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="heading default-margin-top">
                    <h3>Contract</h3>
                    <span
                      className="delete"
                      id={`REMOVE_CONTRACT_${index}`}
                      onClick={() => {
                        removeContract(index);
                      }}
                    >
                      Remove Contract
                    </span>
                  </div>
                  <ContractContextProvider>
                    <CreateContract subjects={contracts[index].subjects} index={index} />
                  </ContractContextProvider>
                </React.Fragment>
              );
            })}

            <div
              className="add"
              data-cy="add_contract"
              onClick={() => {
                //search lead students. If any of each student's subjects are found in the contract array, do NOT push them into new array
                let newContract: Subject[] = [];
                let activesubjects = lead!.contracts.map((contract) => {
                  return contract.subjects;
                });
                lead!.students.forEach((student) => [
                  student.subjects.forEach((subject) => {
                    if (
                      !activesubjects
                        .flat()
                        .some(
                          (activeSubject) =>
                            activeSubject.student === subject.student &&
                            activeSubject.subject_name === subject.subject_name,
                        )
                    ) {
                      newContract.push(subject);
                    }
                  }),
                ]);
                if (newContract.length === 0) {
                  setError((prev: any) => ({
                    ...prev,
                    isActive: true,
                    message: 'Cannot create a contract without available subjects.',
                    type: 'error',
                    redirect: false,
                  }));
                } else if (lead!.client.country === '') {
                  setError((prev: any) => ({
                    ...prev,
                    isActive: true,
                    message: 'Please choose country before creating a contract.',
                    type: 'error',
                    redirect: false,
                  }));
                } else {
                  const contractToAdd = {
                    subjects: newContract,
                    tutors_by_location: [],
                    tutors_by_subject: [],
                  };
                  addContract(contractToAdd);
                }
              }}
            >
              + Add Contract
            </div>
          </section>

          {error.isActive ? (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                error.redirect ? resetErrorWithRedirect() : resetError();
              }}
            />
          ) : null}

          <section className="save-buttons">
            {lead!._id ? (
              <DeleteLead id={Number(lead!._id)} lead={lead!} />
            ) : (
              <div className="default-margin-bottom"></div>
            )}

            <button
              className="save input-margin-left absolute-btn"
              ref={saveButtonRef}
              onClick={async () => {
                if (saveButtonRef.current) {
                  saveButtonRef.current.setAttribute('disabled', 'true');
                }

                let oldContInfo = await FindContactByPhone(lead.client.phone_number);

                if (
                  lead!.client.country === '' ||
                  lead!.client.country === 'Select One' ||
                  lead!.client.email === '' ||
                  lead!.client.country === '' ||
                  lead!.client.full_name === ''
                ) {
                  saveButtonRef.current!.removeAttribute('disabled');
                  setError((prev: any) => ({
                    ...prev,
                    isActive: true,
                    message: 'NAME, EMAIL, and COUNTRY fields are required to save client.',
                    type: 'error',
                    redirect: false,
                  }));
                  return null;
                } else if (lead._id) {
                  // let updateLeadInMongo = await lead!.update();
                  let updateLeadInMongo = await update(lead);
                  if (updateLeadInMongo.successfulSave === true) {
                    socket.emit('updateCache', {
                      newMessage: null,
                      contInfo: { ...oldContInfo, associated_lead: lead, contact_data: undefined },
                    });
                    updateClientID(updateLeadInMongo.id!);
                    buildSaveLeadSuccess();
                  } else {
                    resetError();
                    return null;
                  }
                } else {
                  // lead!.save().then((res) => {
                  save(lead).then((res) => {
                    if (res !== 'Failed to save') {
                      socket.emit('updateCache', {
                        newMessage: null,
                        contInfo: { ...oldContInfo, associated_lead: lead, contact_data: undefined },
                      });
                      updateLeadID(res);
                      buildSaveLeadSuccess();
                    } else {
                      buildSaveLeadError();
                    }
                  });
                }
              }}
              data-cy="lead_save"
            >
              Save Lead
            </button>
          </section>
        </form>
      </DisplayContextProvider>
    );
  }
};

export default LeadsForm;
