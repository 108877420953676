import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { plainToClass } from 'class-transformer';
import { GetBaseURL } from '../Utils';

import AdminNav from '../Components/AdminNav';
import MatchForm from '../Components/MatchingForm/MatchForm';
import { MatchType, StudentType, ClientType, Subject } from '../Models';

import { useMatchData } from '../Components/Hooks/Lead/useMatchData';
import { useLeadData } from '../Components/Hooks/Lead/useLeadData';

const SingleMatch: React.FC<RouteComponentProps> = (RouteComponentProps) => {
  // const { updateMatch } = useMatchData();
  const { update } = useLeadData();
  const [willRedirect, setWillRedirect] = useState(false); //State that determines redirects to leads Page
  const [hasMatch, setHasMatch] = useState(false); //Essentially loading state
  const [match, setMatch] = useState<MatchType | null>(null);

  const returnMatchID = () => {
    let location: string = RouteComponentProps.location.pathname;
    return location.split('/')[location.split('/').length - 1];
  };

  useEffect(() => {
    (async () => {
      const requestLead = await fetch(`${GetBaseURL()}/api/leads/${returnMatchID()}`);
      const response: MatchType = await requestLead.json();
      if (response.students === undefined || response.client === undefined) {
        //When deletes happen this function runs and students + clients are undefined -- we get errors.
        //This will redirect them to leadsPage
        setWillRedirect(true);
      } else {
        // const CLIENT = plainToClass(Client, response.client);
        let allStudents: StudentType[] = [];
        response.students.forEach((student) => {
          // let studentSubjects = student.subjects.map((subject) => {
          //   return plainToClass(Subject, subject);
          // });
          let newStudent: StudentType = {
            student_name: student.student_name,
            student_phone: student.student_phone,
            student_email: student.student_email,
            grade: student.grade,
            subjects: student.subjects,
            skill_level_and_goals: student.skill_level_and_goals,
            first_appointment: student.first_appointment,
            tutorcruncher_id: student.tutorcruncher_id,
          };
          allStudents.push(newStudent);
        });

        let convertedMatch: MatchType = {
          status: response.status,
          column: response.column,
          lead_source: response.lead_source,
          client: response.client,
          students: allStudents,
          notes: response.notes,
          labels: response.labels,
          tutors_offered: response.tutors_offered,
          lead_form_type: response.lead_form_type,
          title: response.title,
          _id: response._id!.toString(),
        };

        //!Here we want to set lead (context) with a populated lead
        setMatch(convertedMatch);
        setHasMatch(true);
      }
    })();
  }, []);

  if (willRedirect) {
    return <Redirect to="/admin/matches" />;
  } else if (hasMatch && match !== null) {
    return (
      <>
        <AdminNav />
        <main
          className="modal-background"
          onClick={async () => {
            // lead must include a client name and email to save
            // await updateMatch(match);
            await update(match);
            setWillRedirect(true);
          }}
        >
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="card shadow form-radius default-margin-top-bottom modal-child"
          >
            <MatchForm match={match} />
          </div>
        </main>
      </>
    );
  } else {
    return <h3>Loading...</h3>;
  }
};

export default SingleMatch;
