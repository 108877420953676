import React, { useState, useRef, useEffect, RefObject } from 'react';
import DropDown from '../Components/Partials/Dropdown';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { studentGrades, ErrorProps } from '../Models';
import { GetBaseURL, SendEmail } from '../Utils';

type Page = {
  page_number: number;
  page_text: string;
  page_image: string;
  lines: string[];
};

type StoryType = {
  title: string;
  level: string;
  cover: string;
  pages: Page[];
  tags: string[];
  associated_words: string[];
  associated_assessment?: string;
};

type DocumentNames = {
  titles: string[];
  imageFilenames: string[];
};

const StoryUpload: React.FC = () => {
  const [storyDoc, setStoryDoc] = useState<StoryType>({
    title: '',
    level: '',
    cover: '',
    pages: [],
    tags: [],
    associated_words: [],
  });
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });
  const [willRenderPreview, setWillRenderPreview] = useState(false);
  const [documentNames, setDocumentNames] = useState<DocumentNames>({
    titles: [],
    imageFilenames: [],
  });
  const [willFetchDocumentNames, setWillSetDocumentNames] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const titleRef = useRef<HTMLInputElement>(null);
  const numberofPagesRef = useRef<HTMLInputElement>(null);
  const tagsRef = useRef<HTMLInputElement>(null);
  const levelRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (willFetchDocumentNames && documentNames.titles.length === 0 && documentNames.imageFilenames.length === 0) {
      (async () => {
        let requestForTitles = await fetch(
          `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/fileNames?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
        );

        let titleData = await requestForTitles.json();
        setDocumentNames((prev: DocumentNames) => ({
          ...titleData,
        }));
        setWillSetDocumentNames(false);
      })();
    }
  }, [willFetchDocumentNames, documentNames]);

  useEffect(() => {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      if (numberOfPages !== 0) {
        e.returnValue = 'changes may not be saved';
      }
    };
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = (e: PopStateEvent) => {
      window.history.go(1);
    };
  }, [numberOfPages]);

  const submitFile = async (input: HTMLInputElement) => {
    let returnValue = false;
    if (input) {
      if (input.files === null || input.files.length === 0) {
        console.log('No file submitted');
        returnValue = true;
      } else {
        const FD = new FormData();
        FD.append(
          input.id.includes('cover-image')
            ? storyDoc.title
            : `${storyDoc.title} ${input.id
                .replace('page-', '')
                .replace('-image-webp', '')
                .replace('-image-jpg', '')
                .replace('-image-png', '')}`,
          input.files[0],
        );
        const splitFileName = input.files[0].name.split('.');
        const URL = `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/addStoryDocument/${
          splitFileName[splitFileName.length - 1]
        }?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;

        const request = await fetch(URL, {
          body: FD,
          method: 'POST',
        });

        request.status === 200 ? (returnValue = true) : (returnValue = false);
      }
    } else {
      console.log('Not current');
    }

    return returnValue;
  };

  const submitMongoData = async () => {
    let returnValue = false;
    const cover1 = document.getElementById('cover-image-webp') as HTMLInputElement;
    const cover2 = document.getElementById('cover-image-jpg') as HTMLInputElement;
    const cover3 = document.getElementById('cover-image-png') as HTMLInputElement;
    const words: string[] = Array.from(
      new Set(
        storyDoc.pages
          .map((page: Page) => {
            return page.page_text
              .toLowerCase()
              .replaceAll('.', '')
              .replaceAll(',', '')
              .replaceAll('!', '')
              .replaceAll('?', '')
              .split(' ');
          })
          .flat(),
      ),
    );
    if (cover1 || cover2 || cover3) {
      if (
        (cover1.files === null || cover1.files.length === 0) &&
        (cover2.files === null || cover2.files.length === 0) &&
        (cover3.files === null || cover3.files.length === 0)
      ) {
        console.log('No file submitted');
      } else {
        const splitFileName =
          cover1.files !== null
            ? cover1.files[0].name.split('.')
            : cover2.files !== null
            ? cover2.files[0].name.split('.')
            : cover3.files![0].name.split('.');

        const storyToSubmit = storyDoc;
        storyToSubmit.cover = `${storyDoc.title.split(' ').join('')}.${splitFileName[splitFileName.length - 1]}`;
        storyToSubmit.associated_words = words;
        storyToSubmit.pages = storyToSubmit.pages.map((page) => ({
          ...page,
          page_image: `${storyToSubmit.title.split(' ').join('')}${page.page_number}.${
            splitFileName[splitFileName.length - 1]
          }`,
        }));
        console.log(storyDoc);
        const URL = `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/addSingleStory?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;
        const request = await fetch(URL, {
          method: 'POST',
          body: JSON.stringify({ storyDoc: storyToSubmit }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const response = await request.json();
        console.log(response);

        request.status === 200 ? (returnValue = true) : (returnValue = false);
      }
    } else {
      console.log('Not Current');
    }

    return returnValue;
  };

  return (
    <main className="main-container">
      <h1 className="header-title">{willRenderPreview ? 'Confirm Submission' : 'Story Form'}</h1>

      <form className="storiesUpload">
        <div className="storiesUpload__input-group">
          <label className="storiesUpload__label">Cover (WEBP): </label>
          <input type="file" name="cover" id="cover-image-webp" className="storiesUpload__input" />
          <label className="storiesUpload__label">Cover (JPG): </label>
          <input type="file" name="cover" id="cover-image-jpg" className="storiesUpload__input" />
          <label className="storiesUpload__label">Cover (PNG): </label>
          <input type="file" name="cover" id="cover-image-png" className="storiesUpload__input" />
        </div>
        {Array.from(Array(numberOfPages)).map((element, index) => {
          return (
            <React.Fragment key={index}>
              <div className="storiesUpload__input-group">
                <label>{`Page ${index + 1} image (WEBP)`}</label>
                <input type="file" id={`page-${index + 1}-image-webp`} className="storiesUpload__input" />
                <label>{`Page ${index + 1} image (JPG)`}</label>
                <input type="file" id={`page-${index + 1}-image-jpg`} className="storiesUpload__input" />
                <label>{`Page ${index + 1} image (PNG)`}</label>
                <input type="file" id={`page-${index + 1}-image-png`} className="storiesUpload__input" />
              </div>
            </React.Fragment>
          );
        })}
        {Array.from(Array(numberOfPages)).map((element, index) => {
          return (
            <React.Fragment key={index}>
              <div className="storiesUpload__select-wrapper">
                <label>{`Page ${index + 1} text`}</label>
                <textarea
                  className="storiesUpload__textarea"
                  placeholder={`Page ${
                    index + 1
                  } text. Separate lines with two semicolons (e.g. The dog walked into a bar.;;The dog jumped over the bar.)`}
                  id={`page-${index + 1}-text`}
                  onBlur={() => {
                    const currentText = (document.getElementById(`page-${index + 1}-text`) as HTMLTextAreaElement)
                      .value;
                    let mutablePages = storyDoc.pages;

                    const oldPageIndex = mutablePages.findIndex((page) => page.page_number === index + 1);
                    if (oldPageIndex !== -1) {
                      mutablePages.splice(oldPageIndex, 1);
                      setStoryDoc((prev: StoryType) => ({
                        ...prev,
                        pages: mutablePages,
                      }));
                    }

                    setStoryDoc((prev: StoryType) => ({
                      ...prev,
                      pages: [
                        ...prev.pages,
                        {
                          page_number: index + 1,
                          page_text: currentText.trim(),
                          page_image: `${storyDoc.title.trim().split(' ').join('')}${index + 1}.jpg`,
                          lines: currentText
                            .trim()
                            .replace(/\r/g, '')
                            .split(/\n/)
                            .join(';;')
                            .split(';;')
                            .map((line) => line.trim()),
                        },
                      ],
                    }));
                  }}
                />
              </div>
            </React.Fragment>
          );
        })}

        {willRenderPreview ? (
          <div className="storiesUpload__doc-info-dropdowns">
            <p data-cy="storiesUpload__preview--title">Title: {storyDoc.title}</p>
            <p data-cy="storiesUpload__preview--level">Level: {storyDoc.level}</p>

            <p data-cy="storiesUpload__preview--pages">
              Pages:
              {storyDoc.pages.map((page, index) => {
                return ` Page ${page.page_number}: ${page.page_text}${index < storyDoc.pages.length - 1 ? ',' : ''}`;
              })}
            </p>
            <p data-cy="storiesUpload__preview--tags">
              Tags:
              {storyDoc.tags.map((tag, index) => {
                return ` ${tag}${index < storyDoc.tags.length - 1 ? ',' : ''}`;
              })}
            </p>
            <p data-cy="storiesUpload__preview--tags">
              Words:
              {Array.from(
                new Set(
                  storyDoc.pages
                    .map((page: Page) => {
                      return page.page_text
                        .toLowerCase()
                        .replaceAll('.', '')
                        .replaceAll(',', '')
                        .replaceAll('!', '')
                        .replaceAll('?', '')
                        .split(' ');
                    })
                    .flat(),
                ),
              ).join(', ')}
            </p>
          </div>
        ) : (
          <>
            <div className="storiesUpload__select-wrapper">
              <label>Title</label>
              <input
                data-cy="storiesUpload__input--title"
                ref={titleRef}
                type="text"
                placeholder="Title"
                defaultValue={storyDoc.title !== '' ? storyDoc.title : undefined}
                onBlur={() => {
                  if (titleRef.current !== null) {
                    let documentTitle = `${titleRef.current.value}`;
                    let errors: string[] = [];
                    documentNames.titles.includes(documentTitle) && errors.push('Title');
                    documentNames.imageFilenames.includes(documentTitle.split(' ').join('')) &&
                      errors.push('Image Preview File Name');
                    if (errors.length > 0) {
                      //todo: throw error
                      setError((prev: ErrorProps) => ({
                        ...prev,
                        isActive: true,
                        type: 'error',
                        message: `The provided title will not work because it will create duplicates of the following:${errors.map(
                          (singleError, index) => {
                            return ` ${singleError}`;
                          },
                        )}. Please choose a unique name.`,
                      }));
                    } else if (documentTitle.includes('-' || documentTitle.includes('/'))) {
                      setError((prev: ErrorProps) => ({
                        ...prev,
                        isActive: true,
                        type: 'error',
                        message: `Title is invalid because the use of '-' or '/' is not allowed.`,
                      }));
                    } else {
                      setStoryDoc((prev: StoryType) => ({
                        ...prev,
                        title: documentTitle.trim(),
                      }));
                    }
                  }
                }}
              />
            </div>

            <div className="storiesUpload__select-wrapper">
              <label>Level</label>
              <input
                type="text"
                className="storiesUpload__input--level"
                data-cy="storiesUpload__input--level"
                ref={levelRef}
                placeholder="Single Letter"
                defaultValue={storyDoc.level !== '' ? storyDoc.level : undefined}
                onBlur={() => {
                  if (levelRef.current) {
                    const level = levelRef.current.value.toUpperCase().trim();
                    setStoryDoc((prev: StoryType) => ({
                      ...prev,
                      level,
                    }));
                  }
                }}
              />
            </div>

            <div className="storiesUpload__select-wrapper">
              <label>Pages</label>
              <input
                type="number"
                className="storiesUpload__input--pages"
                data-cy="storiesUpload__input--pages"
                ref={numberofPagesRef}
                placeholder="Number of Pages"
                defaultValue={storyDoc.pages.length !== 0 ? storyDoc.pages.length : undefined}
                onChange={() => {
                  if (numberofPagesRef.current && !isNaN(Number(numberofPagesRef.current.value))) {
                    setNumberOfPages(Number(numberofPagesRef.current.value));
                  } else {
                    setNumberOfPages(0);
                  }
                }}
              />
            </div>

            <div className="storiesUpload__tags-wrapper">
              <label>Tags</label>
              <div>
                {storyDoc.tags.map((tag, index) => {
                  return (
                    <div>
                      <span key={index}>{tag}</span>{' '}
                      <span
                        onClick={() => {
                          let filteredCategories = storyDoc.tags.filter((string) => {
                            return string !== tag;
                          });

                          setStoryDoc((prev: StoryType) => ({
                            ...prev,
                            tags: filteredCategories,
                          }));
                        }}
                      >
                        X
                      </span>
                    </div>
                  );
                })}
              </div>
              <div>
                <input
                  ref={tagsRef}
                  type="text"
                  placeholder="Tag"
                  data-cy="storiesUpload__input--tags"
                  className="storiesUpload__tags-input"
                />
                <button
                  data-cy="storiesUpload__submit--tags"
                  onClick={(e) => {
                    e.preventDefault();
                    if (tagsRef.current && tagsRef.current.value !== '') {
                      const newTag = tagsRef.current.value;
                      setStoryDoc((prev: StoryType) => ({
                        ...prev,
                        tags: [...prev.tags, newTag],
                      }));
                      tagsRef.current!.value = '';
                    }
                  }}
                >
                  Add Tag
                </button>
              </div>
            </div>
          </>
        )}

        <div>
          {error.isActive && error.message.includes('The following fields require a value but have not been set') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}

          {error.isActive &&
            error.message.includes(
              'The provided title will not work because it will create duplicates of the following',
            ) && (
              <ErrorHandler
                type={error.type}
                error={error.message}
                handler={() => {
                  setError({
                    isActive: false,
                    message: '',
                    type: 'error',
                    redirect: false,
                    icon: '',
                  });
                }}
              />
            )}

          {error.isActive && error.message.includes('Successfully posted') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}

          {error.isActive && error.message.includes('There was an issue with the following submissions') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}

          {error.isActive && error.message.includes('Title is invalid because ') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}
        </div>

        <div className="storiesUpload__btn-wrapper">
          {willRenderPreview ? (
            <>
              <button
                data-cy="storiesUpload__button--backToForm"
                onClick={(e) => {
                  e.preventDefault();
                  setWillRenderPreview(false);
                }}
              >
                Back to Form
              </button>
              <button
                className="storiesUpload__form-button"
                data-cy="storiesUpload__button--submitHandler"
                disabled={disableSubmit}
                onClick={async (e) => {
                  e.preventDefault();
                  setDisableSubmit(true);

                  const IMAGE1_SUBMISSION = submitFile(document.getElementById('cover-image-webp') as HTMLInputElement);
                  const IMAGE2_SUBMISSION = submitFile(document.getElementById('cover-image-jpg') as HTMLInputElement);
                  const IMAGE3_SUBMISSION = submitFile(document.getElementById('cover-image-png') as HTMLInputElement);
                  const MONGO_SUBMISSION = submitMongoData();
                  const postRequests = [
                    await IMAGE1_SUBMISSION,
                    await IMAGE2_SUBMISSION,
                    await IMAGE3_SUBMISSION,
                    await MONGO_SUBMISSION,
                  ];
                  for (let i = 1; i <= numberOfPages; i++) {
                    await submitFile(document.getElementById(`page-${i}-image-webp`) as HTMLInputElement);
                    await submitFile(document.getElementById(`page-${i}-image-jpg`) as HTMLInputElement);
                    await submitFile(document.getElementById(`page-${i}-image-png`) as HTMLInputElement);
                  }
                  const hadRequestFail = postRequests.some((requestValue) => {
                    return requestValue === false;
                  });

                  if (hadRequestFail) {
                    //post error message
                    let message = `There was an issue with the following submissions:${postRequests.map(
                      (value, index) => {
                        if (value === false) {
                          return index === 0
                            ? ' Cover'
                            : // ? ' PDF Document' + (postRequests[1] === false || postRequests[2] === false ? ',' : '')
                            index === 1
                            ? // ? ' Image Preview' + (postRequests[2] === false ? ',' : '')
                              ' Mongo Data'
                            : ' Page Images';
                        } else {
                          return '';
                        }
                      },
                    )}.`;

                    setError({
                      isActive: true,
                      message: message,
                      type: 'error',
                      redirect: false,
                      icon: '',
                    });

                    //email Najib with doc names and mongo Data
                    SendEmail(
                      `${message}. Document Title: ${storyDoc.title}`,
                      'najib@nofusstutors.com',
                      'Error Posting Story',
                    );
                  } else {
                    setError({
                      isActive: true,
                      message: `Successfully posted ${storyDoc.title} to AWS and Mongo`,
                      type: 'success',
                      redirect: false,
                      icon: '',
                    });
                    //Refresh Form:
                    setStoryDoc({
                      title: '',
                      level: '',
                      cover: '',
                      pages: [],
                      tags: [],
                      associated_words: [],
                    });
                    setNumberOfPages(0);
                  }

                  //Enable submit button
                  setDisableSubmit(false);
                }}
              >
                Submit
              </button>
            </>
          ) : (
            <>
              <button
                className="storiesUpload__form-button"
                data-cy="storiesUpload__button--confirmDetails"
                onClick={(e) => {
                  e.preventDefault();
                  const hasEmptyField = Object.keys(storyDoc).some((key) => {
                    return storyDoc[key as keyof StoryType] === '' && key !== 'cover';
                  });

                  if (error.isActive) {
                    alert('Form Cannot be submitted with errors present');
                  } else if (hasEmptyField) {
                    //Error Handler
                    let errors: string[] = [];
                    storyDoc.title.trim() === '' ? errors.push('Title') : (errors = errors);
                    storyDoc.pages.length === 0 ? errors.push('Pages') : (errors = errors);
                    storyDoc.tags.length === 0 ? errors.push('Tags') : (errors = errors);
                    setError((prev: ErrorProps) => ({
                      ...prev,
                      isActive: true,
                      type: 'error',
                      message: `The following fields require a value but have not been set:${errors.map(
                        (singleError, index) => {
                          return ` ${singleError}`;
                        },
                      )}.`,
                    }));
                  } else {
                    setWillRenderPreview(true);
                  }
                }}
              >
                Submit
              </button>
              <button
                className="storiesUpload__form-button"
                data-cy="storiesUpload__button--resetForm"
                style={{ background: 'red' }}
                onClick={(e) => {
                  e.preventDefault();
                  setStoryDoc({
                    title: '',
                    level: '',
                    cover: '',
                    pages: [],
                    tags: [],
                    associated_words: [],
                  });

                  titleRef.current!.value = '';
                  numberofPagesRef.current!.value = '';
                  tagsRef.current!.value = '';
                }}
              >
                Refresh Form
              </button>
            </>
          )}
        </div>
      </form>
      {/* <button
        onClick={async () => {
          const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/convert`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
          });
          const response = await request.json();
          if (request.status === 200) {
            setError({ type: 'success', isActive: true, message: response.message });
          } else {
            setError({ type: 'error', isActive: true, message: response.message });
          }
        }}
      >
        Convert All Story Images in Database to webp format (not AWS)
      </button>
      <button
        onClick={async () => {
          const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/wipeStoriesRead`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
          });
          const response = await request.json();
          if (request.status === 200) {
            if (request.status === 200) {
              setError({ type: 'success', isActive: true, message: response.message });
            } else {
              setError({ type: 'error', isActive: true, message: response.message });
            }
          }
        }}
      >
        Wipe Stories Read
      </button>
      <button
        onClick={async () => {
          const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/wipeRecentlyLearned`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
          });
          const response = await request.json();
          if (request.status === 200) {
            if (request.status === 200) {
              setError({ type: 'success', isActive: true, message: response.message });
            } else {
              setError({ type: 'error', isActive: true, message: response.message });
            }
          }
        }}
      >
        Wipe Recently Learned
      </button>
      <button
        onClick={async () => {
          const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/wipeReadingLevels`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
          });
          const response = await request.json();
          if (request.status === 200) {
            if (request.status === 200) {
              setError({ type: 'success', isActive: true, message: response.message });
            } else {
              setError({ type: 'error', isActive: true, message: response.message });
            }
          }
        }}
      >
        Wipe Reading Levels
      </button>
      <button
        onClick={async () => {
          const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/wipeFreemiumTallies`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
          });
          const response = await request.json();
          if (request.status === 200) {
            if (request.status === 200) {
              setError({ type: 'success', isActive: true, message: response.message });
            } else {
              setError({ type: 'error', isActive: true, message: response.message });
            }
          }
        }}
      >
        Wipe Freemium Tallies
      </button> */}
    </main>
  );
  // }
};

export default StoryUpload;
