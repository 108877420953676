import { states } from '../Models';
import { GetBaseURL } from '../Utils';
import io from 'socket.io-client';

const socket = io(`${process.env.REACT_APP_BASE_URL}`);

export const SendSMS = async (
  phoneNumber: string,
  outboundMessage: string,
  stateProvince: string,
  selectedTwilioNumber?: string,
) => {
  //! made update in Dropdown to set the select value, don't need this manual conversion anymore
  // convert state/province code into shortform
  // let stateProvinceCode = states.find((obj: { label: string; value: string }) => {
  //   return obj.label === stateProvince;
  // });

  // // if cannot find state, use Ontario value
  let stateForSMS = stateProvince ? stateProvince : 'ON';

  const POST_REQUEST = {
    phoneNumber,
    outboundMessage,
    stateForSMS,
    selectedTwilioNumber,
  };

  try {
    let request = await fetch(`${GetBaseURL()}/api/sms/send`, {
      method: 'POST',
      body: JSON.stringify(POST_REQUEST),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let response = request.status;

    if (response === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
