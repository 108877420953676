import React, { useState, useEffect } from 'react';
import { ClientType } from '../../Models';
import { printInitials } from '../../Utils';

interface ClientProps {
  client: ClientType;
}

//Feeling like I'll want to pass down a function to do the isStudent/setIsStudent logic from the leadform. It must be notified of these changes.
export const ClientInMatch: React.FC<ClientProps> = ({ client }) => {
  return (
    <section id="CLIENT_DETAILS" className="form-padding-sides rounded-gray match-client-margin">
      <div className="match-client">
        <div className="sms-selected">
          <div className="tutor-heading-container">
            <div className="avatar client-avatar">{printInitials(client.full_name)}</div>
            <div className="match-client-header">
              <h4 className="l-header-font nowrap" data-cy="match_client--name">
                {client.full_name}
              </h4>
              <a href={`tel: +1${client.phone_number}`} className="none phone-link pointer half-label-margin-top">
                {client.phone_number}
              </a>
            </div>
          </div>
        </div>
        <div className="match-client-header-right">
          <div>{client.major_intersection}</div>
          <div>{client.email}</div>
        </div>
      </div>
    </section>
  );
};

export default ClientInMatch;
