import React, { useState, useEffect } from 'react';
import { GetBaseURL } from '../../Utils';
import { Admin } from '../../Models';

export interface UserContextProps {
  userData?: any;
  setUserData: (userData: any) => void;
}

const UserContext = React.createContext({} as UserContextProps);

const UserContextProvider = (props: any) => {
  const [userData, setUserData] = useState<any>({
    currentUser: {
      email: '',
      full_name: '',
      home_region: '',
      role: '',
      security_grous: [],
    },
    admins: [],
    adminsWithAllData: [],
  });
  const [willSetUserData, setWillSetUserData] = useState(true);

  const findAdmins = async () => {
    let request = await fetch(`${GetBaseURL()}/api/administrators?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`);
    let data = await request.json();
    setUserData((prev: any) => ({
      ...prev,
      admins: data.map((admin: Admin) => {
        return { label: admin.full_name, value: admin.email };
      }),
      adminsWithAllData: data,
    }));
  };

  useEffect(() => {
    if (userData.admins.length === 0 && willSetUserData) {
      findAdmins();
      setWillSetUserData(false);
    }
  }, []);

  const providerValue: UserContextProps = {
    userData,
    setUserData,
  };

  return <UserContext.Provider value={providerValue}>{props.children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
