import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { plainToClass } from 'class-transformer';
import { LeadType, ClientType, StudentType, Subject, studentGrades } from '../Models';
import { GetBaseURL } from '../Utils';

import AdminNav from '../Components/AdminNav';
import LeadsForm from '../Components/LeadsForm/LeadsForm';

//Context
import { LeadContractContextProvider } from '../Components/Context/LeadContractContext';
import { useLeadData } from '../Components/Hooks/Lead/useLeadData';
import { useClientData } from '../Components/Hooks/Lead/useClientData';
import { useStudentData } from '../Components/Hooks/Lead/useStudentData';
import ErrorHandler from '../Components/Partials/ErrorHandler';

const SingleLead: React.FC<RouteComponentProps> = (RouteComponentProps) => {
  const [willRedirect, setWillRedirect] = useState(false); //State that determines redirects to leads Page
  const [hasLead, setHasLead] = useState(false); //Essentially loading state
  const [isNewLead, setIsNewLead] = useState(false); //State to pass to the LeadsForm to determine newLead or not
  const [saveError, setSaveError] = useState(false); //Error handling for when there are no fields active
  const { lead, createNewLead, createLeadFromData, updateLeadID, save, update } = useLeadData(); //Context for leads
  const { updateClientID } = useClientData();
  const { handleIDUpdate } = useStudentData();

  //Fetching the LeadID from URL. Important for fetching lead based on ID
  const returnLeadID = () => {
    let location: string = RouteComponentProps.location.pathname;
    return location.split('/')[location.split('/').length - 1];
  };

  //Have an effect that will fetch a lead and set the context to have value.
  //Once the lead has been set, we will setHasLead(true) so that we render the LeadsForm
  useEffect(() => {
    if (returnLeadID() === 'new') {
      //This conditional is for CreateNewLead instances, where the dynamic URL is 'new' instead of an ID
      createNewLead();
      setIsNewLead(true);
      setHasLead(true);
    } else {
      (async () => {
        const requestLead = await fetch(`${GetBaseURL()}/api/leads/${returnLeadID()}`);
        const response: LeadType = await requestLead.json();
        if (response.students === undefined || response.client === undefined) {
          //When deletes happen this function runs and students + clients are undefined -- we get errors.
          //This will redirect them to leadsPage
          setWillRedirect(true);
        } else {
          // const CLIENT = plainToClass(Client, response.client);
          let allStudents: StudentType[] = [];
          response.students.forEach((student) => {
            // let studentSubjects = student.subjects.map((subject) => {
            //   return plainToClass(Subject, subject);
            // });
            // let newStudent = new StudentType(
            //   student.student_name,
            //   student.student_phone,
            //   student.student_email,
            //   student.student_grade,
            //   studentSubjects,
            //   student.tutorcruncher_id,
            // );
            let newStudent: StudentType = {
              student_name: student.student_name,
              student_phone: student.student_phone,
              student_email: student.student_email,
              grade: student.grade,
              subjects: student.subjects,
              skill_level_and_goals: student.skill_level_and_goals,
              first_appointment: student.first_appointment,
              tutorcruncher_id: student.tutorcruncher_id,
            };
            allStudents.push(newStudent);
          });

          //Sometimes contracts are null, in that case we're initializing it as an empty array
          response.contracts === null ? (response.contracts = []) : (response.contracts = response.contracts);
          // response.contracts.forEach((contract) => {
          //   // let subjectsAsClasses = contract.subjects.map((jsonSubject) => {
          //   //   return plainToClass(Subject, jsonSubject);
          //   // });
          //   // contract.subjects = subjectsAsClasses;
          // });
          let convertedLead: LeadType = {
            status: response.status,
            column: response.column,
            lead_source: response.lead_source,
            client: response.client,
            students: allStudents,
            notes: response.notes,
            labels: response.labels,
            contracts: response.contracts || [],
            timestamp: response.timestamp,
            lead_form_type: response.lead_form_type,
            title: response.title ? response.title : '',
            _id: response._id?.toString(),
            number_of_subjects: response.number_of_subjects,
          };
          //!Here we want to set lead (context) with a populated lead
          createLeadFromData(convertedLead);
          setHasLead(true);
        }
      })();
    }
  }, []);

  if (willRedirect) {
    //Returning to leads Page
    return <Redirect to="/admin/leads" />;
  } else if (!hasLead) {
    //For the split second we're waiting on the leads to load
    return <div>Loading...</div>;
  } else if (lead) {
    return (
      <>
        <AdminNav />
        <main
          className="modal-background"
          data-cy="modal-background"
          onClick={async () => {
            //if client has a tutorcruncher ID, update lead, if not then save as a new lead. check in place to ensure at least name and email are entered in and no blank leads are saved
            if (lead!.client.full_name !== '' && lead!.client.email !== '') {
              //if/else block that captures errors and client_ids on save methods for the lead
              if (lead._id) {
                let updateLeadInMongo = await update(lead!);
                if (updateLeadInMongo.successfulSave === true) {
                  updateClientID(updateLeadInMongo.id!);
                } else {
                  setSaveError(true);
                  return null;
                }
              } else {
                save(lead!).then((res) => {
                  if (res !== 'Failed to save') {
                    updateLeadID(res);
                    // setWillRedirect(true);
                  } else {
                    //!This would remove disabled attribute. Do we want to implement a similar feature here?
                    // saveButtonRef.current!.removeAttribute('disabled');
                    setSaveError(true);
                  }
                });
              }
              setWillRedirect(true);
            } else {
              setSaveError(true);
            }
          }}
        >
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="card shadow form-radius default-margin-top-bottom modal-child"
          >
            {saveError ? (
              <ErrorHandler
                type="warning"
                handler={() => {
                  setWillRedirect(true);
                  setSaveError(false);
                }}
                message="Enter at least a name and email address to save, or click here to return to Leads page."
              />
            ) : null}
            <LeadContractContextProvider>
              <LeadsForm lead={lead} newLead={isNewLead} title={lead.client.full_name} />
            </LeadContractContextProvider>
          </div>
        </main>
      </>
    );
  } else {
    return <h3>Loading...</h3>;
  }
};

export default SingleLead;
