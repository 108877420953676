import React, { useState, useRef } from 'react';
import ErrorHandler from '../Partials/ErrorHandler';

type SelectResourceToUpdateProps = {
  SelectURL: (newURL?: string) => void;
};

const SetURLToUpdate: React.FC<SelectResourceToUpdateProps> = ({ SelectURL }) => {
  const [hasErrorWithURL, setHasErrorWithURL] = useState(false);
  const URLInputRef = useRef<HTMLInputElement>(null);

  return (
    <section>
      <div>
        <label>Resource to Fetch: </label>
        <input ref={URLInputRef} type="text" placeholder="URL to Update..." />
      </div>

      {hasErrorWithURL && (
        <ErrorHandler
          type="error"
          message="The URL entered is not valid. The URL must begin with '/'. We append this url to the end of 'https://nofusstutors.com'"
          handler={() => {
            setHasErrorWithURL(false);
          }}
        />
      )}

      <button
        onClick={() => {
          if (URLInputRef.current && URLInputRef.current.value !== '') {
            let inputText = URLInputRef.current.value;
            if (inputText.charAt(0) === '/') {
              SelectURL(inputText);
              URLInputRef.current.value = '';
            } else {
              setHasErrorWithURL(true);
            }
          } else {
            setHasErrorWithURL(true);
          }
        }}
      >
        Enter URL
      </button>
    </section>
  );
};

export default SetURLToUpdate;
