import React, { useState, useEffect } from 'react';
import { NFTTutor } from '../../Models';
import { UrgentHire } from './UrgentHire';
import SearchSingleTutorInput from './SearchSingleTutorInput';
import { useLeadContracts } from '../Hooks/Lead/useLeadContracts';
import { useLeadData } from '../Hooks/Lead/useLeadData';
import SearchBySubject from './TutorSearch/SearchBySubject';

type FindTutorsProps = {
  defaultSMS: () => string;
  indexOfContract: number;
};

const FindTutors: React.FC<FindTutorsProps> = ({ defaultSMS, indexOfContract }) => {
  const [reRender, setReRender] = useState(false);
  const [subjectsArePresent, setSubjectsArePresent] = useState(false);

  //Contract Hooks
  const { contracts, setTutorsBySubject } = useLeadContracts();
  const { lead } = useLeadData();

  useEffect(() => {
    if (contracts[indexOfContract].subjects.length > 0 && subjectsArePresent === false) {
      setSubjectsArePresent(true);
    }
  }, [contracts[indexOfContract]]);

  const AddSingleTutor = (tutor: NFTTutor) => {
    if (!contracts[indexOfContract].tutors_by_subject.includes(tutor))
      setTutorsBySubject([tutor, ...contracts[indexOfContract].tutors_by_subject!], indexOfContract);
    setReRender(!reRender);
  };

  return (
    <section>
      <div className="heading">
        <h3>Tutors Available</h3>
      </div>

      <div className="tutor-in-lead-border">
        <UrgentHire lead={lead!} />
        <div className="single-tutor-add-container">
          <SearchSingleTutorInput addTutor={AddSingleTutor} country={lead!.client.country} />
        </div>
        {subjectsArePresent ? <SearchBySubject indexOfContract={indexOfContract} defaultSMS={defaultSMS} /> : null}
      </div>
    </section>
  );
};

export default FindTutors;
