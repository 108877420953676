import React, { useState, useEffect } from 'react';
import RenderTutorInLead from '../RenderTutorInLead';
import ErrorHandler from '../../Partials/ErrorHandler';
import { useLeadContracts } from '../../Hooks/Lead/useLeadContracts';
import { useLeadData } from '../../Hooks/Lead/useLeadData';
import { useSubjectData } from '../../Hooks/Lead/useSubjectData';
import { NFTTutor } from '../../../Models';
import { GetBaseURL } from '../../../Utils';

type SubjectSearchProps = {
  indexOfContract: number;
  defaultSMS: () => string;
};

const SearchBySubject: React.FC<SubjectSearchProps> = ({ indexOfContract, defaultSMS }) => {
  //State
  const [subjectServerError, setSubjectServerError] = useState(false); //!In Subject Component
  const [subjectUnknownError, setSubjectUnknownError] = useState(false); //!In Subject Component
  const [subjectErrorMessage, setSubjectErrorMessage] = useState(''); //! In Location Component
  const [subjectTutors, setSubjectTutors] = useState<NFTTutor[]>([]);

  //Hooks/Context
  const { contracts, setTutorsBySubject } = useLeadContracts();
  const { lead } = useLeadData();
  const { returnSubjectGradeAndPrice } = useSubjectData();

  // need this useEffect to re-render page when manually adding a tutor outside component
  useEffect(() => {
    if (contracts[indexOfContract].tutors_by_subject !== []) {
      setSubjectTutors(contracts[indexOfContract].tutors_by_subject);
    }
  }, [contracts[indexOfContract].tutors_by_subject]);

  //Search For Tutors
  useEffect(() => {
    if (contracts[indexOfContract].tutors_by_subject.length === 0) {
      findTutorsBySubjects();
    }
  }, []);

  //Update SMS after tutorsBySubject updates
  useEffect(() => {
    const testSMS = defaultSMS();

    if (contracts[indexOfContract].tutors_by_subject.length > 0) {
      let newTutors = contracts[indexOfContract].tutors_by_subject;
      newTutors.forEach((tutor) => {
        if (tutor !== null) {
          tutor.defaultSMS = testSMS;
        }
      });
      setTutorsBySubject(newTutors, indexOfContract);
    }
  }, [contracts[indexOfContract].tutors_by_subject]);

  const findTutorsBySubjects = async (addMore?: boolean) => {
    let subjects = contracts[indexOfContract].subjects.map((subject) => {
      // return subject.returnSubjectGradeAndPrice();
      return returnSubjectGradeAndPrice(subject);
    });

    let requestBody: { subjects: string[]; currentTutors?: NFTTutor[] } = {
      subjects: subjects,
    };

    if (addMore) {
      requestBody.currentTutors = contracts[indexOfContract].tutors_by_subject;
    }

    let fetchRequest = await fetch(`${GetBaseURL()}/api/tutors/findTutorsBySubject/${lead!.client.country}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (fetchRequest.status === 200) {
      let resTutors = await fetchRequest.json();
      if (addMore) {
        setTutorsBySubject([...contracts[indexOfContract].tutors_by_subject, ...resTutors], indexOfContract);
      } else {
        setTutorsBySubject(resTutors, indexOfContract);
      }
    } else if (fetchRequest.status === 400) {
      let response = await fetchRequest.json();
      setSubjectServerError(true);
      setSubjectErrorMessage(response.message! as string);
    } else {
      setSubjectUnknownError(true);
    }
  };

  const SUBJECT_TUTORS = contracts[indexOfContract].tutors_by_subject.map((tutor) => {
    if (tutor !== null) {
      return (
        <div key={tutor.tutor_id}>
          <RenderTutorInLead tutorOffered={tutor} indexOfContract={indexOfContract} />
        </div>
      );
    } else return null;
  });

  return (
    <>
      {subjectServerError ? (
        <ErrorHandler
          handler={() => {
            setSubjectServerError(false);
          }}
          type="error"
          error={subjectErrorMessage}
        />
      ) : null}
      {subjectUnknownError ? (
        <ErrorHandler
          handler={() => {
            setSubjectUnknownError(false);
          }}
          type="error"
          error="Something went wrong, please try again"
        />
      ) : null}
      <div className="search-tutor-button">
        <button
          // className="align-right"
          onClick={() => {
            findTutorsBySubjects(true);
          }}
        >
          + Add Tutors
        </button>
      </div>
      <div className="default-margin-top scroll tutor-results">{SUBJECT_TUTORS}</div>
    </>
  );
};

export default SearchBySubject;
