import { useContext } from 'react';
import { UserContext } from '../Context/UserContext';
import io from 'socket.io-client';
import { GetPushState, encrypt } from '../../Utils';
import { Admin } from '../../Models';

export const socket = io(`${process.env.REACT_APP_BASE_URL}`);

export const useUserData = () => {
  const { userData, setUserData } = useContext(UserContext);

  const FindSingleAdminByEmail = (email: string) => {
    let foundAdmin = userData.adminsWithAllData.find((singleAdmin: any) => {
      return singleAdmin.email === email;
    });
    return foundAdmin;
  };

  const setUser = (user: Admin) => {
    setUserData((prev: any) => ({
      ...prev,
      currentUser: {
        email: user.email,
        full_name: user.full_name,
        home_region: user.home_region,
        role: user.role,
        security_groups: user.security_groups,
      },
    }));
  };

  const setUserAfterLogin = (user: Admin) => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    document.cookie = `email=${encrypt(user.email)}; expires=${date}; path="/"`;
    document.cookie = `full_name=${encrypt(user.full_name)}; expires=${date}; path="/"`;
    document.cookie = `home_region=${encrypt(user.home_region)}; expires=${date}; path="/"`;
    document.cookie = `role=${encrypt(user.role)}; expires=${date}; path="/"`;
    window.history.pushState({ page_id: 1 }, '', GetPushState(true)); //!Pushstate isn't super advanced, but utlity function returns values depending on env...
    window.location.reload();
  };

  //Remove cookies twice and have awkward settimeout because the removecookie sometimes fails the first time -- and takes time
  const removeUserAfterLogout = () => {
    const date = new Date();
    date.setDate(date.getDate() - 3);

    document.cookie = `email=; expires=${date}; path="/"`;
    document.cookie = `full_name=; expires=${date}; path="/"`;
    document.cookie = `home_region=; expires=${date}; path="/"`;
    document.cookie = `role=; expires=${date}; path="/"`;

    setUserData((prev: any) => ({
      ...prev,
      currentUser: {
        email: '',
        full_name: '',
        home_region: '',
        role: '',
        security_groups: '',
      },
    }));
    setTimeout(() => {
      window.history.pushState({ page_id: 1 }, '', GetPushState(false)); //!Pushstate isn't super advanced, but utlity function returns values depending on env...
      window.location.reload();
    }, 500);
  };

  return { userData, setUser, FindSingleAdminByEmail, setUserAfterLogin, removeUserAfterLogout };
};
