import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { WorksheetsTextSegment, WorksheetTextBlockList } from '../../Models';

type WorksheetTextListProps = {
  segment: WorksheetsTextSegment;
};

const WorksheetTextList: React.FC<WorksheetTextListProps> = ({ segment }) => {
  return (
    <div>
      {segment.type === 'list' &&
        Array.isArray(segment.content) &&
        segment.content.map((singleListItem) => {
          return <p key={uuidv4()}> - {singleListItem}</p>;
        })}
    </div>
  );
};

export default WorksheetTextList;
