import React, { useState, useEffect, useRef } from 'react';
import { StudentType, studentGrades, LeadType } from '../../Models';
import { NormalizePhone } from '../../Utils';
import { RenderSubjects } from './RenderSubjects';
import Dropdown from '../Partials/Dropdown';
import ErrorHandler from '../Partials/ErrorHandler';

import { useLeadData } from '../Hooks/Lead/useLeadData';
import { useClientData } from '../Hooks/Lead/useClientData';
import { useStudentData } from '../Hooks/Lead/useStudentData';
import { useDisplayData } from '../Hooks/useDisplayData';
import { useLeadContracts } from '../Hooks/Lead/useLeadContracts';

type RenderStudentsProp = {
  student: StudentType;
  lead: LeadType;
  UpdateNumberOfSubjects: () => void;
  UpdateStudents: () => void;
  id?: string;
};

const RenderStudentInLead: React.FC<RenderStudentsProp> = ({ student, UpdateNumberOfSubjects, UpdateStudents }) => {
  const { lead } = useLeadData();
  const { handleStudentInputChange, handleStudentSelect, removeStudent, instantiateSubject } = useStudentData();
  const { resetClientIsStudent } = useClientData();
  const { removeStudentFromContracts } = useLeadContracts();

  const [renderNameChange, setRenderNameChange] = useState(false);
  const [renderPhoneChange, setRenderPhoneChange] = useState(false);
  const [renderEmailChange, setRenderEmailChange] = useState(false);
  const [renderGradeChange, setRenderGradeChange] = useState(false);

  const [numberofSubjects, setNumberOfSubjects] = useState(student.subjects.length);

  const nameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const gradeRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (renderNameChange && nameRef.current) {
      nameRef.current.focus();
    }

    if (renderPhoneChange && phoneRef.current) {
      phoneRef.current.focus();
    }

    if (renderEmailChange && emailRef.current) {
      emailRef.current.focus();
    }

    if (renderGradeChange && gradeRef.current) {
      gradeRef.current.focus();
    }
  }, [renderNameChange, renderPhoneChange, renderEmailChange, renderGradeChange]);

  const SetSubjectLength = (value: number) => {
    setNumberOfSubjects(value);
  };

  useEffect(() => {
    UpdateNumberOfSubjects();
  }, [numberofSubjects, UpdateNumberOfSubjects]);

  return (
    <div className="student-container">
      <div className="heading heading-margin">
        <div className="left-heading">
          <h3>Student {lead!.students.indexOf(student) + 1}</h3>
        </div>
        <span
          className={'delete'}
          onClick={() => {
            removeStudent(student);
            removeStudentFromContracts(student);
            UpdateStudents();
          }}
          data-cy="student_delete"
        >
          Remove Student
        </span>
      </div>
      <section className="row student-input">
        <div>
          <label>Student Name (Required)</label>
          {/* {renderNameChange ? ( */}
          <input
            ref={nameRef}
            name="student_name"
            type="text"
            defaultValue={student.student_name}
            onBlur={(e) => {
              handleStudentInputChange(e, student);
              setRenderNameChange(false);
            }}
          />
          {/* ) : (
            <p
              onClick={() => {
                setRenderNameChange(true);
              }}
            >
              {student.student_name}
            </p>
          )} */}
        </div>
        <div>
          <label>Student Phone</label>
          {/* {renderPhoneChange ? ( */}
          <input
            ref={phoneRef}
            name="student_phone"
            type="text"
            defaultValue={student.student_phone}
            onBlur={(e) => {
              NormalizePhone(e);
              handleStudentInputChange(e, student);
              setRenderPhoneChange(false);
            }}
          />
          {/* ) : (
            <p
              onClick={() => {
                setRenderPhoneChange(true);
              }}
            >
              {student.student_phone}
            </p>
          )} */}
        </div>
      </section>
      <section className="row student-input">
        <div>
          <label>Student Email</label>
          {/* {renderEmailChange ? ( */}
          <input
            ref={emailRef}
            name="student_email"
            type="text"
            defaultValue={student.student_email}
            onBlur={(e) => {
              handleStudentInputChange(e, student);
              setRenderEmailChange(false);
            }}
          />
          {/* ) : (
            <p
              onClick={() => {
                setRenderEmailChange(true);
              }}
            >
              {student.student_email}
            </p>
          )} */}
        </div>
        <div>
          <label>Student Grade</label>
          {/* {renderGradeChange ? ( */}
          <Dropdown
            name="grade"
            items={studentGrades}
            value={student.grade}
            onChange={(e) => handleStudentSelect(e, student)}
            // onBlur={() => {
            //   setRenderGradeChange(false);
            // }}
            refer={gradeRef}
          />
          {/* // ) : (
          //   <p
          //     onClick={() => {
          //       setRenderGradeChange(true);
          //     }}
          //   >
          //     {student.grade}
          //   </p>
          // )} */}
        </div>
      </section>
      <section className="subjects-container">
        {student.subjects.map((subject, index) => {
          return (
            <React.Fragment key={index}>
              <RenderSubjects subject={subject} student={student} SetSubjectLength={SetSubjectLength} />
            </React.Fragment>
          );
        })}
        <section className="add-button-container">
          <button
            className="add-button"
            onClick={() => {
              instantiateSubject(student);
              setNumberOfSubjects(student.subjects.length);
            }}
          >
            {student.subjects.length < 1 ? '+ Add Subject' : '+ Add Another Subject'}
          </button>
        </section>
      </section>
    </div>
  );
};

export default RenderStudentInLead;
