import React, { useState, useRef, useEffect, RefObject } from 'react';
import DropDown from '../Components/Partials/Dropdown';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { studentGrades, ErrorProps } from '../Models';
import { GetBaseURL, SendEmail } from '../Utils';

type Question = {
  question: string;
  answer1: {
    answer: string;
    correct: boolean;
  };
  answer2: {
    answer: string;
    correct: boolean;
  };
  answer3: {
    answer: string;
    correct: boolean;
  };
  question_type: string;
  assessment_type: string;
};

type StoryType = {
  title: string;
  questions: Question[];
};

const QuestionsUpload: React.FC = () => {
  const [storyDoc, setStoryDoc] = useState<StoryType>({
    title: '',
    questions: [
      {
        question: '',
        answer1: { answer: '', correct: false },
        answer2: { answer: '', correct: false },
        answer3: { answer: '', correct: false },
        question_type: 'MC',
        assessment_type: 'Comprehension',
      },
      {
        question: '',
        answer1: { answer: '', correct: false },
        answer2: { answer: '', correct: false },
        answer3: { answer: '', correct: false },
        question_type: 'MC',
        assessment_type: 'Comprehension',
      },
      {
        question: '',
        answer1: { answer: '', correct: false },
        answer2: { answer: '', correct: false },
        answer3: { answer: '', correct: false },
        question_type: 'MC',
        assessment_type: 'Comprehension',
      },
    ],
  });
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });
  const [renderPreview, setRenderPreview] = useState(false);

  const questionsRef = useRef<HTMLInputElement>(null);

  const submitFile = async (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      if (ref.current.files === null || ref.current.files.length === 0) {
        console.log('No file submitted');
      } else {
        const FD = new FormData();
        FD.append('Questions Sheet', ref.current.files[0]);
        const URL = `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/parseAndPostQuestions/${process.env.NODE_ENV}?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;

        const request = await fetch(URL, {
          body: FD,
          method: 'POST',
        });

        const response = await request.json();

        request.status === 200
          ? setError({ isActive: true, type: 'success', message: response.message })
          : setError({ isActive: true, type: 'error', message: response.message });
      }
    } else {
      setError({ isActive: true, type: 'error', message: 'Could not locate the file reference on the page.' });
    }
  };

  const handleSubmit = async () => {
    const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/questions`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ storyDoc }),
    });
    const response = await request.json();
    if (request.status === 200) {
      setError({ type: 'success', isActive: true, message: response.message });
    } else {
      setError({ type: 'error', isActive: true, message: response.message });
    }
  };

  return (
    <main className="main-container">
      <h1 className="header-title">{renderPreview ? 'Confirm Submission' : 'Questions Form'}</h1>
      <div className="resourceUpload__input-group">
        <label className="resourceUpload__label">Upload Questions CSV:</label>
        <input ref={questionsRef} type="file" name="words" id="wordsFileToUpload" className="resourceUpload__input" />
      </div>
      <button className="resourceUpload__form-button" onClick={() => submitFile(questionsRef)}>
        Submit CSV
      </button>
      <form className="storiesUpload">
        {renderPreview ? (
          <div className="storiesUpload__doc-info-dropdowns">
            <p data-cy="storiesUpload__preview--title">Title: {storyDoc.title}</p>
            {[1, 2, 3].map((qNumber) => (
              <>
                <p>
                  Question {qNumber}: {storyDoc.questions[qNumber - 1].question}
                </p>
                {[1, 2, 3].map((aNumber) => (
                  <>
                    <p>
                      Q {qNumber} Answer {aNumber}:{' '}
                      {storyDoc.questions[qNumber - 1][`answer${aNumber}` as 'answer1' | 'answer2' | 'answer3']}
                    </p>
                    {storyDoc.questions[qNumber - 1][`answer${aNumber}` as 'answer1' | 'answer2' | 'answer3']
                      .correct && <p>Correct Answer^</p>}
                  </>
                ))}
              </>
            ))}
          </div>
        ) : (
          <>
            <div className="storiesUpload__select-wrapper">
              <label>Story Title (case sensitive): </label>
              <input
                data-cy="storiesUpload__input--title"
                type="text"
                placeholder="Title"
                onChange={(e) => {
                  let mutableStory = storyDoc;
                  mutableStory.title = e.target.value;
                  setStoryDoc(mutableStory);
                }}
              />
            </div>

            {[1, 2, 3].map((qNumber) => (
              <div className="storiesUpload__select-wrapper">
                <div>Q{qNumber}</div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label>Question Type: </label>
                  <select
                    defaultValue="MC"
                    onChange={(e) => {
                      let mutableStory = storyDoc;
                      mutableStory.questions[qNumber - 1].question_type = e.target.value;
                      setStoryDoc(mutableStory);
                    }}
                  >
                    <option value="MC">MC</option>
                  </select>
                  <label>Assessment Type: </label>
                  <select
                    defaultValue="Comprehension"
                    onChange={(e) => {
                      let mutableStory = storyDoc;
                      mutableStory.questions[qNumber - 1].assessment_type = e.target.value;
                      setStoryDoc(mutableStory);
                    }}
                  >
                    <option value="Comprehension">Comprehension</option>
                  </select>
                  <textarea
                    className="storiesUpload__input--level"
                    placeholder={`Question ${qNumber}`}
                    onChange={(e) => {
                      let mutableStory = storyDoc;
                      mutableStory.questions[qNumber - 1].question = e.target.value;
                      setStoryDoc(mutableStory);
                    }}
                  />
                </div>
                {[1, 2, 3].map((aNumber) => (
                  <div>
                    <textarea
                      className="storiesUpload__input--level"
                      data-cy="storiesUpload__input--level"
                      placeholder={`Answer ${aNumber}`}
                      onChange={(e) => {
                        let mutableStory = storyDoc;
                        mutableStory.questions[qNumber - 1][
                          `answer${aNumber}` as 'answer1' | 'answer2' | 'answer3'
                        ].answer = e.target.value;
                        setStoryDoc(mutableStory);
                      }}
                    />
                    <label>Correct?</label>
                    <input
                      type="radio"
                      name={`correct${qNumber}`}
                      onClick={() => {
                        let mutableStory = storyDoc;
                        mutableStory.questions[qNumber - 1][
                          `answer${aNumber}` as 'answer1' | 'answer2' | 'answer3'
                        ].correct = true;
                        setStoryDoc(mutableStory);
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
            {error.isActive && <ErrorHandler type={error.type} message={error.message} />}
            <button
              onClick={(e) => {
                e.preventDefault();
                if (
                  [
                    storyDoc.title,
                    storyDoc.questions[0].question,
                    storyDoc.questions[0].answer1,
                    storyDoc.questions[0].answer2,
                    storyDoc.questions[0].answer3,
                    storyDoc.questions[1].answer1,
                    storyDoc.questions[1].answer2,
                    storyDoc.questions[1].answer3,
                    storyDoc.questions[2].answer1,
                    storyDoc.questions[2].answer2,
                    storyDoc.questions[2].answer3,
                  ].includes('')
                ) {
                  setError({ type: 'error', isActive: true, message: 'Please fill out all the fields.' });
                } else if (
                  ![
                    storyDoc.questions[0].answer1.correct,
                    storyDoc.questions[0].answer2.correct,
                    storyDoc.questions[0].answer3.correct,
                  ].includes(true) ||
                  ![
                    storyDoc.questions[1].answer1.correct,
                    storyDoc.questions[1].answer2.correct,
                    storyDoc.questions[1].answer3.correct,
                  ].includes(true) ||
                  ![
                    storyDoc.questions[2].answer1.correct,
                    storyDoc.questions[2].answer2.correct,
                    storyDoc.questions[2].answer3.correct,
                  ]
                ) {
                  setError({
                    type: 'error',
                    isActive: true,
                    message: 'Please ensure all MC questions have a correct answer.',
                  });
                } else {
                  handleSubmit();
                }
              }}
            >
              Submit
            </button>
          </>
        )}
      </form>
    </main>
  );
};

export default QuestionsUpload;
