//Dependencies
import React from 'react';
import { Route, Switch } from 'react-router-dom';
//Pages
import LoginFLow from './Pages/LoginFlow';
import AdminDashboard from './Pages/AdminDashboard';
import AdminLeads from './Pages/AdminLeads';
import AdminMatches from './Pages/AdminMatches';
import SingleLead from './Pages/SingleLead';
import SingleMatch from './Pages/SingleMatch';
import AdminSMS from './Pages/AdminSMS';
import ResourceHubUpload from './Pages/ResourceHubUpload';
import WorksheetTextBlockUpload from './Pages/WorksheetTextBlockUpload';
import ResourceFilterTextBockUpload from './Pages/ResourceFilterTextBockUpload';
//Components
import ProtectedRoute from './Components/ProtectedRoute';
import AdminNav from './Components/AdminNav';
import Header from './Components/Header';
//Context
import { UserContextProvider } from './Components/Context/UserContext';
import { LeadContextProvider } from './Components/Context/LeadContext';
import StoryUpload from './Pages/StoryUpload';
import PhonicsUpload from './Pages/PhonicsUpload';
import WordsUpload from './Pages/WordsUpload';
import ReadingPortalUsers from './Pages/ReadingPortalUsers';
import QuestionsUpload from './Pages/QuestionsUpload';
import ImageCrop from './Pages/ImageCrop';
import GameQuestionsUpload from './Pages/GameQuestions';
import CurriculumUpload from './Pages/CurriculumUpload';
import WorksheetsUsers from './Pages/WorksheetsUsers';

function App() {
  return (
    <main className="App">
      <UserContextProvider>
        <Header />
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => {
              return <LoginFLow routerProps={props} />;
            }}
          />
          <ProtectedRoute path="/admin" securityGroup={'admin_portal'} component={AdminNav} />
        </Switch>

        <LeadContextProvider>
          <Switch>
            <ProtectedRoute path="/admin" exact={true} securityGroup={'admin_portal'} component={AdminDashboard} />
            <ProtectedRoute
              path="/admin/leads"
              exact={true}
              component={AdminLeads}
              cardProvider={true}
              securityGroup={'admin_portal'}
            />
            <ProtectedRoute
              path="/admin/matches"
              exact={true}
              securityGroup={'admin_portal'}
              component={AdminMatches}
              cardProvider={true}
            />
            <ProtectedRoute path="/admin/matches/:id" securityGroup={'admin_portal'} component={SingleMatch} />
            <ProtectedRoute path="/admin/leads/:id" securityGroup={'admin_portal'} component={SingleLead} />
            <ProtectedRoute
              exact={true}
              path="/admin/readingPortalUsers"
              securityGroup={'admin_portal'}
              component={ReadingPortalUsers}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/worksheetsUsers"
              securityGroup={'admin_portal'}
              component={WorksheetsUsers}
            />
            <ProtectedRoute exact={true} path="/admin/imageCrop" securityGroup={'admin_portal'} component={ImageCrop} />
            {/* <ProtectedRoute path="/admin/messages" securityGroup={'admin_portal'} component={AdminSMS} /> */}
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/resourceHub"
              securityGroup={'resourceHub_form'}
              component={ResourceHubUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/story"
              securityGroup={'resourceHub_form'}
              component={StoryUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/questions"
              securityGroup={'resourceHub_form'}
              component={QuestionsUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/gameQuestions"
              securityGroup={'resourceHub_form'}
              component={GameQuestionsUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/phonics"
              securityGroup={'resourceHub_form'}
              component={PhonicsUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/words"
              securityGroup={'resourceHub_form'}
              component={WordsUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/curricula"
              securityGroup={'resourceHub_form'}
              component={CurriculumUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/singleWorksheet/TextBlock"
              securityGroup={'resourceHub_form'}
              component={WorksheetTextBlockUpload}
            />
            <ProtectedRoute
              exact={true}
              path="/admin/formUpload/filterURL/TextBlock"
              securityGroup={'resourceHub_form'}
              component={ResourceFilterTextBockUpload}
            />
          </Switch>
        </LeadContextProvider>
      </UserContextProvider>
    </main>
  );
}

export default App;
