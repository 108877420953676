import React, { useContext } from 'react';
import { StudentType, Subject } from '../../Models';
import { DisplayContext } from '../Context/DisplayContext';

export const useDisplayData = () => {
  const { displayData, setDisplayData } = useContext(DisplayContext);

  const ConfirmStudentDelete = (value: boolean) => {
    setDisplayData((prev: any) => ({ ...prev, confirmStudentDelete: value }));
  };

  const ConfirmSubjectDelete = (value: boolean) => {
    setDisplayData((prev: any) => ({ ...prev, confirmSubjectDelete: value }));
  };

  const selectStudent = (student: StudentType) => {
    setDisplayData((prev: any) => ({
      ...prev,
      selectedStudent: student,
    }));
  };

  const selectSubject = (subject: Subject) => {
    setDisplayData((prev: any) => ({
      ...prev,
      selectedSubject: subject,
    }));
  };

  return { displayData, ConfirmStudentDelete, ConfirmSubjectDelete, selectStudent, selectSubject };
};
