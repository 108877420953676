import React, { useState, useRef } from 'react';
import { LeadType, MatchType } from '../../Models';
import { FindContactByPhone, isLeadType } from '../../Utils';
import ErrorHandler from './ErrorHandler';
import { useLeadData } from '../Hooks/Lead/useLeadData';
import { socket } from '../Hooks/useUserData';

type DeleteProps = {
  id: number;
  lead: LeadType | MatchType;
};

const DeleteLead: React.FC<DeleteProps> = ({ id, lead }) => {
  const { deleteFromMongo } = useLeadData();
  const [isOpen, setIsOpen] = useState(false);
  const [serverError, setServerError] = useState(false);
  const deleteButton = useRef<HTMLButtonElement>(null);

  if (isOpen) {
    return (
      <div className="warning-container">
        <div className="isa_warning default-margin-bottom">
          <i className="fas fa-exclamation-triangle"></i>
          <span>Are you sure you want to delete this?</span>
        </div>
        <div className="warning-buttons">
          <button
            className="delete-btn"
            onClick={async () => {
              if (deleteButton.current) {
                deleteButton.current.setAttribute('disabled', 'true');
              }
              // const deleteLead = await lead.deleteFromMongo();
              const deleteLead = await deleteFromMongo(lead);
              //todo: (IF LEAD IS LEADTYPE, NOT MATCHTYPE) update cache with this lead, deleting the associate lead from the contactInfo and replacing it with all available fields in contact_data
              if (deleteLead === 200) {
                if (isLeadType(lead)) {
                  let oldContInfo = await FindContactByPhone(lead.client.phone_number);
                  let newContactData = {
                    name: lead.client.full_name,
                    city: lead.client.city,
                    phone: lead.client.phone_number,
                    email: lead.client.email,
                  };
                  socket.emit('updateCache', {
                    newMessage: null,
                    contInfo: { ...oldContInfo, contact_data: newContactData, associated_lead: undefined },
                  });
                }
                window.location.reload();
              } else {
                setServerError(true);
              }
            }}
            data-cy="lead_form--delete"
          >
            DELETE
          </button>
          <button
            className="save"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            CANCEL
          </button>
        </div>
        {serverError ? (
          <ErrorHandler
            handler={() => {
              setServerError(false);
            }}
            type="error"
            // error={`There was an error while deleting the ${
            //   lead instanceof Leads ? 'Lead' : 'Match'
            // }. Please try again`}
            error={`There was an error while deleting the document. Please try again`}
          />
        ) : null}
      </div>
    );
  }
  return (
    <button
      className="delete-btn"
      onClick={() => {
        setIsOpen(true);
      }}
      data-cy="lead_form--set_delete"
    >
      {/* Delete {lead instanceof LeadType ? 'Lead' : 'Match'} */}
      Delete
    </button>
  );
};

export default DeleteLead;
