//Dependencies
import React from 'react';
import { GetBaseURL } from '../Utils';
import { useUserData } from '../Components/Hooks/useUserData';

type LogoutProps = {
  name?: string;
};

const Logout: React.FC<LogoutProps> = ({ name }) => {
  const { removeUserAfterLogout } = useUserData();

  const logoutHandler = async () => {
    const LOGOUT_REQUEST = await fetch(`${GetBaseURL()}/api/login/logout`);
    const RES = LOGOUT_REQUEST.status;

    if (RES === 200) {
      removeUserAfterLogout();
    }
  };

  return (
    <div className="logout pointer" onClick={logoutHandler}>
      <h4>{name}</h4>
    </div>
  );
};

export default Logout;
