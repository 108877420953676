//Dependencies
import React, { useEffect, useState } from 'react';
import UpdatePassword from '../Components/AdminDashboard/UpdatePassword';
import RenderLeadData from '../Components/AdminDashboard/RenderLeadData';
import { GetBaseURL, FindCookieValue } from '../Utils';
import { useUserData } from '../Components/Hooks/useUserData';
import { CookieTypes } from '../Models';

type DashBoardData = {
  numberOfLeads: number;
  numberOfMatches: number;
  classStyle: string;
};

const AdminDashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<DashBoardData | null>(null);
  const { userData, setUser } = useUserData();

  useEffect(() => {
    if (userData.currentUser.email === '' && userData.adminsWithAllData.length > 0) {
      const userEmail = FindCookieValue(CookieTypes.Email);
      if (userEmail) {
        let user = userData.adminsWithAllData.find((singleAdmin: any) => {
          return singleAdmin.email === userEmail;
        });

        setUser({
          full_name: user.full_name,
          email: user.email,
          role: user.role,
          home_region: user.home_region,
          security_groups: user.security_groups ? user.security_groups : undefined,
        });
      }
    }
  }, [userData.currentUser, userData.adminsWithAllData]);

  useEffect(() => {
    (async () => {
      const DATA = await fetch(`${GetBaseURL()}/api/dashboard/leadInfo`);
      const JSON = await DATA.json();
      setDashboardData(JSON.leadData);
    })();
  }, []);

  return (
    <div className="dashboard-container">
      {
        //!Here is where I want the section with the small components that deal with state values
        dashboardData === null ? (
          <div>Loading...</div>
        ) : (
          <React.Fragment>
            <div className="dashboard">
              <RenderLeadData classStyle="pink-gradient" dataType={'Leads'} dataLength={dashboardData.numberOfLeads} />
              <RenderLeadData
                classStyle="blue-gradient"
                dataType={'Matches'}
                dataLength={dashboardData.numberOfMatches}
              />
            </div>
          </React.Fragment>
        )
      }

      <UpdatePassword />
    </div>
  );
};

export default AdminDashboard;
