import React, { useState, useEffect, useRef } from 'react';
import { NFTTutor } from '../../Models';
import { GetBaseURL } from '../../Utils';

type SearchSingleTutorInputProps = {
  addTutor: (tutor: NFTTutor) => void;
  country: string;
};

export const SearchSingleTutorInput: React.FC<SearchSingleTutorInputProps> = ({ addTutor, country }) => {
  const [allTutors, setAllTutors] = useState<NFTTutor[]>([]);
  const [filteredTutors, setFilteredTutors] = useState<NFTTutor[]>([]);
  const tutorSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      let fetchRequest = await fetch(`${GetBaseURL()}/api/tutors/fetchAll/${country}`);
      let response = await fetchRequest.json();

      setAllTutors(response.tutors);
      setFilteredTutors(response.tutors);
    })();
  }, []);

  let mappedTutors = filteredTutors.filter((tutor, index) => {
    return index < 5;
  });

  const handleChangeEvent = () => {
    if (tutorSearchRef.current!.value !== null) {
      let newTutorList = allTutors.filter((tutor) => {
        return tutor.tutor_name.toLowerCase().includes(tutorSearchRef.current!.value.toLowerCase());
      });

      setFilteredTutors(newTutorList);
    }
  };

  return (
    <div className="tutor-search-container">
      <div className="search-icon">
        <i className="fas fa-search"></i>
      </div>
      <input
        className="single-tutor-search"
        onChange={handleChangeEvent}
        type="text"
        ref={tutorSearchRef}
        placeholder={'Find Tutor By Name'}
        data-cy="single_tutor_search"
      />
      <div className="autofill-container">
        {tutorSearchRef.current && tutorSearchRef.current!.value !== '' && tutorSearchRef.current!.value !== null
          ? mappedTutors.map((tutor, index) => {
              return (
                <span
                  className="autofill"
                  onClick={() => {
                    addTutor(tutor);
                    setFilteredTutors(allTutors);
                    tutorSearchRef.current!.value = '';
                  }}
                  key={index}
                  data-cy="single_tutor_search--response"
                >
                  {tutor.tutor_name}
                </span>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default SearchSingleTutorInput;
