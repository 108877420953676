import { useContext } from 'react';
import { LeadContext } from '../../Context/LeadContext';
import { LeadType, StudentType, Subject, NFTSubjectList, StudentGrades, studentGrades } from '../../../Models';
import { GetBaseURL } from '../../../Utils';

export const useStudentData = () => {
  const { lead, setLead } = useContext(LeadContext);

  // <-------------------- input functions -------------------->
  const handleStudentInputChange = (e: React.ChangeEvent<HTMLInputElement>, student: StudentType) => {
    e.persist();
    let updatedStudent: StudentType = student;
    let targetInput: keyof StudentType = e.target.name! as keyof StudentType;

    switch (targetInput) {
      case 'student_name':
        updatedStudent.student_name = e.target.value;
        break;
      case 'student_phone':
        updatedStudent.student_phone = e.target.value;
        break;
      case 'student_email':
        updatedStudent.student_email = e.target.value;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedStudent !== null) {
        updatedLead.students[updatedLead.students.indexOf(student)] = updatedStudent;
      }
      return updatedLead;
    });
  };

  const handleStudentSelect = (e: React.ChangeEvent<HTMLSelectElement>, student: StudentType) => {
    e.persist();
    let updatedStudent: StudentType = student;
    let targetInput: keyof StudentType = e.target.name! as keyof StudentType;

    switch (targetInput) {
      // case 'student_grade':
      // updatedStudent.student_grade = e.target.value;
      case 'grade':
        updatedStudent.grade = e.target.value;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedStudent !== null) {
        updatedLead.students[updatedLead.students.indexOf(student)] = updatedStudent;
      }
      return updatedLead;
    });
  };

  const updateStudentStringValue = (student: StudentType, studentProperty: keyof StudentType, newValue: string) => {
    if (
      studentProperty === 'student_name' ||
      studentProperty === 'student_phone' ||
      studentProperty === 'student_email'
    ) {
      student[studentProperty] = newValue;
    } else {
      alert('There was an error with the client property provided');
    }
  };

  const saveStudentToTC = async (student: StudentType, clientID: number, country: string) => {
    let postObject = {
      newStudent: {
        user: {
          first_name: student.student_name.split(' ')[0],
          last_name: student.student_name.split(' ')[1], //!this needs to be a better function....
          email: student.student_email === '' ? undefined : student.student_email,
          phone: student.student_phone,
        },
        paying_client: clientID,
      },
      oldStudent: student,
      country: country,
    };

    const request = await fetch(`${GetBaseURL()}/api/students/postToTutorcruncher`, {
      method: 'POST',
      body: JSON.stringify(postObject),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (request.status === 200) {
      let response = await request.json();
      student.tutorcruncher_id = response.id;
      return { id: response.id, successfulSave: true };
    } else {
      return {
        successfulSave: false,
      };
    }
  };

  const updateStudentInTC = async (student: StudentType, clientID: number, country: string) => {
    let newStudent = {
      newStudent: {
        user: {
          first_name: student.student_name.split(' ')[0],
          last_name: student.student_name.split(' ')[1],
          email: student.student_email,
          phone: student.student_phone,
        },
        paying_client: clientID,
      },
      oldStudent: student,
      country: country,
    };

    const request = await fetch(`${GetBaseURL()}/api/students/updateInTC`, {
      method: 'POST',
      body: JSON.stringify(newStudent),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (request.status === 200) {
      let response = await request.json();
      student.tutorcruncher_id = response.id;
      return { id: response.id, successfulSave: true };
    } else {
      return {
        successfulSave: false,
      };
    }
  };

  const handleIDUpdate = (id: number, index: number) => {
    let updatedStudent: StudentType = lead!.students[index];
    updatedStudent.tutorcruncher_id = id;

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedStudent !== null) {
        updatedLead.students[index] = updatedStudent;
      }
      return updatedLead;
    });
  };

  const initializeEmptyStudent = () => {
    let newStudent: StudentType = {
      student_name: '',
      student_phone: '',
      student_email: '',
      grade: '',
      subjects: [],
      skill_level_and_goals: '',
      first_appointment: '',
    };
    let newStudents = [...lead.students, newStudent];

    setLead((prev: LeadType) => {
      let updatedLead = {
        ...prev,
        students: newStudents,
      };
      return updatedLead;
    });
  };

  const instantiateSubject = (student: StudentType) => {
    let newSubject: Subject = {
      student: student.student_name,
      subject_name: NFTSubjectList.English,
      subject_grade: student.grade ? student.grade : '',
      subject_price: 30,
      appointment_times: '',
      skills_and_goals: '',
      resources_required: false,
      online_sessions: true,
    };
    newSubject.subject_grade !== StudentGrades.Kindergarten && Number(newSubject.subject_grade.split(' ')[1]) > 11
      ? (newSubject.subject_price = 40)
      : (newSubject.subject_price = 30);
    student.subjects.push(newSubject);
  };

  const removeStudent = (student: StudentType) => {
    const filteredStudents = lead.students.filter((s, index) => {
      return s.student_name !== student.student_name && index !== lead.students.indexOf(student);
    });
    setLead((prev: LeadType) => {
      let updatedLead = {
        ...prev,
        students: filteredStudents,
      };
      return updatedLead;
    });
  };

  return {
    handleStudentInputChange,
    handleStudentSelect,
    updateStudentStringValue,
    saveStudentToTC,
    updateStudentInTC,
    handleIDUpdate,
    initializeEmptyStudent,
    instantiateSubject,
    removeStudent,
  };
};
