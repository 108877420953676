import React, { RefObject } from 'react';
import { Subject } from '../../Models';

type TextInputProps = {
  className?: string;
  title: string;
  name: 'skills_and_goals' | 'appointment_times';
  refer: RefObject<HTMLTextAreaElement>;
  value?: string;
  handler: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    value: 'skills_and_goals' | 'appointment_times',
    subject: Subject,
  ) => void;
  update: () => void;
  isLocked?: boolean;
  subject: Subject;
};

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  return (
    <div>
      <label>{props.title}</label>
      <textarea
        className={props.className ? props.className + ' student-input-width' : 'student-input-width'}
        name={props.name}
        ref={props.refer}
        rows={5}
        defaultValue={props.value ? props.value : ''}
        onChange={(e) => {
          props.handler(e, props.name, props.subject);
        }}
        onBlur={props.update}
        disabled={props.isLocked}
      />
    </div>
  );
};

export default TextInput;
