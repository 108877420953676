import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { FindCookieValue } from '../Utils';
import { CookieTypes } from '../Models';
import { LeadCardContextProvider } from './Context/LeadCardContext';
import { useUserData } from '../Components/Hooks/useUserData';

type ProtectedRouteProps = {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  cardProvider?: boolean;
  securityGroup?: string;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  path,
  cardProvider = false,
  exact = false,
  securityGroup,
}) => {
  const { userData, setUser } = useUserData();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsAuthorized(false);
    setIsLoading(true);
  }, [component]);

  useEffect(() => {
    if (userData.currentUser.email === '' && userData.adminsWithAllData.length > 0) {
      const userEmail = FindCookieValue(CookieTypes.Email);
      if (userEmail) {
        let user = userData.adminsWithAllData.find((singleAdmin: any) => {
          return singleAdmin.email === userEmail;
        });

        setUser({
          full_name: user.full_name,
          email: user.email,
          role: user.role,
          home_region: user.ome_region,
          security_groups: user.security_groups ? user.security_groups : undefined,
        });
      }
    }
  }, [userData.currentUser, userData.adminsWithAllData]);

  useEffect(() => {
    if (isAuthorized === false && userData.currentUser.email !== '') {
      let hasSecurityGroupAuth =
        userData.currentUser &&
        userData.currentUser.security_groups &&
        userData.currentUser.security_groups.length > 0 &&
        userData.currentUser.security_groups.includes(securityGroup);
      setIsAuthorized(hasSecurityGroupAuth);
      setIsLoading(false);
    }
  }, [isAuthorized, userData.currentUser.email]);

  if (isLoading) {
    return <h3>LOADING</h3>;
  } else if (isAuthorized && exact) {
    return (
      <>
        {cardProvider ? (
          <LeadCardContextProvider>
            <Route to={path} exact component={component} />
          </LeadCardContextProvider>
        ) : (
          <Route to={path} exact component={component} />
        )}
      </>
    );
  } else if (isAuthorized) {
    return (
      <>
        {cardProvider ? (
          <LeadCardContextProvider>
            <Route to={path} component={component} />
          </LeadCardContextProvider>
        ) : (
          <Route to={path} component={component} />
        )}
      </>
    );
  } else if (userData.currentUser.email === '') {
    return <h3>LOADING</h3>;
  } else {
    return <Redirect to="/" />;
  }
};

export default ProtectedRoute;
