import React, { useState, useEffect } from 'react';
//Components
import LeadsModal from './LeadsModal';
import CreateLeadModal from './CreateLeadModal';
import ErrorHandler from '../Partials/ErrorHandler';
//Types/Classes
import { AdminLeadsFilterColumn, CardData } from '../../Models';
import { Drop, DragOver, GetNumberForTwilio } from '../../Utils';
//Context
import { LeadContextProvider } from '../Context/LeadContext';
import { useLeadCards } from '../Hooks/Lead/useLeadCards';

type RenderLeadsProps = {
  componentTitle: string;
  hasCreateLead: boolean;
  column: AdminLeadsFilterColumn;
  hasData: Boolean;
  RefreshComponent: (value: boolean) => void;
  refresh: boolean;
  icon?: string;
};

const RenderLeads: React.FC<RenderLeadsProps> = ({
  componentTitle,
  hasCreateLead,
  column,
  hasData,
  RefreshComponent,
  refresh,
  icon,
}) => {
  const [cardInfo, setCardInfo] = useState<CardData[]>([]);
  const [dropError, setDropError] = useState(false);
  const [dropErrorMessage, setDropErrorMessage] = useState('');
  const { fetchColumnCards, updateColumnOfCard, cardContext } = useLeadCards();

  useEffect(() => {
    if (cardContext.filter === '' && hasData) {
      setCardInfo(fetchColumnCards(column));
    } else if (hasData) {
      let allCards = fetchColumnCards(column);
      let query = cardContext.filter.toLowerCase();

      let filtered = allCards.filter((singleCard: CardData) => {
        let phone = singleCard.client_phone ? GetNumberForTwilio(singleCard.client_phone) : '';
        return (
          singleCard.client_city?.toLowerCase().includes(query) ||
          singleCard.client_name?.toLowerCase().includes(query) ||
          phone.includes(query) ||
          singleCard.lead_form_type?.toLowerCase().includes(query) ||
          singleCard.lead_source?.toLowerCase().includes(query) ||
          singleCard.notes?.toLowerCase().includes(query) ||
          singleCard.title?.toLowerCase().includes(query) ||
          singleCard.labels?.join(' ').toLowerCase().includes(query)
        );
      });
      setCardInfo(filtered);
    }
  }, [cardContext.filter, hasData]);

  const onDrop = async (event: React.DragEvent<HTMLElement>) => {
    let resultOfDrop: { status: number; message: string; id: string } = await Drop(event, column);
    if (resultOfDrop.status === 200) {
      updateColumnOfCard(resultOfDrop.id, column);
      RefreshComponent(true);
    } else {
      setDropError(true);
      setDropErrorMessage(resultOfDrop.message);
    }
  };

  useEffect(() => {
    if (refresh === true) {
      setCardInfo(fetchColumnCards(column));
      RefreshComponent(false);
    }
  }, [refresh]);

  if (cardInfo.length > 0) {
    const RENDER_CARDS = cardInfo.map((obj) => {
      return <LeadsModal key={obj._id!} cardInfo={obj} />;
    });

    return (
      <LeadContextProvider>
        <div className="drop-container" onDragOver={DragOver} onDrop={onDrop}>
          <div className="header-and-total">
            <div className="icon-margin">
              <img src={icon}></img>
            </div>
            <h3>{componentTitle}</h3>
            <span>{RENDER_CARDS.length}</span>
          </div>
          {dropError && dropErrorMessage !== '' ? (
            <ErrorHandler
              handler={() => {
                setDropError(false);
                setDropErrorMessage('');
              }}
              type="error"
              error={dropErrorMessage}
            />
          ) : null}
          {hasCreateLead ? <CreateLeadModal /> : null}
          {RENDER_CARDS}
        </div>
      </LeadContextProvider>
    );
  } else {
    return (
      <LeadContextProvider>
        <div className="drop-container" onDragOver={DragOver} onDrop={onDrop}>
          <div className="header-and-total">
            <div className="icon-margin">
              <img src={icon}></img>
            </div>
            <h3>{componentTitle}</h3>
          </div>
          {dropError && dropErrorMessage !== '' ? (
            <ErrorHandler
              handler={() => {
                setDropError(false);
                setDropErrorMessage('');
              }}
              type="error"
              error={dropErrorMessage}
            />
          ) : null}
          {hasCreateLead ? <CreateLeadModal /> : null}
          <p className="card shadow default-margin-top default-padding-top">No leads Available</p>
        </div>
      </LeadContextProvider>
    );
  }
};

export default RenderLeads;
