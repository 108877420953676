import React, { useState, useRef, useEffect, RefObject } from 'react';
import DropDown from '../Components/Partials/Dropdown';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { studentGrades, ErrorProps } from '../Models';
import { GetBaseURL, SendEmail } from '../Utils';

type Standard = {
  state: string;
  code: string;
  description: string;
  grade: number;
  domain: string;
};

const CurriculumUpload: React.FC = () => {
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });
  const stateRef = useRef<HTMLInputElement>(null);
  const standardsRef = useRef<HTMLInputElement>(null);

  const submitFile = async (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current && stateRef.current) {
      if (stateRef.current.value.trim() !== '') {
        if (ref.current.files === null || ref.current.files.length === 0) {
          console.log('No file submitted');
        } else {
          const FD = new FormData();
          FD.append('Questions Sheet', ref.current.files[0]);
          const URL = `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/curricula/uploadCurriculum/${stateRef.current.value}?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;

          const request = await fetch(URL, {
            body: FD,
            method: 'POST',
          });

          const response = await request.json();

          request.status === 200
            ? setError({ isActive: true, type: 'success', message: response.message })
            : setError({ isActive: true, type: 'error', message: response.message });
        }
      } else {
        setError({ type: 'error', isActive: true, message: 'Please enter a state or country.' });
      }
    } else {
      setError({ isActive: true, type: 'error', message: 'Could not locate the file reference on the page.' });
    }
  };

  return (
    <main className="main-container">
      <h1 className="header-title">Upload Curriculum</h1>
      <div className="resourceUpload__input-group">
        <label className="resourceUpload__label">Upload Questions CSV:</label>
        <input type="file" ref={standardsRef} />
        <input type="text" ref={stateRef} className="resourceUpload__select"></input>
      </div>
      <button className="resourceUpload__form-button" onClick={() => submitFile(standardsRef)}>
        Submit CSV
      </button>
    </main>
  );
};

export default CurriculumUpload;
