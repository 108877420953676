import { useContext, useState } from 'react';
import { LeadCardContext, CardContext } from '../../Context/LeadCardContext';
import { AdminLeadsFilterColumn, CardData } from '../../../Models';
import { GetBaseURL } from '../../../Utils';

export const useLeadCards = () => {
  const { cardContext, setCardContext } = useContext(LeadCardContext);
  // const [cardFilter, setCardFilter] = useState<string>('');

  const setLeadCards = async () => {
    let request = await fetch(`${GetBaseURL()}/api/leads/fetchLeadCards`);
    let data: CardData[] = await request.json();
    setCardContext({ cards: data, filter: '' });
  };

  const fetchColumnCards = (value: AdminLeadsFilterColumn) => {
    if (value === AdminLeadsFilterColumn.Blocked || value === AdminLeadsFilterColumn.UnableToContact) {
      // filter for them both
      return cardContext.cards.filter((card) => {
        return card.column === AdminLeadsFilterColumn.Blocked || card.column === AdminLeadsFilterColumn.UnableToContact;
      });
    } else if (value === AdminLeadsFilterColumn.FutureStart || value === AdminLeadsFilterColumn.Contacted) {
      return cardContext.cards.filter((card) => {
        return card.column === AdminLeadsFilterColumn.FutureStart || card.column === AdminLeadsFilterColumn.Contacted;
      });
    } else {
      return cardContext.cards.filter((card) => {
        return card.column === value;
      });
    }
  };

  const updateColumnOfCard = (id: string, column: string) => {
    setCardContext((prev: CardContext) => {
      const UPDATED_CARDS = prev.cards.map((card) => {
        if (card._id === id) {
          card.column = column;
        }
        return card;
      });
      return { ...prev, cards: UPDATED_CARDS };
    });
  };

  //Change Title for Single Card
  const updateCardTitle = (id: string, title: string) => {
    setCardContext((prev: CardContext) => {
      const UPDATED_CARDS = prev.cards.map((card) => {
        if (card._id === id) {
          card.title = title;
        }
        return card;
      });
      return { ...prev, cards: UPDATED_CARDS };
    });
  };

  const updateCardFilter = (query: string) => {
    // setCardFilter(query);
    setCardContext((prev: CardContext) => {
      return { ...prev, filter: query };
    });
  };

  return { cardContext, fetchColumnCards, setLeadCards, updateColumnOfCard, updateCardTitle, updateCardFilter };
};
