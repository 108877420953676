import React, { useState } from 'react';
import { Contract } from '../../Models';

export interface LeadContractContextProps {
  contracts: Contract[];
  setContracts: (ContractData: Contract[]) => void;
}

const LeadContractContext = React.createContext({} as LeadContractContextProps);

const LeadContractContextProvider = (props: any) => {
  const [contracts, setContracts] = useState<Contract[]>([]);

  const providerValue: LeadContractContextProps = {
    contracts,
    setContracts,
  };

  return <LeadContractContext.Provider value={providerValue}>{props.children}</LeadContractContext.Provider>;
};

export { LeadContractContext, LeadContractContextProvider };
