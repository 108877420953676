//Dependencies
import React, { useEffect, useRef, useState } from 'react';

const ReadingPortalUsers: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [publicUsers, setPublicUsers] = useState<any[]>([]);
  const [expandUsers, setExpandUsers] = useState<any[]>([]);
  const codeRef = useRef<HTMLInputElement>(null);
  const durationRef = useRef<HTMLSelectElement>(null);

  // {user.subscription_data.next_billing_date
  //   ? 'paid'
  //   : user.subscription_data.plan_type === 'paid'
  //   ? 'tutor'
  //   : 'free'}
  const fetchRecentUsers = async () => {
    const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/recent`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const response = await request.json();
    if (request.status === 200) {
      const filteredUsers = response.users.filter((user: any) =>
        user.subscription_data.next_billing_date ? true : user.subscription_data.plan_type === 'paid' ? false : true,
      );

      setUsers(
        filteredUsers
          .sort((first: any, second: any) => {
            if (first.subscription_data.freemium_tallies.length === 0) {
              return -1;
            } else if (second.subscription_data.freemium_tallies.length === 0) {
              return 1;
            } else if (
              new Date(
                first.subscription_data.freemium_tallies[
                  first.subscription_data.freemium_tallies.length - 1
                ].access_date,
              ).valueOf() -
                new Date(
                  second.subscription_data.freemium_tallies[
                    second.subscription_data.freemium_tallies.length - 1
                  ].access_date,
                ).valueOf() >
              0
            ) {
              return 1;
            } else if (
              new Date(
                first.subscription_data.freemium_tallies[
                  first.subscription_data.freemium_tallies.length - 1
                ].access_date,
              ).valueOf() -
                new Date(
                  second.subscription_data.freemium_tallies[
                    second.subscription_data.freemium_tallies.length - 1
                  ].access_date,
                ).valueOf() <
              0
            ) {
              return -1;
            } else {
              return 0;
            }
          })
          .reverse(),
      );
    } else {
      console.log(response.message);
    }
  };

  const fetchPublicUsers = async () => {
    const request = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/users?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
      { method: 'POST', headers: { 'Content-Type': 'application/json' } },
    );
    const response = await request.json();
    if (request.status === 200) {
      setPublicUsers(response.users);
    } else {
      console.log(response.message);
    }
  };

  useEffect(() => {
    fetchRecentUsers();
    fetchPublicUsers();
  }, []);

  const ConvertGradeToNumberOrString = (grade: number | string) =>
    typeof grade === 'number'
      ? grade === -1
        ? 'Pre-K'
        : grade === 0
        ? 'KG'
        : `Gr ${grade}`
      : grade === 'Pre-K'
      ? -1
      : grade === 'KG'
      ? 0
      : Number(grade.split(' ')[1]);

  return (
    <main className="main-container">
      <label>DO NOT CLICK THESE UNLESS YOU KNOW WHAT YOU ARE DOING</label>
      <button
        onClick={() => {
          fetch(
            `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/changeEncryption?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            { method: 'PATCH', headers: { 'Content-Type': 'application/json' } },
          );
        }}
      >
        Encrypt passwords with new encryption
      </button>
      {/* <button
        onClick={async () => {
          const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/verifyAll`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
          });
          const response = await request.json();
          console.log(response.message);
        }}
      >
        Verify All Users
      </button> */}
      {/* <button
        onClick={async () => {
          const request = await fetch(`${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/convertAgeToGrade`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
          });
          const response = await request.json();
          console.log(response.message);
        }}
      >
        Convert Age to Grade (one time use)
      </button> */}
      {/* <button
        onClick={async () => {
          const request = await fetch(
            `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/givePaidSub?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            {
              method: 'PATCH',
              headers: { 'Content-Type': 'application/json' },
            },
          );
          const response = await request.json();
          if (request.status === 200) {
            window.location.reload();
          } else {
            console.log(response.message);
          }
        }}
      >
        Give Everyone a Paid Sub
      </button> */}
      <input type="text" placeholder="Code" ref={codeRef} />
      <select defaultValue="Duration" ref={durationRef}>
        <option value="Duration">Duration</option>
        <option value="one month">One Month</option>
        <option value="three months">Three Months</option>
      </select>
      <button
        onClick={async () => {
          if (
            codeRef.current &&
            durationRef.current &&
            codeRef.current.value.trim() !== '' &&
            durationRef.current.value !== 'Duration'
          ) {
            const request = await fetch(
              `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/payments/createCode?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
              {
                method: 'POST',
                body: JSON.stringify({ code: codeRef.current.value.trim(), duration: durationRef.current.value }),
                headers: { 'Content-Type': 'application/json' },
              },
            );
            const response = await request.json();

            console.log(response.message);
          }
        }}
      >
        Create Coupon Code
      </button>
      {/* <button
        onClick={async () => {
          const request = await fetch(
            `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/users/createReadingAccounts?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ publicUsers }) },
          );
          const response = await request.json();

          console.log(response.message);
        }}
      >
        Create Reading Accounts From Existing Worksheet Accounts
      </button>
      <button
        onClick={async () => {
          const request = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/users/createWorksheetAccounts?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ users }) },
          );
          const response = await request.json();

          console.log(response.message);
        }}
      >
        Create Worksheet Accounts From Existing Reading Accounts
      </button> */}
      <h1 className="header-title">Reading Portal Users</h1>
      <h2>Total Users: {users.length}</h2>
      <h3>Click a row to expand its contents</h3>
      <h4>Datetime Format: (dd/mm/yyyy, hr:min:sec)</h4>
      <button
        onClick={() => {
          setExpandUsers(users.map((user) => user._id));
        }}
      >
        Expand All
      </button>
      <button
        onClick={() => {
          setExpandUsers([]);
        }}
      >
        Collapse All
      </button>
      <div className="reading-portal-users">
        <span className="reading-portal-users__title reading-portal-users__first-item">Email</span>
        <span className="reading-portal-users__title reading-portal-users__second-item">Plan Type</span>
        <span className="reading-portal-users__title reading-portal-users__last-item">Date Created</span>

        {users.length > 0 &&
          users.map((user: any, index) => (
            <React.Fragment key={index}>
              <span
                className="reading-portal-users__item reading-portal-users__first-item"
                style={index % 2 === 0 ? { backgroundColor: '#b7a0a0' } : { backgroundColor: 'lightgray' }}
                onClick={() => {
                  setExpandUsers((prev: any[]) =>
                    prev.includes(user._id)
                      ? prev.filter((prevId: string) => prevId !== user._id)
                      : [...prev, user._id],
                  );
                }}
              >
                <h3>{user.user_data.email}</h3>
                {expandUsers.includes(user._id) && (
                  <div>
                    <span style={{ textDecoration: 'underline' }}>Children</span>
                    {(user.user_data.linked_accounts as any[]).map((child: any, childIndex) => (
                      <React.Fragment key={`${index} ${childIndex}`}>
                        <div>
                          <span>{child.user_data.name}: </span>
                          <span>{ConvertGradeToNumberOrString(child.user_data.grade)}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </span>
              <span
                className="reading-portal-users__item reading-portal-users__second-item"
                style={index % 2 === 0 ? { backgroundColor: '#b7a0a0' } : { backgroundColor: 'lightgray' }}
                onClick={() => {
                  setExpandUsers((prev: any[]) =>
                    prev.includes(user._id)
                      ? prev.filter((prevId: string) => prevId !== user._id)
                      : [...prev, user._id],
                  );
                }}
              >
                <h3>
                  {user.subscription_data.next_billing_date
                    ? 'paid'
                    : user.subscription_data.plan_type === 'paid'
                    ? 'tutor'
                    : 'free'}
                </h3>
                {expandUsers.includes(user._id) && (
                  <div>
                    <span style={{ textDecoration: 'underline' }}>Stories Opened</span>
                    {(user.subscription_data.freemium_tallies as any[]).map((story, storyIndex) => (
                      <React.Fragment key={`${index}-${storyIndex}`}>
                        <div>
                          <span>{story.story_title}: </span>
                          <span>{`${new Date(story.access_date).toLocaleString()}`}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </span>
              <span
                className="reading-portal-users__item reading-portal-users__last-item"
                style={index % 2 === 0 ? { backgroundColor: '#b7a0a0' } : { backgroundColor: 'lightgray' }}
                onClick={() => {
                  setExpandUsers((prev: any[]) =>
                    prev.includes(user._id)
                      ? prev.filter((prevId: string) => prevId !== user._id)
                      : [...prev, user._id],
                  );
                }}
              >
                <h3>{new Date(user.date_created).toLocaleString()}</h3>
                {expandUsers.includes(user._id) && (
                  <div>
                    <div style={{ textDecoration: 'underline' }}>
                      {user.subscription_data.plan_type === 'free'
                        ? user.subscription_data.stripe_customer_id
                          ? 'Opened payment modal'
                          : 'Did not open payment modal'
                        : ''}
                    </div>
                    <div>
                      <span>Device:</span>
                      <span>{user.user_data.device ? user.user_data.device : 'N/A'}</span>
                    </div>
                  </div>
                )}
              </span>
            </React.Fragment>
          ))}
      </div>
    </main>
  );
};

export default ReadingPortalUsers;
