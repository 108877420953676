//Dependencies
import React, { useEffect, useState } from 'react';
//Components
import RenderLeads from '../Components/LeadsForm/RenderLeads';
//Types
import { AdminLeadsFilterColumn } from '../Models';
//Context
import { useLeadCards } from '../Components/Hooks/Lead/useLeadCards';

import Hat from '../assets/hat_3.png';
import CalI from '../assets/cal_2.png';
import Block from '../assets/block.png';
import FilterLeadCards from '../Components/Partials/FilterLeadCards';

const AdminLeads: React.FC = () => {
  const [willFetchData, setWillFetchData] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const { cardContext, setLeadCards } = useLeadCards();

  useEffect(() => {
    if (willFetchData) {
      setLeadCards();
      setWillFetchData(false);
    }
  });

  useEffect(() => {
    if (cardContext.cards.length > 0) {
      setHasData(true);
    }
  });

  const RefreshComponent = (value: boolean) => {
    setToggleRefresh(value);
  };

  return (
    <main className="main-container">
      <h1 className="header-title">Leads</h1>
      <FilterLeadCards />
      {/* //todo: implement component to filter Leads*/}
      <div className="grid-container">
        <RenderLeads
          componentTitle="New Leads"
          hasCreateLead={true}
          column={AdminLeadsFilterColumn.NewLead}
          hasData={hasData}
          RefreshComponent={RefreshComponent}
          refresh={toggleRefresh}
          icon={Hat}
        />
        <RenderLeads
          componentTitle="Contacted"
          hasCreateLead={false}
          column={AdminLeadsFilterColumn.Contacted}
          hasData={hasData}
          RefreshComponent={RefreshComponent}
          refresh={toggleRefresh}
          icon={CalI}
        />
        <RenderLeads
          componentTitle="Unable to Contact"
          hasCreateLead={false}
          column={AdminLeadsFilterColumn.UnableToContact}
          hasData={hasData}
          RefreshComponent={RefreshComponent}
          refresh={toggleRefresh}
          icon={Block}
        />
      </div>
    </main>
  );
};

export default AdminLeads;
