import React, { useRef, FormEvent, useState, useEffect } from 'react';
//Components
import ClientInMatch from './ClientInMatch';
import StudentInMatch from './StudentInMatch';
import Dropdown from '../Partials/Dropdown';
//Types
import { AdminLeadsFilterColumn, StudentType, MatchType, NFTTutor } from '../../Models';
//Utils
import { SendSMS, SendEmail } from '../../Utils';
import RenderTutorInMatch from './RenderTutorInMatch';
import DeleteLead from '../Partials/DeleteLead';
import ErrorHandler from '../Partials/ErrorHandler';
import SearchSingleTutorInput from '../LeadsForm/SearchSingleTutorInput';
import { Redirect } from 'react-router-dom';

import { useMatchData } from '../Hooks/Lead/useMatchData';
import { useLeadData } from '../Hooks/Lead/useLeadData';
import { socket } from '../Hooks/useUserData';

type FormProps = {
  match: MatchType;
};

const LeadsForm: React.FC<FormProps> = ({ match }) => {
  //State
  const { update, deleteFromMongo } = useLeadData();
  const { EmailResourcesToClient, postEntireContract } = useMatchData();
  const [tutorsOffered, setTutorsOffered] = useState<NFTTutor[]>(match.tutors_offered);
  const [selectedTutor, setSelectedTutor] = useState<NFTTutor | null>(null);
  const [smsToClient, setSMSToClient] = useState<string>(
    "Thanks for your patience - we've found a great tutor for you! You will receive an email with the details.",
  );
  const [willRedirect, setWillRedirect] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  //Error State
  const [saveMatchError, setSaveMatchError] = useState(false);
  const [saveMatchSuccess, setSaveMatchSuccess] = useState(false);
  const [bookAppointmentError, setBookAppointmentError] = useState(false);
  const [bookAppointmentSuccess, setBookAppointmentSuccess] = useState(false);
  const [clientSMSError, setClientSMSError] = useState(false);
  const [clientSMSSuccess, setClientSMSSuccess] = useState(false);

  //Refs
  const notesRef = useRef<HTMLTextAreaElement>(null);
  const updateColumnRef = useRef<HTMLSelectElement>(null);
  const sendSmsRef = useRef<HTMLButtonElement>(null);
  const bookAppointmentRef = useRef<HTMLButtonElement>(null);
  const saveMatchRef = useRef<HTMLButtonElement>(null);

  const updateSMS = (tutor: NFTTutor) => {
    setSMSToClient(
      `Thanks for your patience - we've found a great tutor for you! Their name is ${
        selectedTutor?.tutor_name.split(' ')[0]
      } and they can be reached at ${selectedTutor?.tutor_phone} and by email at ${selectedTutor?.tutor_email}.`,
    );
  };

  const changeSMStoClient = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setSMSToClient(e.target.value);
  };

  const buildEmailToClient = (clientName: string, tutorName: string, tutorPhone: string, tutorEmail: string) => {
    const clientFirst = clientName.split(' ')[0];
    const message = `
Hi ${clientFirst},

We've found a tutor for you - here is their contact information:
      
Name: ${tutorName}
Phone: ${tutorPhone}
Email: ${tutorEmail}

Have a great day!
`;
    return message;
  };

  const findInPersonStudents = (students: StudentType[]) => {
    let inPersonStudents: any = [];
    students.forEach((student) => {
      const inPersonSubject = student.subjects.filter((subject) => {
        return !subject.online_sessions;
      });

      inPersonStudents.push(inPersonSubject);
    });
    return inPersonStudents.flat();
  };

  const buildEmailToTutor = (
    tutorName: string,
    clientName: string,
    clientPhone: string,
    clientEmail: string,
    clientAddress: string,
    clientCity: string,
    students: StudentType[],
  ) => {
    const tutorFirst = tutorName.split(' ')[0];

    const studentsArray = students.map((student) => {
      const studentFirst = student.student_name.split(' ')[0];
      const studentGrade = student.grade;

      return ` ${studentFirst} (${studentGrade})`;
    });

    let subjectsArray: any = [];
    students.forEach((student) => {
      const subjectString = student.subjects.map((subject) => {
        return ` ${subject.subject_grade} ${subject.subject_name}`;
      });
      subjectsArray.push(subjectString);
    });

    // find all students that are not online - if there is anything in the array, include client address in email body
    const inPersonStudents = findInPersonStudents(students);

    let inPersonString = '';

    if (inPersonStudents.length > 0) {
      inPersonString = `Address: ${clientAddress}, ${clientCity}
      `;
    }

    const message = `
Hi ${tutorFirst},

Here's all the client information you might need:
Client name: ${clientName}
Phone: ${clientPhone}
Email: ${clientEmail}
${inPersonString}
Students:${studentsArray}
Subjects:${subjectsArray}

Have a great day!
    `;
    return message;
  };

  const SelectTutor = (tutor: NFTTutor) => {
    setSelectedTutor(tutor);
    updateSMS(tutor);
  };

  const AddSingleTutor = (tutor: NFTTutor) => {
    match.tutors_offered = [tutor, ...tutorsOffered];
    setTutorsOffered((prevState: NFTTutor[]) => {
      return [tutor, ...prevState];
    });
  };

  useEffect(() => {
    setSMSToClient(smsToClient);
  }, [smsToClient]);

  if (willRedirect) {
    return <Redirect to="/admin/matches" />;
  } else {
    return (
      <form
        onSubmit={(event: FormEvent) => {
          event.preventDefault();
        }}
      >
        {editTitle ? (
          <input
            className="title card-title"
            type="text"
            name="title"
            autoFocus
            onBlur={(e) => {
              match.title = e.target.value;
              setEditTitle(false);
            }}
            defaultValue={
              match.title ? match.title : match.client.full_name !== '' ? match.client.full_name : 'Title Here'
            }
            onSubmit={(e: React.ChangeEvent<HTMLInputElement>): void => {
              // e.preventDefault();
              match.title = e.target.value;
              setEditTitle(false);
            }}
            data-cy="match_form--edit_title"
          ></input>
        ) : (
          <h2
            onClick={() => {
              setEditTitle(true);
            }}
            data-cy="match_form--set_edit_title"
          >
            {match.title ? match.title : match.client.full_name !== '' ? match.client.full_name : 'Title Here'}
          </h2>
        )}
        <section className="row">
          <div>
            <label>Assign Match</label>
            <Dropdown
              refer={updateColumnRef}
              items={[
                { label: 'Free', value: AdminLeadsFilterColumn.Free },
                { label: 'Matt', value: AdminLeadsFilterColumn.Matt },
                { label: 'Suhail', value: AdminLeadsFilterColumn.Suhail },
                { label: 'Ruth', value: AdminLeadsFilterColumn.Ruth },
              ]}
              value={match.column}
              onChange={() => {
                match.column = updateColumnRef.current!.value;
              }}
            />
          </div>
        </section>

        <ClientInMatch client={match.client} />

        <section className="student-details">
          <div className="">
            {match.students.map((student, index) => {
              return (
                <React.Fragment key={index}>
                  <StudentInMatch student={student} />
                </React.Fragment>
              );
            })}
          </div>
        </section>

        <section id="TUTORS_OFFERED" className="form-container-border tutor-container-border">
          <SearchSingleTutorInput addTutor={AddSingleTutor} country={match.client.country} />
          {/* <FindTutors /> */}

          {tutorsOffered.map((tutor) => {
            return (
              <div key={tutor.tutor_id}>
                <RenderTutorInMatch tutorOffered={tutor} SelectTutor={SelectTutor} />
              </div>
            );
          })}
        </section>

        <section className="clientSMS default-margin-bottom">
          {clientSMSError ? (
            <ErrorHandler
              handler={() => {
                sendSmsRef.current!.removeAttribute('disabled');
                setClientSMSError(false);
              }}
              type="error"
              error="There was an issue sending the SMS. Please try again."
            />
          ) : null}
          {clientSMSSuccess ? (
            <ErrorHandler
              handler={() => {
                setClientSMSSuccess(false);
              }}
              type="success"
              message="Successfully Sent SMS!"
            />
          ) : null}

          <div>
            <label>Client SMS:</label>
            <textarea
              className="sms"
              rows={4}
              name="smsToClient"
              defaultValue={smsToClient}
              onChange={changeSMStoClient}
            ></textarea>
          </div>
          <div className="add-button-container">
            <button
              className="default-margin-top"
              ref={sendSmsRef}
              onClick={async () => {
                if (sendSmsRef.current) {
                  sendSmsRef.current.setAttribute('disabled', 'true');
                }
                const returnValue = await SendSMS(match.client.phone_number, smsToClient, match.client.state_province);
                if (returnValue) {
                  setClientSMSSuccess(true);
                  let [associated_phone, twilio_number, body, dateSent, direction] = [
                    `+1${match.client.phone_number}`,
                    '+16469069648',
                    smsToClient,
                    new Date(Date.now()),
                    'outbound-api',
                  ];
                  socket.emit('updateCache', {
                    newMessage: {
                      associated_phone,
                      twilio_number,
                      transport_data: {
                        body,
                        dateSent,
                        direction,
                        from: twilio_number,
                        to: associated_phone,
                      },
                    },
                    contInfo: null,
                    presistActiveContacts: true,
                  });
                } else {
                  setClientSMSError(true);
                }
              }}
            >
              Send Client SMS
            </button>
          </div>
        </section>

        <section id="EXTRA_FIELDS" className="match-client-margin">
          <div>
            <label>Match Notes</label>
            <textarea
              ref={notesRef}
              rows={5}
              defaultValue={match.notes}
              onBlur={() => {
                match.notes = notesRef.current!.value;
              }}
            />
          </div>
        </section>

        {saveMatchError ? (
          <ErrorHandler
            handler={() => {
              saveMatchRef.current!.removeAttribute('disabled');
              setSaveMatchError(false);
            }}
            type="error"
            error="There was an issue saving the Match. Please try again"
          />
        ) : null}
        {saveMatchSuccess ? (
          <ErrorHandler
            handler={() => {
              setSaveMatchSuccess(false);
              setWillRedirect(true);
            }}
            type="success"
            error="The match was successfully saved! Click here to return to Matching page"
          />
        ) : null}
        {bookAppointmentError ? (
          <ErrorHandler
            handler={() => {
              bookAppointmentRef.current!.removeAttribute('disabled');
              setBookAppointmentError(false);
            }}
            type="error"
            error="There was an issue booking the appointment. Please try again"
          />
        ) : null}
        {bookAppointmentSuccess ? (
          <ErrorHandler
            handler={() => {
              setBookAppointmentSuccess(false);
              setWillRedirect(true);
            }}
            type="success"
            error="The appointment was successfully booked! Click here to return to Matching page"
          />
        ) : null}

        <section className="save-buttons">
          {match._id ? <DeleteLead id={Number(match._id)} lead={match} /> : null}

          <div className="absolute-btn flex">
            <button
              ref={saveMatchRef}
              className="save align-right input-margin-left"
              onClick={async () => {
                if (saveMatchRef.current) {
                  saveMatchRef.current.setAttribute('disabled', 'true');
                }

                try {
                  update(match).then(() => {
                    setSaveMatchSuccess(true);
                  });
                } catch (err) {
                  setSaveMatchError(true);
                }
              }}
            >
              Save Match
            </button>
            {selectedTutor !== null ? (
              <button
                ref={bookAppointmentRef}
                className="reverse-save input-margin-left"
                onClick={async () => {
                  if (bookAppointmentRef.current) {
                    bookAppointmentRef.current.setAttribute('disabled', 'true');
                  }
                  try {
                    let postData = {
                      charge_rate: 30.0,
                      tutor_wage: 20.0,
                      tutor_id: selectedTutor.tutor_id,
                    };
                    let postRequest = await postEntireContract(match, postData);
                    if (postRequest === 200) {
                      EmailResourcesToClient(match);

                      // deleteMatchFromMongo(match);
                      deleteFromMongo(match);
                      setBookAppointmentSuccess(true);

                      // build and send email to tutor with client information
                      const emailToTutor = buildEmailToTutor(
                        selectedTutor.tutor_name,
                        match.client.full_name,
                        match.client.phone_number,
                        match.client.email,
                        match.client.street_address,
                        match.client.city,
                        match.students,
                      );
                      SendEmail(emailToTutor, selectedTutor.tutor_email, `Your Tutoring Client Information`);

                      // build and send email to client with tutor information
                      const emailToClient = buildEmailToClient(
                        match.client.full_name,
                        selectedTutor.tutor_name,
                        selectedTutor.tutor_phone,
                        selectedTutor.tutor_email,
                      );
                      SendEmail(emailToClient, match.client.email, `Your Tutor's Information`);
                    }
                  } catch (err) {
                    setBookAppointmentError(true);
                  }
                }}
                data-cy="match_save--appointment"
              >
                Book Appointment
              </button>
            ) : null}
          </div>
        </section>
      </form>
    );
  }
};

export default LeadsForm;
