import React, { useState, useRef, useEffect } from 'react';
import { Subject, StudentType } from '../../Models';
import Dropdown from '../Partials/Dropdown';
import ErrorHandler from '../Partials/ErrorHandler';
import { grades, subjectListArray } from '../../Models/SelectData';

import { useSubjectData } from '../Hooks/Lead/useSubjectData';
import { useDisplayData } from '../Hooks/useDisplayData';

type RenderSubjectsProps = {
  subject: Subject;
  student: StudentType;
  SetSubjectLength: (value: number) => void;
};

export const RenderSubjects: React.FC<RenderSubjectsProps> = ({ subject, student, SetSubjectLength }) => {
  const {
    handleSubjectInputChange,
    handleSubjectSelect,
    handleSubjectTextChange,
    toggleSubjectInputs,
  } = useSubjectData();

  const { displayData, ConfirmSubjectDelete, selectSubject } = useDisplayData();

  // state
  const [isOnline, setIsOnline] = useState(subject.online_sessions);
  const [needsResources, setNeedsResources] = useState(subject.resources_required);
  const [renderSubjectChange, setRenderSubjectChange] = useState(false);
  const [renderGradeChange, setRenderGradeChange] = useState(false);
  const [renderPriceChange, setRenderPriceChange] = useState(false);
  const [renderAppointmentChange, setRenderAppointmentChange] = useState(false);
  const [renderSkillsChange, setRenderSkillsChange] = useState(false);

  // refs
  const subjectRef = useRef<HTMLSelectElement>(null);
  const gradeRef = useRef<HTMLSelectElement>(null);
  const sessionPriceRef = useRef<HTMLInputElement>(null);
  const appointmentTimeRef = useRef<HTMLTextAreaElement>(null);
  const skillsAndGoalsRef = useRef<HTMLTextAreaElement>(null);

  // useEffect(() => {
  //   if (renderSubjectChange && subjectRef.current) {
  //     subjectRef.current.focus();
  //   }

  //   if (renderGradeChange && gradeRef.current) {
  //     gradeRef.current.focus();
  //   }

  //   if (renderPriceChange && sessionPriceRef.current) {
  //     sessionPriceRef.current.focus();
  //   }

  //   if (renderAppointmentChange && appointmentTimeRef.current) {
  //     appointmentTimeRef.current.focus();
  //   }

  //   if (renderSkillsChange && skillsAndGoalsRef.current) {
  //     skillsAndGoalsRef.current.focus();
  //   }
  // }, [renderSubjectChange, renderGradeChange, renderPriceChange, renderAppointmentChange, renderSkillsChange]);

  const removeSubject = () => {
    student.subjects.splice(student.subjects.indexOf(subject), 1);
    SetSubjectLength(student.subjects.length);
  };

  return (
    <section className="default-margin-bottom">
      {/* {displayData.selectedSubject === subject && displayData.confirmSubjectDelete ? (
        <div className="flex-column white-background default-margin-bottom">
          <ErrorHandler
            message={`Are you sure you want to delete ${subject.subject_grade} ${subject.subject_name}?`}
            type="warning"
            icon="fas fa-exclamation-triangle"
          />
          <div className="warning-buttons center">
            <button
              className="delete-btn"
              onClick={() => {
                removeSubject();
                ConfirmSubjectDelete(false);
              }}
              data-cy="subject_remove--confirm"
            >
              DELETE
            </button>
            <button
              className="save"
              onClick={() => {
                ConfirmSubjectDelete(false);
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      ) : null} */}
      <div className="heading">
        <div className="left-heading">
          <h3>{subject.subject_name}</h3>
        </div>
        <span
          className={
            displayData.confirmStudentDelete || displayData.confirmSubjectDelete ? 'delete gray-c-font' : 'delete'
          }
          onClick={() => {
            // ConfirmSubjectDelete(true);
            // selectSubject(subject);
            removeSubject();
          }}
          data-cy="subject_remove"
        >
          Remove Subject
        </span>
      </div>
      <section className="row subject-inputs">
        <div>
          <label>Subject</label>
          {/* {renderSubjectChange ? ( */}
          <Dropdown
            refer={subjectRef}
            name="subject_name"
            items={subjectListArray}
            value={subject.subject_name ? subject.subject_name : 'English'}
            onChange={(e) => {
              handleSubjectSelect(e, student, subject);
              // force update state to render appropriate subject title
              // setSubjectName(subjectRef.current!.value);
            }}
            // onBlur={() => {
            //   setRenderSubjectChange(false);
            // }}
          />
          {/* // ) : (
          //   <p
          //     onClick={() => {
          //       setRenderSubjectChange(true);
          //     }}
          //   >
          //     {subject.subject_name}
          //   </p>
          // )}

          {/* {displayData.confirmStudentDelete || displayData.confirmSubjectDelete ? (
            <p>{subject.subject_name}</p>
          ) : (
            <Dropdown
              refer={subjectRef}
              name="subject_name"
              items={subjectListArray}
              value={subject.subject_name ? subject.subject_name : 'English'}
              onChange={(e) => {
                handleSubjectSelect(e, student, subject);
                // force update state to render appropriate subject title
                // setSubjectName(subjectRef.current!.value);
              }}
              onBlur={() => {
                setRenderSubjectChange(false);
              }}
            />
          )} */}
        </div>

        <div>
          <label>Subject Grade</label>
          {/* {renderGradeChange ? ( */}
          <Dropdown
            refer={gradeRef}
            name="subject_grade"
            items={grades}
            defaultValue={student.grade}
            value={student.grade}
            onChange={(e) => {
              handleSubjectSelect(e, student, subject);
              if (
                gradeRef.current!.value === 'Grade 9' ||
                gradeRef.current!.value === 'Grade 10' ||
                gradeRef.current!.value === 'Grade 11' ||
                gradeRef.current!.value === 'Grade 12'
              ) {
                subject.subject_price = 40;
              } else {
                subject.subject_price = 30;
              }
            }}
            onBlur={() => {
              setRenderGradeChange(false);
            }}
          />
          {/* ) : (
            <p
              onClick={() => {
                setRenderGradeChange(true);
              }}
            >
              {subject.subject_grade}
            </p>
          )} */}
        </div>
        <div>
          <label>Price per hour: $</label>
          {/* {renderPriceChange ? ( */}
          <input
            ref={sessionPriceRef}
            name="subject_price"
            type="number"
            min={15}
            max={100}
            defaultValue={subject.subject_price}
            onChange={(e) => {
              handleSubjectInputChange(e, student, subject);
            }}
            onBlur={() => {
              setRenderPriceChange(false);
            }}
          />
          {/* ) : (
            <p
              onClick={() => {
                setRenderPriceChange(true);
              }}
            >
              {subject.subject_price}
            </p>
          )} */}
        </div>
      </section>
      <section className="row appointments">
        <div>
          <label>Appointment Dates and Times</label>
          {/* {renderAppointmentChange ? ( */}
          <textarea
            className="student-input-width"
            name="appointment_times"
            rows={5}
            defaultValue={subject.appointment_times}
            onBlur={(e) => {
              handleSubjectTextChange(e, student, subject);
              setRenderAppointmentChange(false);
            }}
            ref={appointmentTimeRef}
          />
          {/* ) : (
            <div
              className="subject-text"
              onClick={() => {
                setRenderAppointmentChange(true);
              }}
            >
              {subject.appointment_times}
            </div>
          )} */}
        </div>
        <div>
          <label>Skill Level and Goals</label>
          {/* {renderSkillsChange ? ( */}
          <textarea
            className="student-input-width"
            name="skills_and_goals"
            rows={5}
            defaultValue={subject.skills_and_goals}
            onBlur={(e) => {
              handleSubjectTextChange(e, student, subject);
              setRenderSkillsChange(false);
            }}
            ref={skillsAndGoalsRef}
          />
          {/* ) : (
            <div
              className="subject-text"
              onClick={() => {
                setRenderSkillsChange(true);
              }}
            >
              {subject.skills_and_goals}
            </div>
          )} */}
        </div>
      </section>
      <section className="checkbox-row">
        <div className="checkbox checkbox-margin">
          {displayData.confirmStudentDelete || displayData.confirmSubjectDelete ? null : (
            <input
              id="isOnline"
              name="online_sessions"
              type="checkbox"
              checked={isOnline}
              onChange={(e) => {
                setIsOnline(!isOnline);
                toggleSubjectInputs(e, student, subject);
              }}
            />
          )}
          <span>Online</span>
        </div>
        <div className="checkbox checkbox-margin">
          {displayData.confirmStudentDelete || displayData.confirmSubjectDelete ? null : (
            <input
              id="needsResources"
              name="resources_required"
              type="checkbox"
              checked={needsResources}
              onChange={(e) => {
                setNeedsResources(!needsResources);
                toggleSubjectInputs(e, student, subject);
              }}
            />
          )}
          <span>Requires Resources</span>
        </div>
      </section>
    </section>
  );
  // }
};
