import React, { useState, useEffect } from 'react';
import { CardData } from '../../Models';

export interface CardContext {
  cards: CardData[];
  filter: string;
}

export interface LeadCardContextProps {
  cardContext: CardContext;
  setCardContext: (data: any) => void;
}

const LeadCardContext = React.createContext({} as LeadCardContextProps);

const LeadCardContextProvider = (props: any) => {
  const [cardContext, setCardContext] = useState<CardContext>({ cards: [], filter: '' });

  const providerValue: LeadCardContextProps = {
    cardContext,
    setCardContext,
  };

  return <LeadCardContext.Provider value={providerValue}>{props.children}</LeadCardContext.Provider>;
};

export { LeadCardContext, LeadCardContextProvider };
