import React, { useState } from 'react';
//Types
import { LeadType, AdminLeadsFilterColumn, AdminLeadsFilterStatus } from '../../Models';

export interface LeadContextProps {
  // lead: Leads | null;
  // setLead: (lead: any) => void;
  lead: LeadType;
  setLead: (lead: any) => void;
}

const LeadContext = React.createContext({} as LeadContextProps);

const LeadContextProvider = (props: any) => {
  // const [lead, setLead] = useState<Leads | null>(null);
  const [lead, setLead] = useState<LeadType>({
    status: AdminLeadsFilterStatus.Lead,
    column: AdminLeadsFilterColumn.NewLead,
    client: {
      full_name: '',
      phone_number: '',
      email: '',
      street_address: '',
      city: '',
      state_province: '',
      country: 'USA',
      postcode: '',
      major_intersection: '',
      isStudent: false,
    },
    students: [],
    notes: '',
    labels: [],
    contracts: [],
    timestamp: new Date(),
    lead_form_type: '',
    id: +(Math.random() * 1000000).toFixed(0),
    title: '',
    number_of_subjects: 0,
  });
  const providerValue: LeadContextProps = {
    lead,
    setLead,
  };

  return <LeadContext.Provider value={providerValue}>{props.children}</LeadContext.Provider>;
};

export { LeadContext, LeadContextProvider };
