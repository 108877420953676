export const DateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  timeZone: 'Canada/Eastern',
  hour: 'numeric',
  minute: 'numeric',
};

export const CondensedDateOptions = {
  month: 'short',
  day: '2-digit',
};

export const OverYearDateOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

export const TimeOptions = {
  timeZone: 'Canada/Eastern',
  hour: 'numeric',
  minute: 'numeric',
};
