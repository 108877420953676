import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { WorksheetsTextSegment } from '../../Models';
import ErrorHandler from '../Partials/ErrorHandler';

type UploadListSegmentProps = {
  UpdateTextBlock: (segment: WorksheetsTextSegment) => void;
};

const UploadListSegment: React.FC<UploadListSegmentProps> = ({ UpdateTextBlock }) => {
  const [hasErrorAppendingSegment, setHasErrorAppendingSegment] = useState(false);
  const [hasErrorWithInput, setHasErrorWithInput] = useState(false);
  const [segmentList, setSegmentList] = useState<string[]>([]);
  const ListInputRef = useRef<HTMLTextAreaElement>(null);

  const AppendSegment = () => {
    if (segmentList.length > 0) {
      UpdateTextBlock({
        type: 'list',
        content: segmentList,
      });
      setSegmentList([]);
    } else {
      setHasErrorAppendingSegment(true);
    }
  };

  const AddTextToList = () => {
    if (ListInputRef.current && ListInputRef.current.value !== '') {
      let inputValue = ListInputRef.current.value;
      setSegmentList((prev: string[]) => [...prev, inputValue]);
      ListInputRef.current.value = '';
    } else {
      setHasErrorWithInput(true);
    }
  };

  const PREVIEW_LIST = segmentList.map((listItem) => {
    return (
      <React.Fragment key={uuidv4()}>
        <p>- {listItem}</p>
      </React.Fragment>
    );
  });

  return (
    <section>
      {hasErrorWithInput && (
        <ErrorHandler
          type="error"
          message="Invalid list item entered, please try again or contact the tech team."
          handler={() => {
            setHasErrorWithInput(false);
          }}
        />
      )}
      <div>
        <label>New List Item Text: </label>
        <textarea ref={ListInputRef} placeholder="Paragraph text..." />
      </div>
      <button onClick={AddTextToList}>Add To List</button>

      <div>
        <p>List Preview: </p>
        {PREVIEW_LIST}
      </div>

      {hasErrorAppendingSegment && (
        <ErrorHandler
          type="error"
          message="Invalid list entered, please try again or contact the tech team."
          handler={() => {
            setHasErrorAppendingSegment(false);
          }}
        />
      )}

      {segmentList.length > 0 && <button onClick={AppendSegment}>Submit List</button>}
    </section>
  );
};

export default UploadListSegment;
