import { ContactInfo } from '../Models';
import { GetBaseURL } from '../Utils';

export const FindContactByPhone = async (phone: string) => {
  const request = await fetch(`${GetBaseURL()}/api/contacts/single?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`, {
    method: 'POST',
    body: JSON.stringify({ phone }),
    headers: { 'Content-Type': 'application/json' },
  });
  if (request.status === 200) {
    let contact: ContactInfo = await request.json();
    return contact;
  } else {
    return null;
  }
};
