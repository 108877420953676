import { GetBaseURL } from '../Utils';

export const DragStart = (event: React.DragEvent<HTMLElement>, leadID: string) => {
  event.dataTransfer.setData('lead_id', leadID);
};

export const DragOver = (event: React.DragEvent<HTMLElement>) => {
  event.preventDefault();
  // stop events from firing once dragging
  event.stopPropagation();
};

export const Drop = async (event: React.DragEvent<HTMLElement>, column: string) => {
  event.preventDefault();
  const lead_id = event.dataTransfer.getData('lead_id');

  //!Fetch request to Server endpoint
  let requestObject = {
    id: lead_id,
    column,
  };

  let postRequest = await fetch(`${GetBaseURL()}/api/leads/changeColumn`, {
    method: 'POST',
    body: JSON.stringify(requestObject),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (postRequest.status === 200) {
    //todo: Add hook in that will take value from localstorace as well as lead_id and update the local version of it.
    return { status: 200, message: '', id: lead_id };
  } else {
    let responseJSON = await postRequest.json();
    return { status: 400, message: responseJSON.error, id: lead_id };
  }
};
