export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const FormatName = (str: string) => {
  const formatName = str
    .split(' ')
    .map((name) => capitalize(name))
    .join(' ');
  return formatName;
};

//! returns true if valid
export const ValidateEmail = (email: string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

//! returns user input as numeric values only
export const NumbersOnly = (str: string) => {
  return str
    .trim()
    .replace(/[^0-9]+/g, '')
    .replace(/\s/g, '');
};

// ! format phone number for display in app
export const FormatPhoneNumber = (str: string) => {
  const cleaned = ('' + str).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

// ! restricts user input to alpha numeric
export const RemoveSpecialChars = (str: string) => {
  return str
    .trim()
    .replace(/[^a-zA-Z 0-9]+/g, '')
    .replace(/\s/g, '');
};

// ! parse wage field from tutor cruncher
export const ParseWage = (wage: string) => {
  const newWage = wage.replace('$', '').replace(/\s/g, '').split('-')[0];
  return newWage;
};

// ! returns array of first and last name (or all names)
export const SplitName = (name: string) => {
  return name.split(' ');
};

//! returns true if valid US number
export const ValidatePhone = (phone: string) => {
  let numToCheck = NumbersOnly(phone);

  if (parseInt(numToCheck[0]) === 1) {
    numToCheck = numToCheck.substring(1);
  }

  return numToCheck.length === 10 ? true : false;
};
