export const renderClassname = (label: string) => {
  // deconstruct label to create classname based on subject and grade level
  const deconstructed = [label.split('/')[1], label.split('/')[2].replace(/\s/g, '')];

  let deconstructedSubject = deconstructed[0].split(' ').join('');

  if (
    deconstructedSubject.includes('French') ||
    deconstructedSubject.includes('Fr.Immersion') ||
    deconstructedSubject.includes('Italian') ||
    deconstructedSubject.includes('Spanish')
  ) {
    deconstructedSubject = 'french';
  }

  if (
    deconstructedSubject.includes('sci') ||
    deconstructedSubject.includes('Physics') ||
    deconstructedSubject.includes('Chem') ||
    deconstructedSubject.includes('Bio') ||
    deconstructedSubject.includes('Econ')
  ) {
    deconstructedSubject = 'science';
  }

  if (
    deconstructedSubject.includes('Calculus') ||
    deconstructedSubject.includes('Adv.Func') ||
    deconstructedSubject.includes('Functions') ||
    deconstructedSubject.includes('DataMgmt') ||
    deconstructedSubject.includes('DataManagement') ||
    deconstructedSubject.includes('Accounting')
  ) {
    deconstructedSubject = 'math';
  }

  let deconstructedGrade = deconstructed[1].split('Gr.')[1];

  if (deconstructed[1] === 'KG' || deconstructed[1] === 'JK') {
    deconstructedGrade = 'Kindergarten';
  }

  // className renders as [subject]-[grade level]; ex. math-3
  let result = `label ${deconstructedSubject}-${deconstructedGrade}`.toLowerCase();

  return result;
};
