import React, { useState } from 'react';
import { Contract } from '../../Models';

export interface ContractContextProps {
  contractData: Contract;
  setContractData: (ContractData: Contract) => void;
}

const ContractContext = React.createContext({} as ContractContextProps);

const ContractContextProvider = (props: any) => {
  const [contractData, setContractData] = useState<Contract>({
    subjects: [],
    tutors_by_location: [],
    tutors_by_subject: [],
    selectedTutors: [],
  });

  const providerValue: ContractContextProps = {
    contractData,
    setContractData,
  };

  return <ContractContext.Provider value={providerValue}>{props.children}</ContractContext.Provider>;
};

export { ContractContext, ContractContextProvider };
