import { useContext } from 'react';
import { LeadContext } from '../../Context/LeadContext';
import { LeadType, StudentType, Subject, StudentGrades } from '../../../Models';

export const useSubjectData = () => {
  const { lead, setLead } = useContext(LeadContext);
  // <-------------------- input functions -------------------->
  const handleSubjectInputChange = (e: React.ChangeEvent<HTMLInputElement>, student: StudentType, subject: Subject) => {
    e.persist();
    let updatedSubject: Subject = subject;
    let targetInput: keyof Subject = e.target.name! as keyof Subject;

    switch (targetInput) {
      case 'subject_price':
        updatedSubject.subject_price = parseInt(e.target.value);
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      let updatedStudent: StudentType = updatedLead.students[updatedLead.students.indexOf(student)];

      if (updatedSubject !== null) {
        updatedStudent.subjects[updatedStudent.subjects.indexOf(subject)] = updatedSubject;
      }
      return updatedLead;
    });
  };

  const handleSubjectSelect = (e: React.ChangeEvent<HTMLSelectElement>, student: StudentType, subject: Subject) => {
    e.persist();
    let updatedSubject: Subject = subject;
    let targetInput: keyof Subject = e.target.name! as keyof Subject;

    switch (targetInput) {
      case 'subject_name':
        updatedSubject.subject_name = e.target.value;
        break;
      case 'subject_grade':
        updatedSubject.subject_grade = e.target.value;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      let updatedStudent: StudentType = updatedLead.students[updatedLead.students.indexOf(student)];

      if (updatedSubject !== null) {
        updatedStudent.subjects[updatedStudent.subjects.indexOf(subject)] = updatedSubject;
      }
      return updatedLead;
    });
  };

  const handleSubjectTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    student: StudentType,
    subject: Subject,
  ) => {
    e.persist();
    let updatedSubject: Subject = subject;
    let targetInput: keyof Subject = e.target.name! as keyof Subject;

    switch (targetInput) {
      case 'appointment_times':
        updatedSubject.appointment_times = e.target.value;
        break;
      case 'skills_and_goals':
        updatedSubject.skills_and_goals = e.target.value;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      let updatedStudent: StudentType = updatedLead.students[updatedLead.students.indexOf(student)];

      if (updatedSubject !== null) {
        updatedStudent.subjects[updatedStudent.subjects.indexOf(subject)] = updatedSubject;
      }
      return updatedLead;
    });
  };

  const toggleSubjectInputs = (e: React.ChangeEvent<HTMLInputElement>, student: StudentType, subject: Subject) => {
    e.persist();
    let updatedSubject: Subject = subject;
    let targetInput: keyof Subject = e.target.name! as keyof Subject;

    switch (targetInput) {
      case 'online_sessions':
        updatedSubject.online_sessions = !updatedSubject.online_sessions;
        break;
      case 'resources_required':
        updatedSubject.resources_required = !updatedSubject.resources_required;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      let updatedStudent: StudentType = updatedLead.students[updatedLead.students.indexOf(student)];

      if (updatedSubject !== null) {
        updatedStudent.subjects[updatedStudent.subjects.indexOf(subject)] = updatedSubject;
      }
      return updatedLead;
    });
  };

  const getNumberOfSubjects = () => {
    let numberOfSubjects = 0;
    lead!.students.forEach((student) => {
      // lead.students.forEach((student) => {
      numberOfSubjects = numberOfSubjects + student.subjects.length;
    });

    return numberOfSubjects;
  };

  const renderLabel = (subject: Subject) => {
    let renderGrade;
    if (subject.subject_grade === StudentGrades.Kindergarten) {
      renderGrade = 'KG';
    } else if (subject.subject_grade === StudentGrades.Preschool) {
      renderGrade = 'PS';
    } else {
      renderGrade = `Gr. ${subject.subject_grade.split(' ')[1]}`;
    }

    return `${subject.student.split(' ')[0]}/${subject.subject_name}/${renderGrade}`;
  };

  const returnSubjectGradeAndPrice = (subject: Subject) => {
    return `${subject.subject_name} -- ${subject.subject_grade} -- $${subject.subject_price}`;
  };

  return {
    handleSubjectInputChange,
    handleSubjectSelect,
    handleSubjectTextChange,
    toggleSubjectInputs,
    getNumberOfSubjects,
    renderLabel,
    returnSubjectGradeAndPrice,
  };
};
