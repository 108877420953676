import { NFTSubjectList } from './SubjectTypes';
import { AdminLeadsFilterColumn, StudentGrades } from '../Models';
import { Admin } from './UserTypes';
// all data for Dropdowns are arrays of objects, with the template: [ { label: 'foo', value: 'bar' }, { label: 'foo1', value: 'bar1' } ]

export type SelectAdmin = {
  label: string;
  value: Admin;
};

export const columns = [
  { label: AdminLeadsFilterColumn.NewLead, value: AdminLeadsFilterColumn.NewLead },
  { label: AdminLeadsFilterColumn.Contacted, value: AdminLeadsFilterColumn.Contacted },
  { label: AdminLeadsFilterColumn.UnableToContact, value: AdminLeadsFilterColumn.UnableToContact },
];

export const countries = [
  { label: 'Select One', value: '' },
  { label: 'USA', value: 'USA' },
  { label: 'Canada', value: 'Canada' },
];

export const states = [
  { label: 'Select One', value: 'Select One' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  // Canada
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'Newfoundland & Labrador', value: 'NL' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon', value: 'YT' },
];

export const provinces = [
  { label: 'Select One', value: 'Select One' },
  { label: 'Alberta', value: 'AB' },
  { label: 'British Columbia', value: 'BC' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'Newfoundland & Labrador', value: 'NL' },
  { label: 'Nova Scotia', value: 'NS' },
  { label: 'Northwest Territories', value: 'NT' },
  { label: 'Nunavut', value: 'NU' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Prince Edward Island', value: 'PE' },
  { label: 'Quebec', value: 'QC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Yukon', value: 'YT' },
];

export const grades = [
  { label: 'Junior Kindergarten', value: 'Junior Kindergarten' },
  { label: 'Kindergarten', value: 'Kindergarten' },
  { label: 'Grade 1', value: 'Grade 1' },
  { label: 'Grade 2', value: 'Grade 2' },
  { label: 'Grade 3', value: 'Grade 3' },
  { label: 'Grade 4', value: 'Grade 4' },
  { label: 'Grade 5', value: 'Grade 5' },
  { label: 'Grade 6', value: 'Grade 6' },
  { label: 'Grade 7', value: 'Grade 7' },
  { label: 'Grade 8', value: 'Grade 8' },
  { label: 'Grade 9', value: 'Grade 9' },
  { label: 'Grade 10', value: 'Grade 10' },
  { label: 'Grade 11', value: 'Grade 11' },
  { label: 'Grade 12', value: 'Grade 12' },
  { label: 'College/University', value: 'College/University' },
];

export const studentGrades = [
  { label: 'Select One', value: '' },
  { label: 'Preschool', value: StudentGrades.Preschool },
  { label: 'Kindergarten', value: StudentGrades.Kindergarten },
  { label: 'Grade 1', value: StudentGrades.Grade1 },
  { label: 'Grade 2', value: StudentGrades.Grade2 },
  { label: 'Grade 3', value: StudentGrades.Grade3 },
  { label: 'Grade 4', value: StudentGrades.Grade4 },
  { label: 'Grade 5', value: StudentGrades.Grade5 },
  { label: 'Grade 6', value: StudentGrades.Grade6 },
  { label: 'Grade 7', value: StudentGrades.Grade7 },
  { label: 'Grade 8', value: StudentGrades.Grade8 },
  { label: 'Grade 9', value: StudentGrades.Grade9 },
  { label: 'Grade 10', value: StudentGrades.Grade10 },
  { label: 'Grade 11', value: StudentGrades.Grade11 },
  { label: 'Grade 12', value: StudentGrades.Grade12 },
  { label: 'College/University', value: StudentGrades['College/University'] },
];

// adopted array list from NFTSubjectList
export const subjectListArray = Object.entries(NFTSubjectList).map((subject) => {
  return { label: subject[1], value: subject[0] };
});

export const leadSources = [
  { label: 'Select One', value: '' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Google', value: 'Google' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'OneLocal', value: 'OneLocal' },
  { label: 'NFT - Website', value: 'NFT - Website' },
  { label: 'Organic', value: 'Organic' },
  { label: 'Referral', value: 'Referral' },
];

export const leadFormType = [
  { label: 'Select One', value: '' },
  { label: 'Home Page', value: 'Home Page' },
  { label: 'Math Page', value: 'Math Page' },
  { label: 'English Page', value: 'English Page' },
  { label: 'Reading Page', value: 'Reading Page' },
  { label: 'Writing Page', value: 'Writing Page' },
  { label: 'Science Page', value: 'Science Page' },
  { label: 'Language Page', value: 'Language Page' },
  { label: 'Test Prep Page', value: 'Test Prep Page' },
  { label: 'No Fuss Tutors Blog', value: 'No Fuss Tutors Blog' },
  { label: 'Social Blue', value: 'Social Blue' },
];

export const twilioNumbers = [
  { label: 'New York', value: '+16469069648' },
  { label: 'California', value: '+12133377441' },
  { label: 'Florida', value: '+13055905573' },
  { label: 'Toronto', value: '+16473706323' },
];
