//Dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { useUserData } from './Hooks/useUserData';
import Logo from '../assets/home.png';

const AdminNav: React.FC = () => {
  const { userData } = useUserData();

  return (
    <nav>
      <div className="logo">
        <img src={Logo} alt="No Fuss Tutors logo"></img>
      </div>
      <Link className="link" to="/admin">
        <i className="fas fa-circle"></i>Home
      </Link>
      <Link className="link" to="/admin/leads">
        <i className="fas fa-circle"></i>Leads
      </Link>
      <Link className="link" to="/admin/matches">
        <i className="fas fa-circle"></i>Matches
      </Link>
      <Link className="link" to="/admin/readingPortalUsers">
        <i className="fas fa-circle"></i>Reading Portal Users
      </Link>
      <Link className="link" to="/admin/worksheetsUsers">
        <i className="fas fa-circle"></i>Worksheets Users
      </Link>
      <Link className="link" to="/admin/imageCrop">
        <i className="fas fa-circle"></i>Image Crop
      </Link>
      {/* <Link className="link" to="/admin/messages">
        <i className="fas fa-circle"></i>Messages
      </Link> */}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/resourceHub">
          <i className="fas fa-circle"></i>Resource Upload
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/singleWorksheet/TextBlock">
          <i className="fas fa-circle"></i>Resource Text Update
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/filterURL/TextBlock">
          <i className="fas fa-circle"></i>Worksheet URL Text Update
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/story">
          <i className="fas fa-circle"></i>Story Upload
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/questions">
          <i className="fas fa-circle"></i>Questions Upload
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/gameQuestions">
          <i className="fas fa-circle"></i>Game Questions Upload
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/phonics">
          <i className="fas fa-circle"></i>Phonics Upload
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/words">
          <i className="fas fa-circle"></i>Words Upload
        </Link>
      )}
      {userData.currentUser.security_groups && userData.currentUser.security_groups.includes('resourceHub_form') && (
        <Link className="link" to="/admin/formUpload/curricula">
          <i className="fas fa-circle"></i>Curriculum Upload
        </Link>
      )}
    </nav>
  );
};

export default AdminNav;
