import React, { useState, useRef } from 'react';
import { LeadType } from '../../Models';
import { FindCookieValue, SendEmail } from '../../Utils';

type UrgentHireProps = {
  lead: LeadType;
};

export const UrgentHire: React.FC<UrgentHireProps> = ({ lead }) => {
  const [urgentHire, setUrgentHire] = useState(false);
  const urgentHireRef = useRef<HTMLInputElement>(null);
  const subjectsRef = useRef<HTMLInputElement>(null);

  const SendUrgentEmail = (lead: LeadType, subjectsNeeded: string) => {
    const EMAIL_MESSAGE = `A client, ${lead.client.full_name}, is looking for a tutor for ${subjectsNeeded}. They live close to ${lead.client.major_intersection}.`;

    //Need a post request to send this email to admins
    const EMAIL_TARGET = FindCookieValue('email');
    SendEmail(EMAIL_MESSAGE, EMAIL_TARGET! as string, 'Urgent Hire Alert!');
  };

  if (!urgentHire) {
    return (
      <div className="checkbox checkbox-margin urgent-margin-bottom">
        <input
          ref={urgentHireRef}
          type="checkbox"
          onClick={() => {
            setUrgentHire(urgentHireRef.current!.checked);
          }}
        />
        <span>Urgent Hire?</span>
      </div>
    );
  } else {
    return (
      <>
        <div className="checkbox checkbox-margin label-margin-bottom">
          <input
            ref={urgentHireRef}
            type="checkbox"
            onClick={() => {
              setUrgentHire(urgentHireRef.current!.checked);
            }}
          />
          <span>Urgent Hire?</span>
        </div>
        <div>
          <label>Subject(s) needed</label>
          <div className="subject-alert-container">
            <input ref={subjectsRef} type="text" placeholder="Enter Subject(s)" />
            <button
              className="delete-btn"
              onClick={() => {
                SendUrgentEmail(lead, subjectsRef.current!.value);
              }}
            >
              Send Urgent Hire Alert
            </button>
          </div>
        </div>
      </>
    );
  }
};
