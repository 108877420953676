import React, { useState, useRef, useEffect } from 'react';
import DropDown from '../Components/Partials/Dropdown';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { studentGrades, ErrorProps } from '../Models';
import { GetBaseURL, SendEmail } from '../Utils';

type ResourceUpload = {
  document_type: string;
  document_subject: string[];
  document_grade: string[];
  document_title: string;
  document_description: string;
  document_tags: string[];
  document_subcategories: string[];
};

type DocumentNames = {
  titles: string[];
  pdfFilenames: string[];
  imageFilenames: string[];
};

const ResourceHubUpload: React.FC = () => {
  const [resourceHubDoc, setResourceHubDoc] = useState<ResourceUpload>({
    document_type: 'Worksheets',
    document_subject: [],
    document_grade: [],
    document_title: '',
    document_description: '',
    document_tags: [],
    document_subcategories: [],
  });
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });
  const [willRenderPreview, setWillRenderPreview] = useState(false);
  const [documentNames, setDocumentNames] = useState<DocumentNames>({
    titles: [],
    pdfFilenames: [],
    imageFilenames: [],
  });
  const [willFetchDocumentNames, setWillSetDocumentNames] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const pdfDocRef = useRef<HTMLInputElement>(null);
  const imagePreviewRef = useRef<HTMLInputElement>(null);
  const docTypeRef = useRef<HTMLSelectElement>(null);
  const subjectRef = useRef<HTMLSelectElement>(null);
  const gradeRef = useRef<HTMLSelectElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const subcategoryRef = useRef<HTMLInputElement>(null);
  const tagsRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      willFetchDocumentNames &&
      documentNames.titles.length === 0 &&
      documentNames.pdfFilenames.length === 0 &&
      documentNames.imageFilenames.length === 0
    ) {
      (async () => {
        let requestForTitles = await fetch(
          `${GetBaseURL()}/api/resources/fileNames?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
        );

        let titleData = await requestForTitles.json();
        setDocumentNames((prev: DocumentNames) => ({
          ...titleData,
        }));
        setWillSetDocumentNames(false);
      })();
    }
  }, [willFetchDocumentNames, documentNames]);

  const submitFile = async (ref: React.RefObject<HTMLInputElement>, fileExtension: 'pdf' | 'png') => {
    let returnValue = false;
    if (ref.current) {
      if (ref.current.files === null || ref.current.files.length === 0) {
        console.log('No file submitted');
      } else {
        const FD = new FormData();
        FD.append(resourceHubDoc.document_title, ref.current.files[0]);
        const splitFileName = ref.current.files[0].name.split('.');
        const URL = `${GetBaseURL()}/api/resources/addResourceDocument/${splitFileName[splitFileName.length - 1]}?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`;

        const request = await fetch(URL, {
          body: FD,
          method: 'POST',
        });

        request.status === 200 ? (returnValue = true) : (returnValue = false);
      }
    } else {
      console.log('Not current');
    }

    return returnValue;
  };

  const submitMongoData = async () => {
    let returnValue = false;
    if (imagePreviewRef.current) {
      if (imagePreviewRef.current.files === null || imagePreviewRef.current.files.length === 0) {
        console.log('No file submitted');
      } else {
        const splitFileName = imagePreviewRef.current.files[0].name.split('.');
        const URL = `${GetBaseURL()}/api/resources/addSingleResource/${splitFileName[splitFileName.length - 1]}?key=${
          process.env.REACT_APP_PERSONAL_AUTH_KEY
        }`;
        const request = await fetch(URL, {
          method: 'POST',
          body: JSON.stringify(resourceHubDoc),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        request.status === 200 ? (returnValue = true) : (returnValue = false);
      }
    } else {
      console.log('Not Current');
    }

    return returnValue;
  };

  return (
    <main className="main-container">
      <h1 className="header-title">{willRenderPreview ? 'Confirm Submission' : 'Resource Hub Form'}</h1>

      <form className="resourceUpload">
        <div className="resourceUpload__input-group">
          <label className="resourceUpload__label">PDF Document: </label>
          <input
            ref={pdfDocRef}
            type="file"
            name="pdfDocument"
            id="pdfFileToUpload"
            className="resourceUpload__input"
          />
        </div>

        <div className="resourceUpload__input-group">
          <label className="resourceUpload__label">Image Preview (PNG or JPG): </label>
          <input
            ref={imagePreviewRef}
            type="file"
            name="imagePreview"
            id="imageFileToUpload"
            className="resourceUpload__input"
          />
        </div>

        {willRenderPreview ? (
          <div className="resourceUpload__doc-info-dropdowns">
            <p data-cy="resourceUpload__preview--docType">Document Type: {resourceHubDoc.document_type}</p>
            <p data-cy="resourceUpload__preview--subject">
              Document Subject:{' '}
              {resourceHubDoc.document_subject.map((subject, index) => {
                return ` ${subject}${index < resourceHubDoc.document_subject.length - 1 ? ',' : ''}`;
              })}
            </p>
            <p data-cy="resourceUpload__preview--grade">
              Document Grade:{' '}
              {resourceHubDoc.document_grade.map((grade, index) => {
                return ` ${grade}${index < resourceHubDoc.document_grade.length - 1 ? ',' : ''}`;
              })}
            </p>
            <p data-cy="resourceUpload__preview--title">Document Title: {resourceHubDoc.document_title}</p>
            <p data-cy="resourceUpload__preview--description">
              Document Description: {resourceHubDoc.document_description}
            </p>
            <p data-cy="resourceUpload__preview--subcategories">
              Document Subcategories:
              {resourceHubDoc.document_subcategories.map((category, index) => {
                return ` ${category}${index < resourceHubDoc.document_subcategories.length - 1 ? ',' : ''}`;
              })}
            </p>
            <p data-cy="resourceUpload__preview--tags">
              Document Tags:
              {resourceHubDoc.document_tags.map((tag, index) => {
                return ` ${tag}${index < resourceHubDoc.document_tags.length - 1 ? ',' : ''}`;
              })}
            </p>
          </div>
        ) : (
          <>
            <div className="resourceUpload__select-wrapper">
              <label>Document Type</label>
              <DropDown
                name="resourceUpload__select--docType"
                refer={docTypeRef}
                items={[
                  { label: 'Worksheet', value: 'Worksheets' },
                  { label: 'Assessment', value: 'Assessments' },
                ]}
                value={resourceHubDoc.document_type}
                onChange={() => {}}
                onBlur={() => {
                  if (docTypeRef.current !== null) {
                    setResourceHubDoc((prev: ResourceUpload) => ({
                      ...prev,
                      document_type: docTypeRef.current!.value,
                    }));
                  }
                }}
              />
            </div>

            <div className="resourceUpload__select-wrapper">
              <label>Subject</label>
              <div>
                {resourceHubDoc.document_subject.map((subject, index) => {
                  return (
                    <div>
                      <span key={index}>{subject}</span>{' '}
                      <span
                        onClick={() => {
                          let filteredSubjects = resourceHubDoc.document_grade.filter((string) => {
                            return string !== subject;
                          });

                          setResourceHubDoc((prev: ResourceUpload) => ({
                            ...prev,
                            document_subject: filteredSubjects,
                          }));
                        }}
                      >
                        X
                      </span>
                    </div>
                  );
                })}
              </div>
              <DropDown
                name="resourceUpload__select--subject"
                refer={subjectRef}
                value={resourceHubDoc.document_subject[0]}
                items={[
                  { label: 'Select Type', value: '' },
                  { label: 'Math', value: 'Math' },
                  { label: 'Reading', value: 'Reading' },
                  { label: 'Writing', value: 'Writing' },
                  { label: 'Science', value: 'Science' },
                  { label: 'Art', value: 'Art' },
                  { label: 'College/University', value: 'College/University' },
                ]}
                onChange={() => {}}
                onBlur={() => {
                  if (subjectRef.current !== null) {
                    let subjects = [...resourceHubDoc.document_subject, subjectRef.current!.value];

                    setResourceHubDoc((prev: ResourceUpload) => ({
                      ...prev,
                      document_subject: Array.from(new Set(subjects)),
                    }));
                  }
                }}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Add Subject
              </button>
            </div>

            <div className="resourceUpload__select-wrapper">
              <label>Grade Level</label>
              <div>
                {resourceHubDoc.document_grade.map((grade, index) => {
                  return (
                    <div>
                      <span key={index}>{grade}</span>{' '}
                      <span
                        onClick={() => {
                          let filteredGrades = resourceHubDoc.document_grade.filter((string) => {
                            return string !== grade;
                          });

                          setResourceHubDoc((prev: ResourceUpload) => ({
                            ...prev,
                            document_grade: filteredGrades,
                          }));
                        }}
                      >
                        X
                      </span>
                    </div>
                  );
                })}
              </div>
              <DropDown
                name="resourceUpload__select--grade"
                value={resourceHubDoc.document_grade[0]}
                refer={gradeRef}
                items={studentGrades}
                onChange={() => {}}
                onBlur={() => {
                  if (gradeRef.current !== null) {
                    let grades = [...resourceHubDoc.document_grade, gradeRef.current!.value];

                    setResourceHubDoc((prev: ResourceUpload) => ({
                      ...prev,
                      document_grade: Array.from(new Set(grades)),
                    }));
                  }
                }}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Add Grade
              </button>
            </div>

            <div className="resourceUpload__select-wrapper">
              <label>Document Title</label>
              <input
                data-cy="resourceUpload__input--title"
                ref={titleRef}
                type="text"
                placeholder="Document Title"
                defaultValue={resourceHubDoc.document_title !== '' ? resourceHubDoc.document_title : undefined}
                onBlur={() => {
                  if (titleRef.current !== null) {
                    let documentTitle = `${titleRef.current.value}`;
                    let errors: string[] = [];
                    documentNames.titles.includes(documentTitle) && errors.push('Title');
                    documentNames.pdfFilenames.includes(documentTitle.split(' ').join('')) &&
                      errors.push('PDF File Name');
                    documentNames.imageFilenames.includes(documentTitle.split(' ').join('')) &&
                      errors.push('Image Preview File Name');
                    if (errors.length > 0) {
                      //todo: throw error
                      setError((prev: ErrorProps) => ({
                        ...prev,
                        isActive: true,
                        type: 'error',
                        message: `The provided title will not work because it will create duplicates of the following:${errors.map(
                          (singleError, index) => {
                            return ` ${singleError}`;
                          },
                        )}. Please choose a unique name.`,
                      }));
                    } else if (documentTitle.includes('-' || documentTitle.includes('/'))) {
                      setError((prev: ErrorProps) => ({
                        ...prev,
                        isActive: true,
                        type: 'error',
                        message: `Title is invalid because the use of '-' or '/' is not allowed.`,
                      }));
                    } else {
                      setResourceHubDoc((prev: ResourceUpload) => ({
                        ...prev,
                        document_title: documentTitle,
                      }));
                    }
                  }
                }}
              />
            </div>

            <div className="resourceUpload__select-wrapper">
              <label>Description</label>
              <textarea
                className="resourceUpload__text-area"
                data-cy="resourceUpload__textarea--description"
                ref={descriptionRef}
                placeholder="Document Description"
                defaultValue={
                  resourceHubDoc.document_description !== '' ? resourceHubDoc.document_description : undefined
                }
                onBlur={() => {
                  if (descriptionRef.current !== null) {
                    setResourceHubDoc((prev: ResourceUpload) => ({
                      ...prev,
                      document_description: descriptionRef.current!.value,
                    }));
                  }
                }}
              />
            </div>

            <div className="resourceUpload__subcategories-wrapper">
              <label>Subcategories</label>
              <div>
                {resourceHubDoc.document_subcategories.map((category, index) => {
                  return (
                    <div>
                      <span key={index}>{category}</span>{' '}
                      <span
                        onClick={() => {
                          let filteredCategories = resourceHubDoc.document_subcategories.filter((string) => {
                            return string !== category;
                          });

                          setResourceHubDoc((prev: ResourceUpload) => ({
                            ...prev,
                            document_subcategories: filteredCategories,
                          }));
                        }}
                      >
                        X
                      </span>
                    </div>
                  );
                })}
              </div>
              <div>
                <input
                  ref={subcategoryRef}
                  type="text"
                  placeholder="Subcategories"
                  data-cy="resourceUpload__input--subcategory"
                  className="resourceUpload__subcategory-input"
                />
                <button
                  data-cy="resourceUpload__submit--subcategory"
                  onClick={(e) => {
                    e.preventDefault();
                    if (subcategoryRef.current && subcategoryRef.current.value !== '') {
                      const newCategory = subcategoryRef.current!.value;

                      setResourceHubDoc((prev: ResourceUpload) => ({
                        ...prev,
                        document_subcategories: [...prev.document_subcategories, newCategory],
                      }));
                      subcategoryRef.current!.value = '';
                    }
                  }}
                >
                  Add Subcategory
                </button>
              </div>
            </div>

            <div className="resourceUpload__tags-wrapper">
              <label>Tags</label>
              <div>
                {resourceHubDoc.document_tags.map((tag, index) => {
                  return (
                    <div>
                      <span key={index}>{tag}</span>{' '}
                      <span
                        onClick={() => {
                          let filteredCategories = resourceHubDoc.document_tags.filter((string) => {
                            return string !== tag;
                          });

                          setResourceHubDoc((prev: ResourceUpload) => ({
                            ...prev,
                            document_tags: filteredCategories,
                          }));
                        }}
                      >
                        X
                      </span>
                    </div>
                  );
                })}
              </div>
              <div>
                <input
                  ref={tagsRef}
                  type="text"
                  placeholder="Tags"
                  data-cy="resourceUpload__input--tags"
                  className="resourceUpload__tags-input"
                />
                <button
                  data-cy="resourceUpload__submit--tags"
                  onClick={(e) => {
                    e.preventDefault();
                    if (tagsRef.current && tagsRef.current.value !== '') {
                      const newTag = tagsRef.current!.value;
                      setResourceHubDoc((prev: ResourceUpload) => ({
                        ...prev,
                        document_tags: [...prev.document_tags, newTag],
                      }));
                      tagsRef.current!.value = '';
                    }
                  }}
                >
                  Add Tags
                </button>
              </div>
            </div>
          </>
        )}

        <div>
          {error.isActive && error.message.includes('The following fields require a value but have not been set') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}

          {error.isActive &&
            error.message.includes(
              'The provided title will not work because it will create duplicates of the following',
            ) && (
              <ErrorHandler
                type={error.type}
                error={error.message}
                handler={() => {
                  setError({
                    isActive: false,
                    message: '',
                    type: 'error',
                    redirect: false,
                    icon: '',
                  });
                }}
              />
            )}

          {error.isActive && error.message.includes('Successfully posted') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}

          {error.isActive && error.message.includes('There was an issue with the following submissions') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}

          {error.isActive && error.message.includes('Title is invalid because ') && (
            <ErrorHandler
              type={error.type}
              error={error.message}
              handler={() => {
                setError({
                  isActive: false,
                  message: '',
                  type: 'error',
                  redirect: false,
                  icon: '',
                });
              }}
            />
          )}
        </div>

        <div className="resourceUpload__btn-wrapper">
          {willRenderPreview ? (
            <>
              <button
                data-cy="resourceUpload__button--backToForm"
                onClick={(e) => {
                  e.preventDefault();
                  setWillRenderPreview(false);
                }}
              >
                Back to Form
              </button>
              <button
                className="resourceUpload__form-button"
                data-cy="resourceUpload__button--submitHandler"
                disabled={disableSubmit}
                onClick={async (e) => {
                  e.preventDefault();
                  setDisableSubmit(true);

                  const PDF_SUBMISSION = submitFile(pdfDocRef, 'pdf');
                  const IMAGE_SUBMISSION = submitFile(imagePreviewRef, 'png');
                  const MONGO_SUBMISSION = await submitMongoData();
                  const postRequests = [await PDF_SUBMISSION, await IMAGE_SUBMISSION, await MONGO_SUBMISSION];
                  const hadRequestFail = postRequests.some((requestValue) => {
                    return requestValue === false;
                  });

                  if (hadRequestFail) {
                    //post error message
                    let message = `There was an issue with the following submissions:${postRequests.map(
                      (value, index) => {
                        if (value === false) {
                          return index === 0
                            ? ' PDF Document'
                            : // ? ' PDF Document' + (postRequests[1] === false || postRequests[2] === false ? ',' : '')
                            index === 1
                            ? // ? ' Image Preview' + (postRequests[2] === false ? ',' : '')
                              ' Image Preview'
                            : ' Mongo Data';
                        } else {
                          return '';
                        }
                      },
                    )}.`;

                    setError({
                      isActive: true,
                      message: message,
                      type: 'error',
                      redirect: false,
                      icon: '',
                    });

                    //email Russell with doc names and mongo Data
                    SendEmail(
                      `${message}. Document Title: ${resourceHubDoc.document_title}`,
                      'russell@nofusstutors.com',
                      'Error Posting Resource',
                    );
                  } else {
                    setError({
                      isActive: true,
                      message: `Successfully posted ${resourceHubDoc.document_title} to AWS and Mongo`,
                      type: 'success',
                      redirect: false,
                      icon: '',
                    });
                    //Refresh Form:
                    setResourceHubDoc({
                      document_description: '',
                      document_title: '',
                      document_grade: [],
                      document_subject: [],
                      document_type: '',
                      document_tags: [],
                      document_subcategories: [],
                    });
                    window.location.reload();
                  }

                  //Enable submit button
                  setDisableSubmit(false);
                }}
              >
                Submit
              </button>
            </>
          ) : (
            <>
              <button
                className="resourceUpload__form-button"
                data-cy="resourceUpload__button--confirmDetails"
                onClick={(e) => {
                  e.preventDefault();
                  const hasEmptyField = Object.values(resourceHubDoc).some((value) => {
                    return value === '';
                  });

                  if (error.isActive) {
                    alert('Form Cannot be submitted with errors present');
                  } else if (hasEmptyField) {
                    //Error Handler
                    let errors: string[] = [];
                    resourceHubDoc.document_description.trim() === '' ? errors.push('Description') : (errors = errors);
                    resourceHubDoc.document_grade.length === 0 ? errors.push('Grade') : (errors = errors);
                    resourceHubDoc.document_subject.length === 0 ? errors.push('Subject') : (errors = errors);
                    resourceHubDoc.document_title.trim() === '' ? errors.push('Title') : (errors = errors);
                    resourceHubDoc.document_type.trim() === '' ? errors.push('Document Type') : (errors = errors);

                    setError((prev: ErrorProps) => ({
                      ...prev,
                      isActive: true,
                      type: 'error',
                      message: `The following fields require a value but have not been set:${errors.map(
                        (singleError, index) => {
                          return ` ${singleError}`;
                        },
                      )}.`,
                    }));
                  } else {
                    setWillRenderPreview(true);
                  }
                }}
              >
                Submit
              </button>
              <button
                className="resourceUpload__form-button"
                data-cy="resourceUpload__button--resetForm"
                onClick={(e) => {
                  e.preventDefault();
                  setResourceHubDoc({
                    document_description: '',
                    document_title: '',
                    document_grade: [],
                    document_subject: [],
                    document_type: '',
                    document_tags: [],
                    document_subcategories: [],
                  });

                  docTypeRef.current!.value = '';
                  gradeRef.current!.value = '';
                  subjectRef.current!.value = '';
                  titleRef.current!.value = '';
                  descriptionRef.current!.value = '';
                }}
              >
                Refresh Form
              </button>
            </>
          )}
        </div>
      </form>
    </main>
  );
  // }
};

export default ResourceHubUpload;
