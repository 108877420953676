import React, { useState, useEffect, useRef, useCallback, RefObject } from 'react';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { ErrorProps } from '../Models';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { GetBaseURL } from '../Utils';

export type Question = {
  question: string;
  answer1: {
    answer: string;
    correct: boolean;
  };
  answer2: {
    answer: string;
    correct: boolean;
  };
  answer3: {
    answer: string;
    correct: boolean;
  };
  question_type: string;
  assessment_type: string;
};

export type StoryPage = {
  page_number: number;
  page_text: string;
  page_image: string;
  lines: string[];
  font_size?: string;
  font_color?: string;
  line_height?: string;
  top?: string;
  right?: string;
  margin_bottom?: string;
  text_align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  word_margin?: string;
  word_tb_padding?: string;
  word_rl_padding?: string;
  word_container_width?: string;
};

export type Words = {
  _id?: string;
  text: string;
  canadian_version?: string;
  sensitive?: boolean;
  fry_list?: number;
  word_types?: string[];
  audio?: string;
  associated_worksheets?: string[];
  associated_questions?: string[];
  definitions: string[];
  phonics: WordPhonics[];
  images?: string[];
  wordOfTheDay?: boolean;
};

export type WordPhonics = {
  part: string;
  phonemes: string[];
  position: number;
  multiple?: boolean;
};

export type Story = {
  _id?: string;
  title: string;
  cover: string;
  pages: StoryPage[];
  level: string;
  tags: string[];
  priority_tag: string;
  questions: Question[];
  associated_words: Words[];
  mobileFriendly?: boolean;
};

type Crop = {
  unit: 'px' | '%';
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  aspect?: number;
};

const ImageCrop: React.FC = () => {
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });

  const [storyData, setStoryData] = useState<Story[]>([]);
  const [storyToEdit, setStoryToEdit] = useState<Story | null>(null);
  const [cropToSet, setCropToSet] = useState<Crop>({ unit: '%' });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [page, setPage] = useState(0);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);

  const resetError = () => {
    setError({
      isActive: false,
      message: '',
      type: 'error',
    });
  };

  const FetchStories = async () => {
    const request = await fetch(
      `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
      { method: 'GET', headers: { 'Content-Type': 'application/json' } },
    );
    const response = await request.json();
    if (request.status === 200) {
      setStoryData(response.stories);
    } else {
      setError({ type: 'error', isActive: true, message: response.message });
    }
  };

  useEffect(() => {
    FetchStories();
  }, []);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
    const image = imgRef.current;
    if (image) image.crossOrigin = '*';
  }, []);

  const generateDownload = async (canvas: HTMLCanvasElement | null, crop: Crop | null) => {
    if (!crop || !canvas) {
      return;
    }

    for (let i = 0; i < 3; i++) {
      let FD = new FormData();
      canvas.toBlob(
        async (blob) => {
          FD.append(`${storyToEdit?.title}${page + 1}Mobile`, blob!);
          const request = await fetch(
            `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/addStoryDocument/${
              i === 0 ? 'webp' : i === 1 ? 'jpg' : 'png'
            }?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            { method: 'POST', body: FD },
          );
          const response = await request.json();
          if (request.status === 200) {
            if (i === 2) {
              const updateRequest = await fetch(
                `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/stories/markAsMobileFriendly/${storyToEdit?._id}?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
                { method: 'POST', headers: { 'Content-Type': 'application/json' } },
              );
              const updateResponse = await updateRequest.json();
              if (updateRequest.status === 200) {
                setError({ isActive: true, type: 'success', message: 'Success!' });
              } else {
                setError({ isActive: true, type: 'error', message: updateResponse.message });
              }
            }
          } else {
            setError({ isActive: true, type: 'error', message: response.message });
          }
        },
        i === 0 ? 'image/webp' : i === 1 ? 'image/jpeg' : 'image/png',
        1,
      );
    }
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    if (ctx && crop.width && crop.height && crop.x && crop.y) {
      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    }
  }, [completedCrop]);

  return (
    <main className="main-container">
      {!storyToEdit ? (
        <div>
          <div>Select the story you wish to crop the images for.</div>
          {storyData.map((story, i) => (
            <React.Fragment key={`story-${i}`}>
              <div
                style={{ cursor: 'pointer', margin: '10px', width: 'fit-content' }}
                onClick={() => setStoryToEdit(story)}
              >
                {story.title} {story.mobileFriendly && <i className="fa fa-check"></i>}
              </div>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div>
          <div>Currently editing images for {storyToEdit.title}</div>
          {storyToEdit.mobileFriendly && (
            <div style={{ color: 'red' }}>Warning: You have already cropped images for this story previously.</div>
          )}
          <button onClick={() => window.location.reload()}>Go Back</button>

          {page > 0 && (
            <button
              onClick={() => {
                resetError();
                setPage(page - 1);
              }}
            >
              Previous Page
            </button>
          )}
          {page < storyToEdit.pages.length - 1 && (
            <button
              onClick={() => {
                resetError();
                setPage(page + 1);
              }}
            >
              Next Page
            </button>
          )}
          <div style={{ margin: '50px', maxWidth: '50%' }}>
            <ReactCrop
              src={`https://study-portal.s3.us-east-2.amazonaws.com/${
                storyToEdit.pages.sort((first, second) => first.page_number - second.page_number)[page].page_image
              }`}
              crop={cropToSet}
              onChange={(newCrop) => setCropToSet(newCrop)}
              onComplete={(newCrop) =>
                setCompletedCrop(
                  newCrop.x === 0 && newCrop.y === 0
                    ? { ...newCrop, x: 0.1, y: 0.1 }
                    : newCrop.x === 0
                    ? { ...newCrop, x: 0.1 }
                    : newCrop.y === 0
                    ? { ...newCrop, y: 0.1 }
                    : newCrop,
                )
              }
              onImageLoaded={onLoad}
            />
          </div>
          <div>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
          {error.isActive && <ErrorHandler type={error.type} message={error.message} handler={resetError} />}
          <button
            type="button"
            disabled={!completedCrop?.width || !completedCrop?.height}
            onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
            style={{ width: 'auto' }}
          >
            Submit this page's images
          </button>
        </div>
      )}
    </main>
  );
};

export default ImageCrop;
