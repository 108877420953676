//Dependencies
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { ErrorProps } from '../Models';
import { GetBaseURL, ValidateEmail } from '../Utils';

const WorksheetsUsers: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [usersWithoutWorksheets, setUsersWithoutWorksheets] = useState<any[]>([]);
  const [usersWithWorkSheets, setUsersWithWorksheets] = useState<any[]>([]);
  const [expandUsers, setExpandUsers] = useState<any[]>([]);
  const [resources, setResources] = useState<any[]>([]);
  const [withWorksheets, setWithWorksheets] = useState(true);
  const [page, setPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState(30);
  const paidSubRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<ErrorProps>({ type: 'error', isActive: false, message: '' });

  // {user.subscription_data.next_billing_date
  //   ? 'paid'
  //   : user.subscription_data.plan_type === 'paid'
  //   ? 'tutor'
  //   : 'free'}
  const fetchRecentUsers = async () => {
    const request = await fetch(`${GetBaseURL()}/api/users/recent?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
    const response = await request.json();
    if (request.status === 200) {
      setUsers(response.sortedUsersWithWorksheets);
      setUsersWithWorksheets(response.sortedUsersWithWorksheets);
      setUsersWithoutWorksheets(response.usersWithoutWorksheets);
      setWithWorksheets(true);
    } else {
      console.log(response.message);
    }
  };
  const fetchResources = async () => {
    const request = await fetch(`${GetBaseURL()}/api/resources/fetchV3resources`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const response = await request.json();
    if (request.status === 200) {
      setResources(response.resources);
    } else {
      console.log(response.message);
    }
  };

  useEffect(() => {
    fetchRecentUsers();
    fetchResources();
  }, []);

  const ConvertGradeToNumberOrString = (grade: number | string) =>
    typeof grade === 'number'
      ? grade === -1
        ? 'Pre-K'
        : grade === 0
        ? 'KG'
        : `Gr ${grade}`
      : grade === 'Pre-K'
      ? -1
      : grade === 'KG'
      ? 0
      : Number(grade.split(' ')[1]);

  return (
    <main className="main-container">
      {error.isActive && (
        <ErrorHandler
          type={error.type}
          message={error.message}
          handler={() => setError((prev: ErrorProps) => ({ ...prev, isActive: false }))}
        />
      )}
      <span>
        <h1 className="header-title">Worksheets Users</h1>
        <input type="email" placeholder="Paid Sub Email" ref={paidSubRef} />
        <button
          onClick={async () => {
            if (paidSubRef.current && ValidateEmail(paidSubRef.current.value)) {
              const request = await fetch(
                `${GetBaseURL()}/api/users/giveUserPaidSub?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
                {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ email: paidSubRef.current.value }),
                },
              );
              const response = await request.json();
              if (request.status === 200) {
                setError({ type: 'success', isActive: true, message: response.message });
              } else {
                setError({ type: 'error', isActive: true, message: response.message });
              }
            } else {
              setError({ type: 'error', isActive: true, message: 'Invalid Email' });
            }
          }}
        >
          Give this User a Paid Sub
        </button>
      </span>
      <h2>Total Users: {usersWithWorkSheets.length + usersWithoutWorksheets.length}</h2>
      <h2>
        <CSVLink
          filename={'worksheets-users.csv'}
          target="_blank"
          data={[
            [
              'name',
              'email',
              'phone',
              'user_role',
              'tutor_interest',
              'last_download_date',
              'worksheet_names',
              'date_created',
            ],

            ...usersWithWorkSheets.map((user) => [
              user.user_data.full_name && user.user_data.full_name !== ''
                ? user.user_data.full_name
                : '//Not Provided//',
              user.user_data.email,
              user.user_data.phone ? user.user_data.phone : '//Not provided//',
              user.userRole && user.userRole !== '' ? user.userRole : 'Incomplete',
              user.tutorInterest,
              user.worksheets.length > 0
                ? user.assessments.length > 0
                  ? new Date(user.worksheets[user.worksheets.length - 1].date_downloaded).valueOf() >
                    new Date(user.assessments[user.assessments.length - 1].date_downloaded).valueOf()
                    ? new Date(user.worksheets[user.worksheets.length - 1].date_downloaded).toLocaleString()
                    : new Date(user.assessments[user.assessments.length - 1].date_downloaded).toLocaleString()
                  : new Date(user.worksheets[user.worksheets.length - 1].date_downloaded).toLocaleString()
                : '',
              [
                ...user.worksheets.map(
                  (worksheet: any) => resources.find((resource) => resource._id == worksheet.resource_id)?.title,
                ),
                ...user.assessments.map(
                  (assessment: any) => resources.find((resource) => resource._id == assessment.resource_id)?.title,
                ),
              ].join(';;'),
              new Date(user.date_created).toLocaleString(),
            ]),
            ...usersWithoutWorksheets.map((user) => [
              user.user_data.full_name && user.user_data.full_name !== ''
                ? user.user_data.full_name
                : '//Not Provided//',
              user.user_data.email,
              user.user_data.phone ? user.user_data.phone : '//Not provided//',
              user.userRole && user.userRole !== '' ? user.userRole : 'Incomplete',
              user.tutorInterest,
              'N/A',
              'None',
              new Date(user.date_created).toLocaleString(),
            ]),
          ]}
        >
          Download CSV of all users
        </CSVLink>
      </h2>
      <h3>
        Users {withWorksheets ? 'with' : 'without'} worksheets: {users.length}
      </h3>
      <h4>Datetime Format: (dd/mm/yyyy, hr:min:sec)</h4>
      <h4>Click a row to expand its contents</h4>
      <button
        onClick={() => {
          setExpandUsers(users.map((user) => user._id));
        }}
      >
        Expand All
      </button>
      <button
        onClick={() => {
          setExpandUsers([]);
        }}
      >
        Collapse All
      </button>
      <button
        onClick={() => {
          setUsers(withWorksheets ? usersWithoutWorksheets : usersWithWorkSheets);
          setWithWorksheets(!withWorksheets);
        }}
      >
        {withWorksheets ? 'Show Users Without Worksheets' : 'Show Users With Worksheets'}
      </button>
      <div>
        {page > 1 && <button onClick={() => setPage(page - 1)}>Previous Page</button>}
        {page * numPerPage < users.length && <button onClick={() => setPage(page + 1)}>Next Page</button>}
        <input
          placeholder="Set # Users Per Page"
          type="number"
          min={1}
          onBlur={(e) => {
            setNumPerPage(Math.round(Number(e.target.value)));
            e.target.value = `${Math.round(Number(e.target.value))}`;
          }}
        />
      </div>

      <div className="reading-portal-users">
        <span className="reading-portal-users__title reading-portal-users__first-item">Email</span>
        <span className="reading-portal-users__title reading-portal-users__second-item">Plan Type</span>
        <span className="reading-portal-users__title reading-portal-users__last-item">Date Created</span>

        {users.length > 0 &&
          users.slice(page === 1 ? 0 : numPerPage * (page - 1), page * numPerPage).map((user: any, index) => (
            <React.Fragment key={index}>
              <span
                className="reading-portal-users__item reading-portal-users__first-item"
                style={index % 2 === 0 ? { backgroundColor: '#b7a0a0' } : { backgroundColor: 'lightgray' }}
                onClick={() => {
                  setExpandUsers((prev: any[]) =>
                    prev.includes(user._id)
                      ? prev.filter((prevId: string) => prevId !== user._id)
                      : [...prev, user._id],
                  );
                }}
              >
                <h3>{user.user_data.email}</h3>
                {expandUsers.includes(user._id) && (
                  <div>
                    <span style={{ textDecoration: 'underline' }}>Assessments Downloaded</span>
                    {(user.assessments as any[]).map((doc, docIndex) => (
                      <React.Fragment key={`${index} ${docIndex}`}>
                        <div>
                          <span>{resources.find((resource) => resource._id == doc.resource_id)?.title}: </span>
                          <span>{`${new Date(doc.date_downloaded).toLocaleString()}`}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </span>
              <span
                className="reading-portal-users__item reading-portal-users__second-item"
                style={index % 2 === 0 ? { backgroundColor: '#b7a0a0' } : { backgroundColor: 'lightgray' }}
                onClick={() => {
                  setExpandUsers((prev: any[]) =>
                    prev.includes(user._id)
                      ? prev.filter((prevId: string) => prevId !== user._id)
                      : [...prev, user._id],
                  );
                }}
              >
                <h3>
                  {user.customer_id && user.subscription_id && user.subscription_active
                    ? 'paid'
                    : user.subscription_active && user.userRole === 'Tutor'
                    ? 'tutor'
                    : user.subscription_active
                    ? 'client'
                    : 'free'}
                </h3>
                {expandUsers.includes(user._id) && (
                  <div>
                    <span style={{ textDecoration: 'underline' }}>Worksheets Downloaded</span>
                    {(user.worksheets as any[]).map((doc, docIndex) => (
                      <React.Fragment key={`${index}-${docIndex}`}>
                        <div>
                          <span>{resources.find((resource) => resource._id == doc.resource_id)?.title}: </span>
                          <span>{`${new Date(doc.date_downloaded).toLocaleString()}`}</span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </span>
              <span
                className="reading-portal-users__item reading-portal-users__last-item"
                style={index % 2 === 0 ? { backgroundColor: '#b7a0a0' } : { backgroundColor: 'lightgray' }}
                onClick={() => {
                  setExpandUsers((prev: any[]) =>
                    prev.includes(user._id)
                      ? prev.filter((prevId: string) => prevId !== user._id)
                      : [...prev, user._id],
                  );
                }}
              >
                <h3>{new Date(user.date_created).toLocaleString()}</h3>
                {expandUsers.includes(user._id) && (
                  <div>
                    <div style={{ textDecoration: 'underline' }}>Name: {user.user_data.full_name}</div>
                    <div>Phone: {user.user_data.phone}</div>
                    <div>{user.userRole}</div>
                    <div>
                      <span>Interested in tutoring:</span>
                      <span>{user.tutorInterest}</span>
                    </div>
                  </div>
                )}
              </span>
            </React.Fragment>
          ))}
      </div>
    </main>
  );
};

export default WorksheetsUsers;
