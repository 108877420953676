import React, { useState, useRef, useEffect, RefObject } from 'react';
import DropDown from '../Components/Partials/Dropdown';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { studentGrades, ErrorProps } from '../Models';
import { GetBaseURL, SendEmail } from '../Utils';

const WordsUpload: React.FC = () => {
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });
  const [wordsWithoutAudio, setWordsWithoutAudio] = useState<string[] | null>(null);
  const [wordsNotInDatabase, setWordsNotInDatabase] = useState<string[] | null>(null);
  const [fetching, setFetching] = useState(false);

  const wordsRef = useRef<HTMLInputElement>(null);

  const submitFile = async (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      if (ref.current.files === null || ref.current.files.length === 0) {
        console.log('No file submitted');
      } else {
        const FD = new FormData();
        FD.append('Words Sheet', ref.current.files[0]);
        const URL = `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/words/postWords?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;

        const request = await fetch(URL, {
          body: FD,
          method: 'POST',
        });

        const response = await request.json();

        request.status === 200
          ? setError({ isActive: true, type: 'success', message: response.message })
          : setError({ isActive: true, type: 'error', message: response.message });
      }
    } else {
      setError({ isActive: true, type: 'error', message: 'Could not locate the file reference on the page.' });
    }
  };

  return (
    <main className="main-container">
      <div className="resourceUpload__input-group">
        <label className="resourceUpload__label">Upload Words CSV:</label>
        <input ref={wordsRef} type="file" name="words" id="wordsFileToUpload" className="resourceUpload__input" />
      </div>
      <button className="resourceUpload__form-button" onClick={() => submitFile(wordsRef)}>
        Submit
      </button>
      {error.isActive && <ErrorHandler type={error.type} message={error.message} />}
      <button
        className="resourceUpload__form-button"
        onClick={async () => {
          setFetching(true);
          const request = await fetch(
            `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/words/wordsWithoutAudio?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
            },
          );
          const response = await request.json();
          if (request.status === 200) {
            setWordsWithoutAudio(response.unavailableStoryWords);
          } else {
            setError({ type: 'error', isActive: true, message: response.message });
          }
          setFetching(false);
        }}
      >
        Get Words Without Audio
      </button>
      <button
        className="resourceUpload__form-button"
        onClick={async () => {
          setFetching(true);
          const request = await fetch(
            `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/words/wordsNotInDatabase?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
            },
          );
          const response = await request.json();
          if (request.status === 200) {
            setWordsNotInDatabase(response.unavailableStoryWords);
          } else {
            setError({ type: 'error', isActive: true, message: response.message });
          }
          setFetching(false);
        }}
      >
        Get Words Not in Database
      </button>
      <button
        className="resourceUpload__form-button"
        onClick={async () => {
          setFetching(true);
          const request = await fetch(
            `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/words/removeAssociatedWords?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`,
            { method: 'POST', headers: { 'Content-Type': 'application/json' } },
          );
          const response = await request.json();
          if (request.status === 200) {
            setError({ type: 'success', isActive: true, message: response.message });
          } else {
            setError({ type: 'error', isActive: true, message: response.message });
          }
        }}
      >
        Remove Associated Words
      </button>
      {fetching && <div>Fetching...</div>}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {wordsWithoutAudio && (
          <span>
            Words Without Audio
            {wordsWithoutAudio.map((word, index) => (
              <React.Fragment key={`audio-${index}`}>
                <div>{word}</div>
              </React.Fragment>
            ))}
          </span>
        )}
        {wordsNotInDatabase && (
          <span>
            Words Not In Database
            {wordsNotInDatabase.map((word, index) => (
              <React.Fragment key={`database-${index}`}>
                <div>{word}</div>
              </React.Fragment>
            ))}
          </span>
        )}
      </div>
    </main>
  );
  // }
};

export default WordsUpload;
