// normalize phone number
export const NormalizePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
  const x = event.target.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
  if (event.target.value.length < 1) {
    return x;
  }

  if (event.target.value.length !== 10) {
    return x;
  }

  event.target.value = '(' + x![1] + ') ' + x![2] + '-' + x![3];
};

// export const formatPhoneNumber = (str: string) => {
//   const edit = str.split('+1')[1];
//   const phone =
//     '(' +
//     edit[0] +
//     edit[1] +
//     edit[2] +
//     ') ' +
//     edit[3] +
//     edit[4] +
//     edit[5] +
//     '-' +
//     edit[6] +
//     edit[7] +
//     edit[8] +
//     edit[9];
//   return phone;
// };

export const printInitials = (fullName: string) => {
  let splitName = fullName
    .split(' ')
    .map((name) => {
      return name[0];
    })
    .join('');
  return splitName;
};

// export const GetNumberForTwilio = (phonestring: string) => {
//   let numbers: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
//   let returnstring = [];
//   for (let i = 0; i < phonestring.length; i++) {
//     if (numbers.includes(phonestring.charAt(i))) {
//       returnstring.push(phonestring.charAt(i));
//     }
//   }

//   return returnstring.join('').length === 10 ? { success: true, phone: returnstring.join('') } : { success: false };
// };

export const limitCharCount = (str: string, limit: number = 40) => {
  if (str.length > limit) {
    str = `${str.substring(0, limit)}...`;
  }
  return str;
};

// export const NumbersOnlyPhone =
export const GetNumberForTwilio = (phonestring: string) => {
  let numbers: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  let returnstring = [];
  for (let i = 0; i < phonestring.length; i++) {
    if (numbers.includes(phonestring.charAt(i))) {
      returnstring.push(phonestring.charAt(i));
    }
  }

  return returnstring.join('');
};
