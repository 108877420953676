import React from 'react';
import { useLeadCards } from '../Hooks/Lead/useLeadCards';

const FilterLeadCards: React.FC = () => {
  const { updateCardFilter } = useLeadCards();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCardFilter(event.target.value);
  };

  return (
    <div>
      <input type="text" placeholder="Search Cards..." onChange={handleChange}
        className="filter-input" />
    </div>
  );
};

export default FilterLeadCards;
