import { GetBaseURL } from '../Utils';

export const SendEmail = async (message: string, email: string, subject: string) => {
  const POST_REQUEST = {
    email: email,
    message: message,
    subject: subject,
  };

  fetch(`${GetBaseURL()}/api/email/send`, {
    method: 'POST',
    body: JSON.stringify(POST_REQUEST),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const SendHTMLEmail = async (message: string, email: string, subject: string) => {
  const POST_REQUEST = {
    email: email,
    message: message,
    subject: subject,
  };

  fetch(`${GetBaseURL()}/api/email/send_html`, {
    method: 'POST',
    body: JSON.stringify(POST_REQUEST),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
