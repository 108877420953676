import { useContext } from 'react';
import { LeadContext } from '../../Context/LeadContext';
import { LeadType, ClientType, StudentType } from '../../../Models';
import { GetBaseURL, NumbersOnly } from '../../../Utils';

export const useClientData = () => {
  const { lead, setLead } = useContext(LeadContext);

  // <-------------------- input functions -------------------->
  const handleClientInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    let updatedClient: ClientType = lead!.client;
    let targetInput: keyof ClientType = e.target.name! as keyof ClientType;

    switch (targetInput) {
      case 'full_name':
        updatedClient.full_name = e.target.value;
        break;
      case 'phone_number':
        updatedClient.phone_number = NumbersOnly(e.target.value);
        // updatedClient.phone_number = e.target.value;
        break;
      case 'email':
        updatedClient.email = e.target.value;
        break;
      case 'street_address':
        updatedClient.street_address = e.target.value;
        break;
      case 'major_intersection':
        updatedClient.major_intersection = e.target.value;
        break;
      case 'city':
        updatedClient.city = e.target.value;
        break;
      case 'postcode':
        updatedClient.postcode = e.target.value;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedClient !== null) {
        updatedLead.client = updatedClient;
      }
      return updatedLead;
    });
  };

  const resetClientIsStudent = () => {
    let updatedClient: ClientType = lead!.client;
    updatedClient.isStudent = !updatedClient.isStudent;

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedClient !== null) {
        updatedLead.client = updatedClient;
      }
      return updatedLead;
    });
  };

  const toggleClientStudent = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedClient: ClientType = lead!.client;
    let targetInput: keyof ClientType = e.target.name! as keyof ClientType;

    switch (targetInput) {
      case 'isStudent':
        updatedClient.isStudent = !updatedClient.isStudent;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedClient !== null) {
        updatedLead.client = updatedClient;
      }
      return updatedLead;
    });
  };

  const handleClientSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    let updatedClient: ClientType = lead!.client;
    let targetInput: keyof ClientType = e.target.name! as keyof ClientType;

    switch (targetInput) {
      case 'country':
        updatedClient.country = e.target.value;
        break;
      case 'state_province':
        updatedClient.state_province = e.target.value;
        break;
    }

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedClient !== null) {
        updatedLead.client = updatedClient;
      }
      return updatedLead;
    });
  };

  const updateClientStringValue = (clientProperty: keyof ClientType, newValue: string) => {
    if (
      clientProperty === 'city' ||
      clientProperty === 'postcode' ||
      clientProperty === 'major_intersection' ||
      clientProperty === 'street_address' ||
      clientProperty === 'email' ||
      clientProperty === 'full_name' ||
      clientProperty === 'phone_number' ||
      clientProperty === 'state_province' ||
      clientProperty === 'country'
    ) {
      lead.client[clientProperty] = newValue;
    } else {
      alert('There was an error with the client property provided');
    }
  };

  // <-------------------- Tutorcruncher Hooks -------------------->
  const postToTutorcruncher = async () => {
    let newClient = {
      client: {
        user: {
          first_name: lead.client.full_name.split(' ')[0],
          last_name: lead.client.full_name.split(' ')[1],
          email: lead.client.email,
          phone: lead.client.phone_number,
          street: lead.client.street_address,
          town: lead.client.city,
          postcode: lead.client.postcode,
        },
        status: 'live',
      },
      country: lead.client.country,
    };

    const request = await fetch(`${GetBaseURL()}/api/clients/postToTutorcruncher`, {
      method: 'POST',
      body: JSON.stringify(newClient),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (request.status === 200) {
      let response = await request.json();
      lead.client.tutorcruncher_id = response.id;
      return { id: response.id, successfulSave: true };
    } else {
      return {
        successfulSave: false,
      };
    }
  };

  const updateInTutorcruncher = async () => {
    let newClient = {
      client: {
        user: {
          first_name: lead.client.full_name.split(' ')[0],
          last_name: lead.client.full_name.split(' ')[1],
          email: lead.client.email,
          phone: lead.client.phone_number,
          street: lead.client.street_address,
          town: lead.client.city,
          postcode: lead.client.postcode,
        },
      },
      NFT_CLIENT: lead.client,
      country: lead.client.country,
    };

    const request = await fetch(`${GetBaseURL()}/api/clients/updateInTutorcruncher`, {
      method: 'POST',
      body: JSON.stringify(newClient),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (request.status === 200) {
      let response = await request.json();
      lead.client.tutorcruncher_id = response.id;
      return { id: response.id, successfulSave: true };
    } else {
      return {
        successfulSave: false,
      };
    }
  };

  // <-------------------- Client ID Update Hook -------------------->
  const updateClientID = (id: number) => {
    let updatedClient: ClientType = lead!.client;
    updatedClient.tutorcruncher_id = id;

    setLead((prev: LeadType) => {
      let updatedLead: LeadType = prev;
      if (updatedClient !== null) {
        updatedLead.client = updatedClient;
      }
      return updatedLead;
    });
  };

  const initializeClientAsStudent = () => {
    let newStudent: StudentType = {
      student_name: lead.client.full_name,
      student_phone: lead.client.phone_number,
      student_email: lead.client.email,
      grade: '',
      subjects: [],
      skill_level_and_goals: '',
      first_appointment: '',
    };
    setLead((prev: LeadType) => {
      let updatedLead = {
        ...prev,
        students: [newStudent],
      };
      return updatedLead;
    });
    return [newStudent];
  };

  const getFullAddress = (client: ClientType) => {
    return `${client.street_address}, ${client.city} ${client.postcode}`;
  };

  return {
    handleClientInputChange,
    resetClientIsStudent,
    toggleClientStudent,
    handleClientSelect,
    updateClientStringValue,
    postToTutorcruncher,
    updateInTutorcruncher,
    updateClientID,
    initializeClientAsStudent,
    getFullAddress,
  };
};
