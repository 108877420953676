import React, { useState, useRef, useEffect, RefObject } from 'react';
import DropDown from '../Components/Partials/Dropdown';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { studentGrades, ErrorProps } from '../Models';
import { GetBaseURL, SendEmail } from '../Utils';

const PhonicsUpload: React.FC = () => {
  const [error, setError] = useState<ErrorProps>({
    isActive: false,
    message: '',
    type: 'error',
    redirect: false,
    icon: '',
  });

  const phonicsRef = useRef<HTMLInputElement>(null);

  const submitFile = async (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      if (ref.current.files === null || ref.current.files.length === 0) {
        console.log('No file submitted');
      } else {
        const FD = new FormData();
        FD.append('Phonics Sheet', ref.current.files[0]);
        const URL = `${process.env.REACT_APP_STUDY_PORTAL_URL}/api/words/postPhonics?key=${process.env.REACT_APP_PERSONAL_AUTH_KEY}`;

        const request = await fetch(URL, {
          body: FD,
          method: 'POST',
        });

        const response = await request.json();

        request.status === 200
          ? setError({ isActive: true, type: 'success', message: response.message })
          : setError({ isActive: true, type: 'error', message: response.message });
      }
    } else {
      setError({ isActive: true, type: 'error', message: 'Could not locate the file reference on the page.' });
    }
  };

  return (
    <main className="main-container">
      <div className="resourceUpload__input-group">
        <label className="resourceUpload__label">Upload Phonics CSV:</label>
        <input ref={phonicsRef} type="file" name="phonics" id="phonicsFileToUpload" className="resourceUpload__input" />
      </div>
      <button className="resourceUpload__form-button" onClick={() => submitFile(phonicsRef)}>
        Submit
      </button>
      {error.isActive && <ErrorHandler type={error.type} message={error.message} />}
    </main>
  );
  // }
};

export default PhonicsUpload;
