//Dependencies
import React, { useEffect, useState } from 'react';
//Components
import RenderMatch from '../Components/MatchingForm/RenderMatch';
//Types
import { AdminLeadsFilterColumn } from '../Models';
//Context
import { useLeadCards } from '../Components/Hooks/Lead/useLeadCards';
import FilterLeadCards from '../Components/Partials/FilterLeadCards';

const AdminMatches: React.FC = () => {
  const [willFetchData, setWillFetchData] = useState(true);
  const [hasData, setHasData] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const { cardContext, setLeadCards } = useLeadCards();

  useEffect(() => {
    if (willFetchData) {
      setLeadCards();
      setWillFetchData(false);
    }
  });

  useEffect(() => {
    if (cardContext.cards.length > 0) {
      setHasData(true);
    }
  });

  const RefreshComponent = (value: boolean) => {
    setToggleRefresh(value);
  };

  return (
    <main className="main-container">
      <h1>Matches</h1>
      <FilterLeadCards />
      <div className="match-grid-container">
        <RenderMatch
          componentTitle="Free For All"
          column={AdminLeadsFilterColumn.Free}
          hasData={hasData}
          RefreshComponent={RefreshComponent}
          refresh={toggleRefresh}
        />
        <RenderMatch
          componentTitle="Suhail"
          column={AdminLeadsFilterColumn.Suhail}
          hasData={hasData}
          RefreshComponent={RefreshComponent}
          refresh={toggleRefresh}
        />
        <RenderMatch
          componentTitle="Matt"
          column={AdminLeadsFilterColumn.Matt}
          hasData={hasData}
          RefreshComponent={RefreshComponent}
          refresh={toggleRefresh}
        />
        <RenderMatch
          componentTitle="Ruth"
          column={AdminLeadsFilterColumn.Ruth}
          hasData={hasData}
          RefreshComponent={RefreshComponent}
          refresh={toggleRefresh}
        />
      </div>
    </main>
  );
};

export default AdminMatches;
