import React from 'react';

type LeadDataProps = {
  dataLength: number;
  dataType: String;
  classStyle: string;
};

export const RenderLeadData: React.FC<LeadDataProps> = ({ dataLength, dataType, classStyle }) => {
  const classString = classStyle + ' lead-data';

  return (
    <div className={classString}>
      <div className="dashboard-data">
        <p>{dataLength}</p>
        <span>Number of {dataType}</span>
      </div>
    </div>
  );
};

export default RenderLeadData;
