import React, { useState } from 'react';

export interface DisplayContextProps {
  displayData?: any;
  setDisplayData: (userData: any) => void;
}

const DisplayContext = React.createContext({} as DisplayContextProps);

const DisplayContextProvider = (props: any) => {
  const [displayData, setDisplayData] = useState<any>({
    confirmStudentDelete: false,
    confirmSubjectDelete: false,
    selectedStudent: '',
    selectedSubject: '',
  });

  const providerValue: DisplayContextProps = {
    displayData,
    setDisplayData,
  };

  return <DisplayContext.Provider value={providerValue}>{props.children}</DisplayContext.Provider>;
};

export { DisplayContext, DisplayContextProvider };
