import React, { useState, useRef } from 'react';
import { WorksheetsTextSegment } from '../../Models';
import ErrorHandler from '../Partials/ErrorHandler';

type UploadParagraphSegmentProps = {
  UpdateTextBlock: (segment: WorksheetsTextSegment) => void;
};

const UploadParagraphSegment: React.FC<UploadParagraphSegmentProps> = ({ UpdateTextBlock }) => {
  const [hasError, setHasError] = useState(false);
  const ParagraphInputRef = useRef<HTMLTextAreaElement>(null);

  const AppendParagraph = () => {
    if (ParagraphInputRef.current && ParagraphInputRef.current.value !== '') {
      UpdateTextBlock({
        type: 'paragraph',
        content: ParagraphInputRef.current.value,
      });
      ParagraphInputRef.current.value = '';
    } else {
      setHasError(true);
    }
  };

  return (
    <section>
      {hasError && (
        <ErrorHandler
          type="error"
          message="Invalid Paragraph entered, please try again or contact the tech team."
          handler={() => {
            setHasError(false);
          }}
        />
      )}
      <div>
        <label>New Paragraph: </label>
        <textarea ref={ParagraphInputRef} placeholder="Paragraph text..." />
      </div>
      <button onClick={AppendParagraph}>Add Paragraph</button>
    </section>
  );
};

export default UploadParagraphSegment;
