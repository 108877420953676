import React, { useRef, FormEvent, useState } from 'react';
import ErrorHandler from '../Partials/ErrorHandler';
import { HashPassword, FindCookieValue, GetBaseURL } from '../../Utils';
import { CookieTypes } from '../../Models';

const UpdatePassword: React.FC = () => {
  //State
  const [showResetForm, setShowResetForm] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);
  //Error State
  const [passwordError, setPasswordError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  //Refs
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    if (
      newPasswordRef.current!.value !== confirmPasswordRef.current!.value ||
      newPasswordRef.current!.value.length < 8
    ) {
      setPasswordError(true);
      return;
    }

    let postObject = {
      currentPassword: HashPassword(currentPasswordRef.current!.value),
      newPassword: HashPassword(newPasswordRef.current!.value),
      confirmPassword: HashPassword(confirmPasswordRef.current!.value),
      adminEmail: FindCookieValue(CookieTypes.Email),
    };

    const Request = await fetch(`${GetBaseURL()}/api/login/updateAdminPassword`, {
      method: 'POST',
      body: JSON.stringify(postObject),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const Res = await Request.json();
    if (Request.status !== 200) {
      setServerError(true);
      setServerErrorMessage(Res.errorMessage! as string);
    } else {
      setSuccessfulUpdate(true);
    }
  };

  if (showResetForm) {
    return (
      <form
        className="password-form"
        onSubmit={(event: FormEvent) => {
          event.preventDefault();
        }}
      >
        <h3>Reset Password</h3>
        {passwordError ? (
          <ErrorHandler
            handler={() => {
              setPasswordError(false);
            }}
            type="error"
            error="Please check that the new passwords match and are at least 8 characters long."
          />
        ) : null}
        {serverError && serverErrorMessage !== '' ? (
          <ErrorHandler
            handler={() => {
              setServerError(false);
              setServerErrorMessage('');
            }}
            type="error"
            error={serverErrorMessage}
          />
        ) : null}
        {successfulUpdate ? (
          <ErrorHandler
            handler={() => {
              setShowResetForm(false);
            }}
            type="success"
            message="Successfully Updated Password! Click here to hide form."
          />
        ) : null}
        <div className="password-reset">
          <label>Current Password</label>
          <input type="password" ref={currentPasswordRef} placeholder={'Current Password'} data-cy="current_password" />
        </div>
        <div className="password-reset">
          <label>New Password</label>
          <input type="password" ref={newPasswordRef} placeholder={'New Password'} data-cy="new_password" />
        </div>
        <div className="password-reset">
          <label>Confirm Password</label>
          <input
            type="password"
            ref={confirmPasswordRef}
            placeholder={'Confirm New Password'}
            data-cy="confirm_password"
          />
        </div>
        <button className="password-submit" onClick={handleSubmit} data-cy="submit_password">
          Submit
        </button>
      </form>
    );
  } else {
    return (
      <div>
        <button
          data-cy="reset_password"
          onClick={() => {
            setShowResetForm(true);
          }}
        >
          Reset Password
        </button>
      </div>
    );
  }
};

export default UpdatePassword;
