import React from 'react';
import { WorksheetsTextSegment } from '../../Models';
import { v4 as uuidv4 } from 'uuid';
type WorksheetTextHeadingProps = {
  segment: WorksheetsTextSegment;
};

const WorksheetTextHeading: React.FC<WorksheetTextHeadingProps> = ({ segment }) => {
  return (
    <React.Fragment key={uuidv4()}>
      <h3>{segment.type === 'header' && typeof segment.content === 'string' && segment.content}</h3>
    </React.Fragment>
  );
};

export default WorksheetTextHeading;
